html {
  // filter: brightness(0.5);
  @media only screen and (min-width: 1600px) {
    font-size: $fs-base !important;
  }
  @media only screen and (max-width: 1599px) {
    font-size: 14px !important;
  }
}

.container {
  @media only screen and (max-width: 1512px) {
    max-width: rem(1460);
  }
  @media only screen and (min-width: 1513px) {
    max-width: rem(1460);
  }
}
body {
  font-family: "EB Garamond", serif;
  max-width: 100%;
  main {
    min-width: 100%;
    @include respond(ipadMobile) {
      overflow-x: hidden;
    }
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    a {
      text-decoration: none;
    }
  }
}

a {
  text-decoration: none;
}
