.shop-now-wrapper {
  background-color: $white;
  max-width: 100%;

  &.collection_wrapper {
    padding: rem(150) 0;

    @include respond(mobile) {
      padding: rem(50) 0;
    }

    .shop_now_product_cards {
      .shop_now_title {
        font-family: "EB Garamond";
        font-size: rem(42);
        font-weight: 600;
        line-height: rem(50);
        letter-spacing: 0.01em;
        margin: 0 0 rem(80);
        color: #9f8043;

        @include respond(mobile) {
          font-size: rem(24);
          margin: 0 0 rem(50);
        }
      }

      .product_card_wrapper {
        .product_card {
          .product_upper_section {
            .product_contains {
              h2 {
                font-family: "EB Garamond";
                color: #9f8043;
              }
            }
          }
          .product_cart_section {
            .btn_buy_noe {
              padding: 0;
            }
          }
        }
      }
    }
  }

  @include respond(desktop) {
    padding-top: rem(130);
  }

  @include respond(ipadMobile) {
    padding-top: rem(80);
  }
  @include respond(ipad) {
    padding-top: rem(120);
  }

  .shop_now_main_container {
    max-width: 94%;
    margin: 0px auto;
  }

  .shop_now_filters {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: rem(47);
    padding: rem(80) rem(0) rem(44);

    @include respond(ipadMobile) {
      flex-wrap: wrap;
    }

    .shop_now_options {
      width: 100%;
      max-width: rem(298);

      @include respond(ipadMobile) {
        max-width: rem(298);
      }

      @include respond(mobile) {
        max-width: rem(500);
      }
    }
  }

  .shop_now_product_cards {
    display: block;

    .shop_now_title {
      display: block;
      text-align: center;
      font-family: "Raleway";
      font-size: rem(40);
      font-weight: 600;
      line-height: normal;
      margin: rem(37) 0;
      color: #775c3d;
      text-transform: uppercase;

      @include respond(ipadMobile) {
        margin: rem(90) 0 rem(24);
      }

      @include respond(mobile) {
        font-size: rem(34);
        margin: rem(50) 0 rem(24);
      }
    }

    .product_counts {
      display: block;
      text-align: center;
      font-family: "Raleway";
      font-size: rem(14);
      font-weight: 600;
      line-height: rem(22);
      margin-bottom: rem(37);
      color: #775c3d;
    }

    .product_card_wrapper {
      gap: rem(16);
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      @include respond(laptopdesktop) {
        grid-template-columns: repeat(3, 1fr);
        padding-bottom: rem(40);
      }

      @include respond(ipadMobile) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include respond(mobile) {
        grid-template-columns: repeat(1, 1fr);
      }

      .product_card {
        border: 1px solid #574a34;
        padding: rem(24);
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        align-content: space-between;

        @include respond(mobile) {
          padding: rem(20);
          margin-bottom: rem(16);
        }

        .product_upper_section {
          width: 100%;

          .product_img_section {
            display: grid;
            justify-content: center;
            padding-top: rem(45);

            @include respond(mobile) {
              padding-top: rem(22);
            }

            img {
              margin: rem(0) auto;
              text-align: center;

              &.bottle_img {
                width: auto !important;
                height: rem(330) !important;
                z-index: 0;
              }

              &.shadow_img {
                margin-top: -40px;
                object-fit: scale-down;
              }
            }
          }

          .product_contains {
            display: block;
            padding: rem(18) rem(0) rem(16);

            @include respond(mobile) {
              padding: rem(15) rem(0) rem(12);
            }

            h2 {
              font-family: "EB Garamond";
              font-size: rem(24);
              font-weight: 500;
              line-height: rem(32);
              color: #775c3d;
              max-width: rem(332);
              margin: rem(0) auto;
              text-transform: uppercase;

              @include respond(mobile) {
                font-size: rem(21);
                line-height: rem(27);
                max-width: rem(304);
              }
            }

            p {
              font-family: "Raleway";
              font-size: rem(16);
              font-weight: 300;
              line-height: rem(23);
              margin: rem(16) auto;
              max-width: rem(366);
              color: #775c3d;
              text-transform: capitalize;

              @include respond(mobile) {
                margin: rem(13) auto;
                font-size: rem(12);
                line-height: rem(17);
                max-width: rem(305);
              }
            }

            h6 {
              margin: rem(0) auto;
              font-family: "DM Serif Text";
              font-size: rem(24);
              font-weight: 400;
              line-height: rem(33);
              color: #181818;

              @include respond(mobile) {
                font-size: rem(16);
                line-height: rem(21);
              }
            }
          }
        }

        .product_cart_section {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: rem(20);

          .add_card_section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #f5f5f5;
            padding: rem(9.5) rem(12);
            width: 100%;
            max-width: rem(91);
            border-radius: rem(3);

            .decrease_sign {
              font-family: "Raleway";
              font-size: rem(22);
              font-weight: 400;
              line-height: rem(21);
              color: #000000;
              cursor: pointer;

              @include respond(mobile) {
                font-size: rem(18);
                line-height: rem(20);
              }
            }

            .count_numbers {
              font-family: DM Serif Text;
              font-size: rem(18);
              font-weight: 400;
              line-height: rem(25);
              color: #000000;

              @include respond(mobile) {
                font-size: rem(16);
                line-height: rem(20);
                font-weight: 500;
              }
            }

            .increase_sign {
              font-family: "Raleway";
              font-size: rem(22);
              font-weight: 400;
              line-height: rem(21);
              color: #000000;
              cursor: pointer;

              @include respond(mobile) {
                font-size: rem(18);
                line-height: rem(20);
              }
            }
          }

          .btn_buy_noe {
            border: rem(0);
            outline: none;
            box-shadow: none;
            background: #775c3d;
            height: rem(42);
            width: 100%;
            max-width: rem(146);
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Raleway";
            font-size: rem(14);
            font-weight: 500;
            line-height: rem(22);
            color: #ffffff;
            border-radius: rem(3);

            @include respond(mobile) {
              max-width: rem(134);
              font-size: rem(12);
              font-weight: 400;
            }
          }

          .cart_div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: rem(46);
            min-width: rem(46);
            height: rem(42);
            border: rem(1) solid #775c3d;
            cursor: pointer;
            border-radius: rem(3);

            @include respond(mobile) {
              width: rem(44);
              min-width: rem(44);
              height: rem(41);
            }
          }
        }
      }
    }
  }

  .btn_view_all {
    width: rem(131);
    height: rem(45);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Raleway";
    font-size: rem(12);
    font-weight: 500;
    line-height: rem(14);
    letter-spacing: -0.02em;
    margin: rem(60) auto;
    cursor: pointer;
    box-shadow: none;
    border: 0px;
    outline: none;
    background: #775c3d;
    color: #ffffff;
    text-transform: uppercase;
    border-radius: rem(3);

    @include respond(mobile) {
      margin: rem(34) auto rem(50);
    }
  }
}
