.octave-collection {
  background-color: rgba(244, 242, 238, 1);
  position: relative;

  .octave-banner-section {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    .octave-banner-innersection {
      background: linear-gradient(
        180deg,
        rgba(15, 15, 15, 0) 10%,
        #252422 100%,
        #1a1613 100%
      );

      bottom: 0;
      padding: rem(10) 0;
    }
    @include respond(desktop) {
      min-height: rem(800);
    }
    @include respond(ipadMobile) {
      min-height: rem(450);
    }
  }

  .about-the-collection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: rem(50) rem(0);

    .octave-about-the-collection-container {
      max-width: rem(1312);
      margin-inline: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include respond(ipadMobile) {
        max-width: 94%;
        margin-inline: auto;
      }
      .top-heading {
        color: #5f5f5f;
        font-family: "Raleway";
        font-weight: 400;
        font-size: rem(24);
      }
      .heading-text {
        color: #8e6a4a;
        font-family: "EB Garamond";
        font-size: rem(50);
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        line-height: 1;
        max-width: 100%;
        letter-spacing: rem(0.3);
        @include respond(ipadMobile) {
          font-size: rem(24);
          padding: rem(5) 0;
        }
      }
      p {
        text-align: center;
        color: #4d4d4d;
        letter-spacing: rem(0.5);
        font-size: rem(16);
        font-family: "Raleway";
        font-weight: 400;
        line-height: 1.5;
        @include respond(ipadMobile) {
          text-align: justify;
        }
      }
    }
  }
  .our-journery-section {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: rem(175) 0;
    @include respond(desktop) {
      min-height: rem(650);
    }
    @include respond(ipadMobile) {
      min-height: rem(450);
    }
    .journey-container {
      max-width: rem(1250);
      margin-inline: auto;
      display: flex;
      justify-content: flex-end;
      @include respond(ipadMobile) {
        max-width: 94%;
        margin-inline: auto;
      }
      .journey-right-section {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        max-width: rem(650);
        .heading-text {
          text-transform: uppercase;
          color: #fee698;
          font-weight: 400;
          font-family: "EB Garamond";
          font-size: rem(32);
          letter-spacing: rem(3);
          padding-bottom: rem(15);
        }
        .description {
          color: #ffffff;
          font-weight: 400;
          font-family: "Raleway";
          font-size: rem(16);
          line-height: 1.88;
          letter-spacing: rem(0.8);
          opacity: 0.8;
          padding-bottom: rem(20);
        }
        span {
          background-color: #775c3d;
          padding: rem(17) rem(25);
          color: #ffffff;
          font-weight: 500;
          font-family: "Raleway";
          font-size: rem(14);
          letter-spacing: rem(2);
          text-transform: uppercase;
        }
      }
    }
  }
  .our-collection-wrapper {
    background-color: #472b2d;
    // background-color: #181818;

    .our-collection-wrapper-container {
      max-width: rem(1332);
      margin-inline: auto;
      padding: rem(50) rem(0);
      position: relative;

      .heading-text {
        color: #b6995e;
        font-size: rem(32);
        font-family: "EB Garamond";
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: rem(0.5);
        @include respond(ipadMobile) {
          font-size: rem(24);
          padding-inline: rem(5);
          letter-spacing: rem(0.02);
        }
      }
      .card-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: rem(50);
        padding-top: rem(25);
        @include respond(ipadMobile) {
          flex-direction: column;
          gap: rem(15);
        }
        .card-item {
          min-height: rem(515);
          width: rem(420);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;

          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .inner-card-wrapper {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .inner-card {
              width: 90%;
              min-height: 94%;
              letter-spacing: rem(0.3);
              // padding: rem(10) rem(10);
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;

              .inner-card-content-wrapper {
                padding-top: rem(50);

                h3 {
                  color: #ffffff;
                  text-align: center;
                  font-size: rem(24);
                  font-weight: 500;
                  font-family: "EB Garamond";
                  text-transform: uppercase;
                  line-height: 1.3;
                }

                p {
                  color: #ffffff;
                  text-align: left;
                  font-size: rem(16);
                  font-weight: 300;
                  font-family: "Raleway";
                  text-align: justify;
                  line-height: 2;
                  opacity: 0.7;
                  @include respond(desktop) {
                    min-height: rem(200);
                  }
                  @include respond(ipadMobile) {
                    text-align: justify;
                  }
                }
              }
            }
          }
          @include respond(desktop) {
            &:not(:last-child) {
              &::after {
                position: absolute;
                content: "";
                background-image: url("/octave-collection/right-arrow.png");
                width: rem(60);
                height: rem(50);
                top: 20%;
                right: -11%;
                background-repeat: no-repeat;
              }
            }
          }
        }
      }
    }
  }

  .collection-section {
    padding: rem(80) 0 0;
    @include respond(ipadMobile) {
      padding: rem(50) 0;
    }
    .octave-collection-container {
      max-width: rem(1312);
      margin-inline: auto;
      position: relative;
      .heading-text {
        color: #8e6a4a;
        font-family: "EB Garamond";
        font-size: rem(32);
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        line-height: 1;
        letter-spacing: rem(0.5);
      }
      .tab-container {
        display: flex;
        justify-content: center;
        padding: rem(40) 0;
        @include respond(ipadMobile) {
          padding: rem(20) 0;
        }
        ul {
          display: flex;
          justify-content: center;
          align-content: center;
          gap: 0 rem(20);
          border-bottom: rem(1) solid black;
          // max-width: rem(900);
          @include respond(ipadMobile) {
            gap: 0 rem(20);
          }
          .active {
            color: rgba(142, 106, 74, 1);
            font-weight: 700;

            border-bottom: rem(4) solid rgba(142, 106, 74, 1);
          }
          li {
            color: rgba(0, 0, 0, 1);
            font-family: "EB Garamond";
            font-weight: 500;
            font-size: rem(24);
            text-transform: uppercase;
            letter-spacing: rem(0.5);
            padding: rem(5) 0;
            cursor: pointer;
            @include respond(ipadMobile) {
              font-size: rem(18);
            }
          }
        }
      }

      .dropdown-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include respond(ipadMobile) {
          flex-direction: column-reverse;
          align-items: flex-start;
        }
        .left-section {
          color: rgba(0, 0, 0, 1);
          font-size: rem(16);
          font-family: "Montserrat";
          font-weight: 500;
          @include respond(ipadMobile) {
            font-size: rem(16);
            padding-left: rem(20);
            padding-top: rem(10);
          }
        }
        .right-section {
          display: flex;
          align-items: center;
          gap: rem(15);
          @include respond(ipadMobile) {
            justify-content: center;
            width: 100%;
          }
          .select-item {
            display: flex;
            align-items: center;
            gap: rem(10);
            @include respond(ipadMobile) {
              flex-direction: column;
              align-items: flex-start;
            }
            span {
              color: rgba(0, 0, 0, 1);
              font-family: "Montserrat";
              font-weight: 500;
              font-size: rem(16);
              letter-spacing: rem(0.3);
            }
            .top-nav__search {
              min-width: rem(110);
              .react-select {
                max-width: rem(230);
                cursor: pointer;
                .react-select__control {
                  background-color: transparent;
                }
                .css-13cymwt-control {
                  box-shadow: none !important;
                  border: rem(1) solid rgba(0, 0, 0, 1);
                }
                .css-qbdosj-Input {
                  color: rgba(0, 0, 0, 1) !important;
                }
                .css-t3ipsp-control {
                  box-shadow: none;
                }
                .css-b62m3t-container {
                  border: rem(1) solid rgba(0, 0, 0, 1);
                  border-radius: rem(3);
                  .css-13cymwt-control {
                    background-color: transparent;
                    border: rem(1) solid rgba(0, 0, 0, 1);
                  }
                  .css-1fdsijx-ValueContainer {
                    padding: 0;
                    .css-1dimb5e-singleValue {
                      color: rgba(0, 0, 0, 1);
                      font-size: rem(14);
                      font-weight: 500;
                      font-family: "Raleway";
                      padding-left: rem(10);
                      letter-spacing: rem(2);
                      @include respond(ipad) {
                        font-size: rem(13);
                      }
                      @include respond(mobile) {
                        font-size: rem(12);
                      }
                    }
                  }
                }
                .css-1hb7zxy-IndicatorsContainer {
                  .css-1u9des2-indicatorSeparator {
                    -webkit-align-self: stretch;
                    -ms-flex-item-align: stretch;
                    align-self: stretch;
                    width: 1px;
                    background-color: transparent;
                    margin-bottom: 0;
                    margin-top: 0;
                    display: none;
                  }
                  .css-1xc3v61-indicatorContainer {
                    .css-tj5bde-Svg {
                      fill: rgba(0, 0, 0, 1);
                      line-height: 1;
                      stroke: rgba(0, 0, 0, 1);
                      stroke-width: 0;
                    }
                  }
                }
                .css-1jqq78o-placeholder {
                  color: rgba(0, 0, 0, 1);
                  font-size: rem(16);
                  font-weight: 500;
                  font-family: "Montserrat";
                  padding-left: rem(10);
                }
                // .css-13cymwt-control:hover {
                //   border-color: transparent;
                //   border: rem(1) solid
                //   rgba(0, 0, 0, 1);
                //   outline: none;
                //   box-shadow: none;
                //   background-color: transparent;
                // }
              }
            }
            .react-select__control--is-focused {
              background-color: transparent !important;
              border-color: rgba(0, 0, 0, 1);
              outline: none;
            }
            .react-select__control--menu-is-open {
              border: rem(1) solid rgba(0, 0, 0, 1);
              outline: none;
            }
          }
        }
      }

      .collection-card-wrapper {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: rem(15) rem(15.5);
        padding-top: rem(30);
        @include respond(ipadMobile) {
          flex-direction: column;
        }
        .card-item {
          background-color: white;
          border: rem(0.5) solid #775c3d;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: rem(20) rem(20);
          height: rem(490);
          width: rem(316);
          position: relative;
          .down-arrow-pointer {
            position: absolute;
            bottom: rem(-11);
            left: 50%;
            background-color: white;
            transform: translateX(-50%);
            border-right: 2px solid #775c3d;
            border-bottom: 2px solid #775c3d;
            width: rem(20);
            height: rem(20);
            transform: rotate(45deg);
          }
          @include respond(ipadMobile) {
            width: 90%;
          }
          img {
            max-height: rem(336);
          }
          p {
            color: rgba(119, 92, 61, 1);
            font-size: rem(18);
            font-family: "EB Garamond";
            text-transform: uppercase;
            margin-bottom: 0;
            letter-spacing: rem(0.3);
            line-height: 1.3;
          }
          :nth-child(2) {
            margin-top: rem(5);
            font-weight: 400;
          }
          :nth-child(3) {
            font-weight: 500;
          }
          span {
            margin-top: rem(10);
            padding: rem(10) rem(50);
            border: rem(0.5) solid #775c3d;
            color: #775c3d;
            font-size: rem(13);
            font-weight: 500;
            font-family: "Raleway";
            text-transform: uppercase;
            cursor: pointer;
            letter-spacing: rem(0.5);
          }
        }
        .octave-jounery-carditem {
          min-width: rem(425);
          .image-wrapper {
            img {
              width: 100%;
              height: rem(346);
            }
          }
          .content-wrapper {
            .heading-text {
              color: #775c3d;
              font-size: rem(16);
              font-weight: 600;
              font-family: "EB Garamond";
              text-transform: uppercase;
              padding-top: rem(25);
              letter-spacing: rem(0.3);
              text-align: center;
            }
            .description {
              color: #5c5c5c;
              font-size: rem(12);
              font-weight: 400;
              font-family: "Raleway";
              letter-spacing: rem(0.3);
              text-align: center;
            }
          }
        }
        .card-width-100 {
          @include respond(desktop) {
            min-width: 100%;
          }
          @include respond(ipadMobile) {
          }
        }
      }
      .card-modal-container {
        // position: absolute;
        background-color: white;
        border: rem(1) solid rgba(87, 74, 52, 1);
        display: none;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        // z-index: 10;
        // top: 45%;
        .top-inner-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: rem(15) rem(40) 0;
          @include respond(ipadMobile) {
          }
          .text {
            color: rgba(119, 92, 61, 1);
            font-size: rem(16);
            font-family: "Raleway";
            font-weight: 600;
            text-align: center;
            letter-spacing: rem(0.3);
          }
          .close-btn-wrapper {
            cursor: pointer;
          }
        }
        .card-wrapper {
          width: 100%;
          padding: rem(25) rem(40) rem(30);
          display: flex;
          gap: rem(0) rem(40);
          // justify-content: space-evenly;
          align-items: center;
          overflow-x: auto;
          .card-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: rem(280);
            height: rem(480);
            border: rem(0.5) solid #775c3d;
            padding: rem(15);
            @include respond(ipadMobile) {
              width: 86%;
            }
            img {
              max-height: rem(250);
            }
            p {
              color: #775c3d;
              font-size: rem(14);
              font-weight: 600;
              font-family: "EB Garamond";
              text-transform: uppercase;
              margin-bottom: 0;
              letter-spacing: rem(0.3);
              text-align: center;
            }

            :nth-child(2) {
              margin-top: rem(10);
            }
            :nth-child(3) {
              font-weight: 400;
              font-size: rem(12);
              color: rgba(0, 0, 0, 1);
              padding-top: rem(20);
            }
            span {
              margin-top: rem(10);
              padding: rem(0);
              border: none;
              color: #775c3d;
              font-size: rem(13);
              font-weight: 500;
              font-family: "Raleway";
              text-transform: uppercase;
              cursor: pointer;
              letter-spacing: rem(0.5);
            }
            button {
              background: rgba(119, 92, 61, 1);
              color: rgba(255, 255, 255, 1);
              font-size: rem(16);
              font-weight: 400;
              font-family: "Raleway";
              border: none;
              text-transform: uppercase;
              letter-spacing: rem(1);
              padding: rem(10) rem(48);
              width: 100%;
              margin-top: rem(15);
            }
          }
        }
      }
    }
  }

  .openModal {
    display: flex !important;
  }
}
