.product-container {
  background-color: hsla(0, 0%, 100%, 1);
  width: 100%;
  min-height: 100vh;
}

.backarrow {
  display: none;
}

.firstfold-container {
  padding: rem(158) 0 rem(80);

  @include respond(mobile) {
    padding: rem(100) 0 rem(0);
    position: relative;

    .backarrow {
      position: absolute;
      top: rem(20);
      left: rem(20);
      display: none;
    }
  }

  .left-innerwrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: rem(40);

    .imgClass {
      max-width: rem(425);
      max-height: rem(600);

      @include respond(mobile) {
        max-width: rem(191);
        max-height: rem(240);
      }
    }
  }

  .right-outerwrapper {
    .right-innerwrapper {
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      text-align: center;
      margin: auto;

      @include respond(ipadMobile) {
        width: 100%;
        padding-top: rem(40);

        .productslider-container {
          width: 70%;

          img {
            max-width: rem(73);
            margin: 0 auto;
          }

          margin: rem(50) auto rem(5);

          .slick-prev:before,
          .slick-next:before {
            color: hsla(0, 0%, 30%, 1);
          }
        }
      }

      .top-headingtext {
        color: hsla(0, 0%, 37%, 1);
        font-size: rem(24);
        font-weight: 400;
        font-style: italic;
        font-family: "Baskerville";

        @include respond(mobile) {
          color: hsla(32, 59%, 24%, 1);
          font-size: rem(20);
        }
      }

      .headingText {
        color: #14130F;
        font-size: rem(33);
        font-weight: 600;
        font-family: "EB Garamond";
        text-transform: uppercase;
        line-height: rem(46);
        margin-bottom: 0;
        max-width: 100%;
        text-align: left;
        width: 100%;

        @include respond(ipadMobile) {
          text-align: center;
        }

        @include respond(mobile) {
          max-width: 100%;
          font-size: rem(23);
          font-weight: 500;
          line-height: rem(26);
          padding-top: rem(5);
        }
      }

      .years-text {
        color: hsla(90, 2%, 16%, 1);
        font-size: rem(24);
        font-weight: 400;
        text-transform: uppercase;
        font-family: "Raleway";
        padding: rem(15) 0 rem(15);
        margin: 0;

        @include respond(mobile) {
          padding: rem(0) 0 rem(15);
        }
      }

      .number-container {
        line-height: 1;
        position: relative;

        span {
          color: hsla(43, 69%, 58%, 1);
          font-size: rem(63);
          font-weight: 400;
          font-family: "DM Serif Text";
          letter-spacing: rem(0.2);

          @include respond(mobile) {
            font-size: rem(44);
          }

          // &:first-child {
          //   &::after {
          //     content: "";
          //     position: absolute;
          //     left: 38%;
          //     top: 10%;
          //     transform: translateY(-50%) translateX(15px);
          //     transform: rotate(20deg);
          //     height: rem(50);
          //     width: rem(3);
          //     background-color: hsla(43, 69%, 58%, 1);
          //     @include respond(mobile) {
          //       height: rem(40);
          //     }
          //     @include respond(ipad) {
          //       display: none;
          //     }
          //   }
          // }

          &:not(:first-child) {
            padding-left: rem(70);

            @include respond(mobile) {
              padding-left: rem(40);
            }
          }
        }
      }

      .learnmorebtn {
        color: hsla(0, 0%, 100%, 1);
        font-size: rem(13);
        font-weight: 500;
        line-height: 1;
        min-width: 100%;
        text-align: center;
        font-family: "Roboto";
        margin: rem(20) 0 rem(30);
        padding: rem(15) rem(120);
        background-color: hsla(32, 20%, 43%, 1);
        border-radius: rem(1);
        border: 0px;

        @include respond(mobile) {}
      }

      .delivery-to-wrapper {
        min-width: 100%;
        text-align: left;
        padding: rem(13) rem(36);
        background-color: hsla(40, 21%, 95%, 1);

        @include respond(mobile) {}

        .text {
          color: hsla(32, 20%, 43%, 1);
          font-size: rem(16);
          font-weight: 500;
          font-family: "Raleway";
          margin-bottom: 0;
          padding-bottom: rem(5);

          @include respond(mobile) {}
        }

        .css-b62m3t-container {
          min-width: 100%;

          .css-13cymwt-control:hover {
            border-color: hsla(40, 41%, 44%, 1);

            @include respond(mobile) {}
          }
        }

        .css-1nmdiq5-menu {
          color: hsla(40, 41%, 44%, 1);
          text-transform: uppercase;
          letter-spacing: rem(1);

          @include respond(mobile) {}
        }
      }

      .accordion-wrapper {
        min-width: 100%;
        margin: rem(20) 0;

        @include respond(mobile) {}

        .accordion-item {
          padding: rem(8) 0;
          border-left: none;
          border-right: none;

          @include respond(mobile) {}
        }

        .accordion-button {
          text-transform: uppercase;
          color: hsla(0, 0%, 0%, 1);
          font-weight: 400;
          font-size: rem(21);
          font-family: "Raleway";

          @include respond(mobile) {}
        }

        .accordion-button:not(.collapsed) {
          background-color: white;
          outline: none;
          border-color: white;

          @include respond(mobile) {}
        }
      }

      .product_details_abv_section {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: rem(16) 0 rem(32);

        @include respond(ipadMobile) {
          justify-content: center;
        }

        @include respond(mobile) {
          padding: rem(16) 0 0;
        }

        .product_details_box {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: rem(5);
          text-align: left;

          h3 {
            font-family: "Raleway";
            font-size: rem(18);
            font-weight: 400;
            line-height: rem(21);
            letter-spacing: 0.01em;
            color: #292A28;
            margin: 0;

            @include respond(mobile) {
              font-size: rem(13);
              gap: rem(3);
            }
          }
        }

        .vertical_line {
          display: block;
          height: rem(18);
          width: rem(1);
          background: #292A28;
          margin: rem(2) rem(10) rem(0);

          @include respond(mobile) {
            height: rem(15);
            margin: rem(3) rem(5) rem(0);
          }
        }
      }

      .product_sort_description {
        display: block;

        p {
          font-family: "Raleway";
          font-size: rem(18);
          font-weight: 400;
          line-height: rem(25);
          text-align: left;
          color: #292A28;
          margin: 0 0 rem(8);

          @include respond(ipadMobile) {
            text-align: center;
          }

          @include respond(mobile) {
            font-size: rem(12);
            line-height: rem(18);
          }
        }

        .read_more_btn {
          display: block;
          background: transparent;
          outline: none;
          box-shadow: none;
          font-family: "Raleway";
          font-size: rem(18);
          font-weight: 900;
          line-height: rem(25);
          text-align: left;
          color: #775C3D;
          border: 0;
          width: auto;
          height: auto;
          padding: 0;
          margin: 0;

          @include respond(ipadMobile) {
            margin: 0 auto;
          }

          @include respond(mobile) {
            font-size: rem(16);
          }
        }
      }

      .ex_wait_price_section {
        display: flex;
        justify-content: left;
        gap: rem(5);
        width: 100%;
        margin: rem(32) 0 rem(12);

        @include respond(ipadMobile) {
          justify-content: center;
        }

        @include respond(mobile) {
          margin: rem(4) 0 rem(12);
        }

        h3 {
          font-family: "Raleway";
          font-size: rem(32);
          font-weight: 600;
          line-height: rem(43);
          letter-spacing: 0.01em;
          text-align: left;
          margin: 0;

          @include respond(mobile) {
            font-size: rem(20);
          }
        }
      }
    }
  }
}

.collection-container {
  text-align: center;
  max-width: 80%;
  margin-inline: auto;
  padding: rem(20) 0 rem(120);
  position: relative;

  @include respond(mobile) {
    max-width: 90%;
    padding: rem(50) 0 rem(50);
  }

  .toptext {
    color: hsla(90, 2%, 16%, 1);
    font-size: rem(18);
    font-weight: 500;
    text-transform: uppercase;
    font-family: "Raleway";
    letter-spacing: rem(3);
    position: relative;

    @include respond(ipadMobile) {
      margin: 0 0 rem(20);
    }

    @include respond(mobile) {
      font-size: rem(14);
      padding: 0 rem(50);
    }

    &::after {
      content: "";
      position: absolute;
      right: rem(20);
      top: 50%;
      height: rem(2);
      width: rem(410);
      background-color: hsla(40, 41%, 44%, 1);


      @include respond(smalldesktop) {
        width: rem(310);
      }

      @include respond(ipadProMobile) {
        width: rem(200);
      }

      @include respond(ipadMobile) {
        top: 48%;
        width: rem(80);
        height: rem(1);
        right: rem(48);
      }

      @include respond(mobile) {
        top: 48%;
        width: rem(40);
        height: rem(1);
        right: 0;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: rem(20);
      top: 50%;
      height: rem(2);
      width: rem(410);
      background-color: hsla(40, 41%, 44%, 1);

      @include respond(smalldesktop) {
        width: rem(310);
      }

      @include respond(ipadProMobile) {
        width: rem(200);
      }

      @include respond(ipadMobile) {
        top: 48%;
        width: rem(80);
        height: rem(1);
        left: rem(48);
      }

      @include respond(mobile) {
        top: 48%;
        width: rem(40);
        height: rem(1);
        left: 0;
      }
    }
  }

  .headingText {
    color: hsla(40, 41%, 44%, 1);
    font-size: rem(39);
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;
    padding: rem(25) 0 rem(30);
    font-family: "EB Garamond";

    @include respond(mobile) {
      font-size: rem(26);
      padding: rem(15) 0 rem(20);
    }
  }

  .description-text {
    color: #4d4d4d;
    font-size: rem(16);
    font-weight: 400;
    line-height: 1.5;
    max-width: 80%;
    margin: auto;
    letter-spacing: rem(0.4);
    font-family: "Raleway";

    @include respond(mobile) {
      font-size: rem(14);
      max-width: 90%;
    }
  }

  &::after {
    content: "";
    position: absolute;
    right: 50%;
    bottom: rem(-80);
    transform: translateY(-50%) translateX(15px);
    height: rem(80);

    width: rem(2);
    background-color: hsla(35, 56%, 52%, 1);
    display: none;

    @include respond(ipadMobile) {
      display: none;
    }
  }
}

.testingnote-container {
  display: flex;
  position: relative;
  justify-content: flex-end;

  @include respond(ipadMobile) {
    background-color: hsla(0, 0%, 0%, 1);
    padding: rem(50) 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .TopheadingText {
      color: hsla(0, 0%, 100%, 1);
      font-size: rem(26);
      font-weight: 400;
      font-family: "EB Garamond";
      text-transform: uppercase;
      line-height: 1.1;
      padding-bottom: rem(20);
    }

    .topinner-container {
      .grid-container {
        display: grid;
        grid-template-columns: rem(165) rem(165);

        .item {
          background-color: transparent;
          border: rem(1) solid white;
          color: hsla(0, 0%, 100%, 1);
          border-radius: 0;
          min-height: rem(87);
          padding: rem(10) rem(10);

          .colorimage {
            max-width: rem(112);
            max-height: rem(31);
            padding-top: rem(0);
          }

          .headingText {
            font-weight: 600;
            font-size: rem(14);
            text-align: left;
            font-family: "Raleway";
            margin-bottom: rem(5);
          }

          .subtext {
            font-weight: 400;
            letter-spacing: rem(1);
            font-size: rem(10);
            text-align: left;
            font-family: "Raleway";
            line-height: 1.1;
            margin: 0;
            opacity: 0.9;
          }
        }
      }
    }

    .bottominner-container {
      display: flex;
      justify-content: center;

      .rare-image {
        max-width: 100%;
        min-height: rem(396);
      }
    }
  }

  .inner-container {
    height: rem(820);
    width: 86%;
    @include bgCover();
  }

  .left-innerwrapper {
    height: rem(820);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    min-width: 40%;
    top: 2;
    background-image: linear-gradient(to right,
        hsla(240, 100%, 0%, 1),
        hsla(240, 100%, 0%, 1),
        hsla(240, 100%, 0%, 0.45),
        hsla(240, 100%, 0%, 0.22),
        hsla(240, 100%, 0%, 0.1),
        hsla(240, 100%, 0%, 0));

    .TopheadingText {
      color: hsla(0, 0%, 100%, 1);
      font-size: rem(39);
      font-weight: 500;
      font-family: "EB Garamond";
      text-transform: uppercase;
      line-height: 1.1;
      padding-bottom: rem(30);
      margin-left: rem(180);
      text-align: center;

      @include respond(mobile) {
        max-width: 100%;
        font-size: rem(34);
        font-weight: 500;
        padding-top: rem(25);
      }
    }

    .grid-container {
      padding-left: rem(150);
      display: grid;
      grid-template-columns: rem(295) rem(295);

      .item {
        background-color: transparent;
        border: 1px solid white;
        color: hsla(0, 0%, 100%, 1);
        border-radius: 0;
        min-height: rem(186);
        padding: rem(30) rem(25);

        .colorimage {
          max-width: rem(240);
          max-height: rem(31);
          padding-top: rem(10);
        }

        .headingText {
          font-weight: 700;
          font-size: rem(24);
          text-align: left;
          font-family: "Lato";
        }

        .subtext {
          font-weight: 400;
          font-size: rem(16);
          text-align: left;
          font-family: "Raleway";
          line-height: 1.15;
          letter-spacing: rem(0.2);
          margin: 0;
        }
      }
    }
  }
}

.flovourwheel-container {
  margin: rem(30) 0;
  border-top: rem(2) solid hsla(0, 0%, 83%, 1);
  border-bottom: rem(2) solid hsla(0, 0%, 83%, 1);

  @include respond(mobile) {
    border: none;
  }

  .left-innercontainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: auto;
    position: relative;
    padding: rem(80) 0;

    @include respond(mobile) {
      padding: rem(30) 0;
      border-bottom: rem(1) solid hsla(40, 41%, 44%, 1);
      width: 90%;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) translateX(15px);
      height: 100%;
      width: rem(2);
      background-color: hsla(0, 0%, 83%, 1);

      @include respond(mobile) {
        display: none;
      }

      @include respond(ipad) {
        display: none;
      }
    }

    .headingText {
      color: hsla(40, 41%, 44%, 1);
      font-size: rem(39);
      font-weight: 500;
      text-transform: uppercase;
      margin: 0;
      padding: rem(20) 0 rem(30);
      font-family: "EB Garamond";

      @include respond(mobile) {
        color: hsla(0, 0%, 9%, 1);
        font-size: rem(26);
        font-weight: 400;
        padding: rem(20) 0;
      }
    }

    .description-text {
      color: #292a28;
      font-size: rem(16);
      font-weight: 400;
      font-family: "Raleway";
      width: 65%;
      line-height: 1.5;
      margin-inline: auto;
      letter-spacing: rem(0.3);

      @include respond(mobile) {
        width: 90%;
        font-size: rem(14);
      }
    }

    .logo {
      margin-top: rem(60);

      @include respond(mobile) {
        margin-top: rem(20);
        height: rem(97);
      }

      @include respond(mediumMobile) {
        width: 60%;
        height: 100%;
      }
    }
  }

  .right-innercontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: rem(80) 0;

    @include respond(desktop) {
      min-height: 100%;
    }

    @include respond(mobile) {
      padding: rem(0) rem(12);
    }

    .radar_chart_div {
      height: rem(455);
      width: rem(550);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: rem(24);

      @include respond(mobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
      }

      @include respond(smallmobile) {
        height: 100%;
        width: 100%;
      }
    }

    .image {
      height: rem(441);
      width: rem(553);

      @include respond(mobile) {
        height: rem(274);
        width: rem(328);
      }
    }
  }
}

.slider-container {
  padding: rem(20) rem(40);

  @include respond(mobile) {
    padding: rem(15) rem(15) rem(50);
    margin-bottom: rem(20);
  }

  .image {
    max-height: rem(760);
    width: 100%;

    @include respond(mobile) {
      min-height: rem(340);
      width: 100%;
    }
  }

  .slick-slider {
    @include respond(mobile) {
      ul {
        display: none !important;
      }
    }

    .slick-prev {
      @include respond(mobile) {
        left: 40%;
      }

      .slick-prev:before {
        font-size: rem(40);

        @include respond(mobile) {
          font-size: rem(24);
        }
      }

      &::before {
        font-size: rem(40);

        @include respond(mobile) {
          font-size: rem(24);
        }
      }
    }

    .slick-arrow,
    .slick-next {
      width: rem(40);
      height: rem(40);

      @include respond(mobile) {
        width: rem(24);
        height: rem(24);
        bottom: 0;
        top: 112%;
        right: 40%;
        color: hsla(32, 20%, 43%, 1);
      }

      .slick-prev:before {
        font-size: rem(40);

        @include respond(mobile) {
          font-size: rem(24);
        }
      }

      &::before {
        font-size: rem(40);

        @include respond(mobile) {
          font-size: rem(24);
          color: hsla(32, 20%, 43%, 1);
        }
      }
    }

    .slick-dots {
      @include respond(desktop) {
        bottom: rem(16);
      }

      @include respond(mobile) {}

      li button::before {
        font-size: rem(18);
        color: hsla(38, 29%, 43%, 1);

        @include respond(mobile) {}
      }
    }
  }
}

.cask-container {
  background-color: white;
  padding: rem(80) 0;

  @include respond(mobile) {
    padding: 0 0 rem(60);
  }

  .toptext {
    font-size: rem(33);
    font-weight: 400;
    font-style: italic;
    color: hsla(38, 29%, 43%, 1);
    text-align: center;
    font-family: "Baskerville";

    @include respond(mobile) {
      font-size: rem(20);
      color: hsla(40, 41%, 44%, 1);
    }
  }

  .inner-container {
    width: 60%;
    margin-inline: auto;
    @include bgCover();

    @include respond(mobile) {
      min-width: 90%;
      letter-spacing: rem(0.3);
    }

    .headingText {
      font-size: rem(51);
      font-weight: 400;
      color: hsla(38, 29%, 43%, 1);
      text-align: center;
      font-family: "EB Garamond";
      text-transform: uppercase;
      text-align: center;
      padding-bottom: rem(40);

      @include respond(mobile) {
        font-size: rem(26);
        padding-bottom: rem(20);
        padding-top: rem(15);
      }
    }

    .description-text {
      font-size: rem(16);
      font-weight: 400;
      color: #292a28;
      text-align: center;
      font-family: "Raleway";
      line-height: 1.65;
      letter-spacing: rem(0.3);

      @include respond(mobile) {
        font-size: rem(14);
        line-height: 1.5;
        letter-spacing: rem(0.2);
      }

      span {
        color: hsla(38, 29%, 43%, 1);

        @include respond(mobile) {
          color: hsla(40, 38%, 54%, 1);
        }
      }
    }
  }
}

.discover-collection-container {
  background-color: hsla(240, 33%, 1%, 1);
  min-width: 100%;
  padding: rem(80) rem(100) 0;

  @include respond(ipadProMobile) {
    padding: rem(60) rem(40) 0;
  }

  @include respond(ipadMobile) {
    padding: rem(40) rem(20) 0;
  }

  @include respond(mobile) {
    padding: rem(40) rem(0);
  }

  .headingText {
    font-size: rem(34);
    font-weight: 600;
    color: hsla(0, 0%, 17%, 1);
    text-align: center;
    font-family: "EB Garamond";
    text-transform: uppercase;
    text-align: center;
    padding-bottom: rem(30);

    @include respond(mobile) {
      font-size: rem(26);
      font-weight: 400;
      color: hsla(41, 44%, 72%, 1);
      text-align: center;
      font-family: "EB Garamond";
      opacity: 0.8;
      padding: 0 rem(20) rem(20);
    }
  }

  .card-container {
    // max-width: 90%;
    // margin: auto;
    height: calc(100% - rem(75));
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: rem(25);
    border: rem(1) solid hsla(38, 25%, 27%, 1);
    margin-bottom: rem(75);

    @include respond(mobile) {
      padding: rem(15) rem(15) rem(25);
      max-width: 100%;
      margin: rem(10) 0;
      height: auto;
    }

    .bottle-wrapper {
      background-color: hsla(40, 21%, 95%, 1);
      padding: rem(55) rem(0);
      min-width: 100%;
      display: flex;
      justify-content: center;

      @include respond(mobile) {
        padding: rem(35) rem(70);
      }

      @include respond(smallmobile) {
        padding: rem(35) rem(40);
      }

      .image {
        // width: rem(276);
        max-height: rem(340);

        @include respond(ipadProMobile) {
          // width: rem(218);
          max-height: rem(280);
        }

        @include respond(ipadMobile) {
          // width: rem(218);
          max-height: rem(200);
        }

        @include respond(mobile) {
          // width: rem(218);
          max-height: rem(270);
        }
      }
    }

    .card-heading {
      font-size: rem(40);
      font-weight: 400;
      color: hsla(36, 73%, 93%, 1);
      text-align: center;
      font-family: "EB Garamond";
      text-transform: uppercase;
      text-align: center;
      line-height: 1;
      padding-top: rem(15);

      // @include respond(desktop) {
      //   min-height: rem(100);
      // }
      @include respond(ipadProMobile) {
        font-size: rem(30);
      }

      @include respond(ipadMobile) {
        font-size: rem(18);
      }

      @include respond(mobile) {
        font-size: rem(24);
        padding-top: rem(10);
        color: hsla(41, 44%, 72%, 1);
      }
    }

    .card-subheading-text {
      font-size: rem(21);
      font-weight: 400;
      font-style: italic;
      color: hsla(36, 36%, 65%, 1);
      text-align: center;
      font-family: "Baskerville";
      margin: 0;

      @include respond(mobile) {
        font-size: rem(14);
        opacity: 0.6;
      }
    }

    .learnmorebtn {
      padding: rem(18) rem(100);
      background-color: transparent;
      border-radius: rem(1);
      border: rem(1) solid hsla(32, 20%, 43%, 1);
      color: hsla(36, 36%, 65%, 1);
      font-size: rem(13);
      font-weight: 500;
      font-family: "Roboto";
      margin-top: rem(20);

      @include respond(ipadProMobile) {
        padding: rem(18) rem(60);
      }

      @include respond(ipadMobile) {
        padding: rem(18) rem(25);
      }

      @include respond(mobile) {
        background-color: hsla(32, 32%, 35%, 1);
        margin-top: rem(15);
        font-family: "Raleway";
        font-size: rem(10);
        border: rem(1) solid hsla(32, 20%, 43%, 1);
        padding: rem(13) rem(30);
        color: hsla(0, 0%, 100%, 1);
        border-radius: rem(1);
      }
    }
  }
}

.testing_nots_box_section {
  gap: rem(32) 0;
  margin: rem(60) 0;

  .row_of_testing_notes {
    justify-content: center;
    gap: rem(24) 0;
  }

  .testing_box {
    display: block;
    padding: rem(40);
    border: rem(2) solid #9F8043;
    height: 100%;

    h2 {
      font-family: "EB Garamond";
      font-size: rem(24);
      font-weight: 600;
      line-height: rem(31);
      letter-spacing: 0.01em;
      margin: 0 0 rem(16);
      text-align: left;
      color: #9F8043;
      text-transform: uppercase;
    }

    p {
      font-family: "Raleway";
      font-size: rem(16);
      font-weight: 400;
      line-height: rem(25);
      text-align: left;
      margin: 0;
      color: #292A28;
    }
  }
}

.octave_product_details_section {
  display: block;
  margin: rem(80) 0 0;
  padding-left: 10vw;

  @include respond(ipadMobile) {
    padding-left: 0;
  }

  @include respond(mobile) {
    padding-left: 20vw;
    margin: rem(50) 0 0;
  }

  @include respond(mediumMobile) {
    padding-left: 0;
  }

  .octave_product_gap {
    gap: rem(32) 0;

    @include respond(mediumMobile) {
      gap: rem(24) 0;
    }
  }

  h2 {
    font-family: "EB Garamond";
    font-size: rem(24);
    font-weight: 600;
    line-height: rem(31);
    letter-spacing: 0.01em;
    text-align: left;
    margin: 0;
    color: #9F8043;

    @include respond(mediumMobile) {
      font-size: rem(20);
      line-height: normal;
    }
  }

  h3 {
    font-family: "Raleway";
    font-size: rem(24);
    font-weight: 600;
    line-height: rem(28);
    letter-spacing: 0.01em;
    text-align: left;
    color: #292A28;
    margin: 0;

    @include respond(mediumMobile) {
      font-size: rem(20);
      line-height: normal;
    }
  }
}