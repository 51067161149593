.single_cask_banner {
    display: block;
    width: 100%;
    height: auto;
    position: relative;

    .banner_bg_img {
        display: block;
        width: 100% !important;
        height: 100vw !important;
        max-height: 100vh;
    }

    .banner_text_box {
        display: block;
        position: absolute;
        top: 60%;
        transform: translateY(-50%);
        right: 14vw;
        max-width: 30vw;

        @include respond(ipadProMobile) {
            right: 6vw;
            max-width: 42vw;
        }

        // @include respond(mediumMobile) {
        //     right: 6vw;
        //     max-width: 42vw;
        // }


        h2 {
            margin: 0 0 5vw;
            font-family: "EB Garamond";
            font-size: rem(76);
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.01em;
            background-image: -webkit-linear-gradient(bottom, #F8E0A6, #FFD192);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @include respond(ipadProMobile) {
                font-size: rem(56);
            }

            @include respond(mediumMobile) {
                font-size: rem(26);
            }

        }

        h3 {
            font-family: "Raleway";
            font-size: rem(46);
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.01em;
            margin: 0;
            text-transform: uppercase;
            color: #FFFFFF;

            @include respond(ipadProMobile) {
                font-size: rem(36);
            }

            @include respond(mediumMobile) {
                font-size: rem(16);
            }
        }
    }

}

.single_cask_collection_details {
    display: block;
    padding: rem(160) 0 rem(139);

    &.padding_top_zero {
        padding-top: 0;
    }

    @include respond(mobile) {
        padding: rem(32) 0;
    }

    .reverse_div {
        flex-direction: row-reverse;
    }

    .Column_reverse_div {
        @include respond(mobile) {
            flex-direction: column-reverse;
        }
    }

    .single_cask_details {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: rem(700);
        margin: 0 auto;
        height: 100%;

        @include respond(ipadMobile) {
            max-width: 100%;
            padding-bottom: rem(80);
        }

        @include respond(mobile) {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: rem(15);
            padding-bottom: rem(12);
        }

        &.single_content {
            @include respond(mobile) {
                display: block;
                padding-top: rem(25);
            }

            .cask_mobile_img {
                display: none;
            }
        }

        .cask_mobile_img {
            display: none;

            @include respond(mobile) {
                display: block;
            }

            img {
                width: auto !important;
                height: 75vw !important;
            }
        }

        .inner_div {
            display: block;

            @include respond(mobile) {
                text-align: left;
            }

            h2 {
                font-family: "EB Garamond";
                font-size: rem(54);
                font-weight: 700;
                line-height: rem(60);
                letter-spacing: 0.01em;
                margin: 0 0 rem(30);
                color: #8E6A4A;
                // max-width: rem(380);
                text-transform: uppercase;

                @include respond(ipadMobile) {
                    max-width: 100%;
                    text-align: center;
                }

                @include respond(mobile) {
                    font-size: rem(24);
                    line-height: rem(24);
                    text-transform: uppercase;
                    margin: 0 0 rem(20);
                }
            }

            h4 {
                font-family: "EB Garamond";
                font-size: rem(14);
                font-weight: 700;
                line-height: rem(17);
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #1C1B1B;
                margin: 0 0 rem(30);

                @include respond(ipadMobile) {
                    text-align: center;
                }

            }

            p {
                font-family: 'Raleway';
                font-size: rem(20);
                font-weight: 400;
                line-height: tem(31);
                margin: 0 0 rem(40);
                color: #8F8C86;

                @include respond(ipadMobile) {
                    text-align: center;
                }

                @include respond(mobile) {
                    font-size: rem(15);
                    line-height: rem(24);
                    margin: 0 0 rem(20);
                }
            }

            h3 {
                font-family: 'Raleway';
                font-size: rem(28);
                font-style: italic;
                font-weight: 400;
                line-height: rem(35);
                letter-spacing: 0.02em;
                margin: 0;
                color: #8E6A4A;
                max-width: rem(600);

                &.desktop-text {
                    display: block;
                }

                @include respond(ipadMobile) {
                    max-width: 100%;
                    text-align: center;
                }

                @include respond(mobile) {
                    font-size: rem(18);
                    line-height: rem(18);

                    &.desktop-text {
                        display: none;
                    }
                }
            }

            .btn_Explore_the_range {
                border: rem(2) solid #8E6A4A;
                background: transparent;
                outline: none;
                box-shadow: none;
                border-radius: rem(4);
                width: rem(226);
                height: rem(40);
                font-family: "Raleway";
                font-size: rem(15);
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.09em;
                text-transform: uppercase;
                color: #8E6A4A;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                margin: rem(36) 0 0;
            }
        }
    }

    .single_cask_collection_detail_img {
        display: block;

        img {
            width: 100% !important;
            height: auto !important;
        }

        h3 {
            font-family: 'Raleway';
            font-size: rem(18);
            font-style: italic;
            font-weight: 400;
            line-height: rem(19);
            letter-spacing: 0.02em;
            margin: 0;
            color: #8E6A4A;
            max-width: rem(600);
            display: none;

            @include respond(mobile) {
                display: block;
                max-width: rem(340);
                margin: rem(20) 0 rem(0);
            }
        }
    }
}


.single_cask_whisky_collection_section {
    display: block;
    margin-bottom: rem(64);

    @include respond(mobile) {
        margin-bottom: rem(20);
    }

    .collection_title {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #8E6A4A;
        padding: rem(19) 0;
        font-family: "EB Garamond";
        font-size: rem(44);
        font-weight: 700;
        line-height: rem(60);
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #FFFFFF;

        @include respond(mobile) {
            font-size: rem(32);
        }

        @include respond(smallmobile) {
            font-size: rem(26);
        }
    }

    .single_cask_collection_card_section {
        display: block;

        h2 {
            font-family: "Raleway";
            font-size: rem(24);
            font-weight: 800;
            line-height: rem(39);
            text-align: center;
            color: #8E8371;
            margin: rem(56) 0;

            @include respond(mobile) {
                text-align: left;
                margin: rem(35) 0;
                font-size: rem(18);
                line-height: rem(23);
                font-weight: 500;
            }
        }

        .collection_card {
            height: 100%;
            text-align: center;

            .collection_card_img {
                display: block;
                height: 30vw !important;

                @include respond(mobile) {
                    height: 75vw !important;
                }

                img {
                    width: auto !important;
                    max-width: 95% !important;
                    margin: 0 auto;
                    height: 30vw !important;
                    transform: scale(1);
                    transition: transform .3s ease-out;

                    &:hover {
                        transition: transform .3s ease-out;
                        transform: scale(1.05);
                    }

                    @include respond(mobile) {
                        height: 75vw !important;
                    }
                }
            }

            .collection_card_details {
                display: flex;
                flex-wrap: wrap;
                align-content: space-between;
                margin-top: rem(44);
                height: calc(100% - 34vw);

                @include respond(mobile) {
                    margin-top: rem(20);
                    height: calc(100% - 80vw);
                }

                .collection_whisky_data {
                    display: block;
                    width: 100%;

                    h4 {
                        font-family: "Raleway";
                        font-size: rem(17);
                        font-weight: 500;
                        line-height: rem(23);
                        color: #000000;
                        margin: 0;
                        text-transform: uppercase;

                        @include respond(mobile) {
                            font-size: rem(12);
                        }
                    }

                    h3 {
                        font-family: "EB Garamond";
                        font-size: rem(28);
                        font-weight: 800;
                        line-height: rem(39);
                        margin: rem(2) 0 rem(12);
                        text-transform: uppercase;
                        color: #000000;

                        @include respond(mobile) {
                            font-size: rem(18);
                            line-height: rem(23);
                            margin: 0 0 rem(8);
                        }
                    }
                }

                .collection_btn_group {
                    width: 100%;

                    .btn_view_range {
                        width: rem(161);
                        height: rem(40);
                        border: 2px solid #8E6A4A;
                        color: #8E6A4A;
                        background: #FFFFFF;
                        padding: 0;
                        outline: none;
                        font-family: "Raleway";
                        font-size: rem(18);
                        font-weight: 500;
                        line-height: normal;
                        border-radius: rem(4);
                        margin-bottom: rem(102);

                        @include respond(mobile) {
                            width: rem(131);
                            height: rem(32);
                            border: 1px solid #8E6A4A;
                            margin-bottom: rem(60);
                            font-size: rem(14);
                        }
                    }
                }
            }
        }
    }
}

.single_cask_collection_popup_section {
    width: 100%;
    margin-bottom: rem(15);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    .single_cask_collection_popup_inner_div {
        display: block;
        background: #8A6B3C;
        border-radius: rem(8);
        padding: rem(7);

        .collection_popup_header {
            padding: rem(19) rem(32);
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include respond(mobile) {
                padding: 0 rem(10) rem(10);
            }

            h5 {
                font-family: "EB Garamond";
                font-size: rem(32);
                font-weight: 800;
                line-height: rem(39);
                color: #FFFFFF;
                text-transform: uppercase;
                margin: 0;

                @include respond(mobile) {
                    font-size: rem(18);
                }
            }

            .close_icon_div {
                display: block;

                img {
                    width: rem(25);
                    height: rem(25);
                    filter: invert(1);
                    cursor: pointer;

                    @include respond(mobile) {
                        width: rem(14);
                        height: rem(14);
                    }
                }
            }
        }

        .single_cask_collection_popup_body {
            display: block;
            background: #FFFFFF;
            border-radius: rem(12);
            padding: rem(50);

            @include respond(mediumMobile) {
                padding: rem(16);
            }

            .collection_popup_card {
                display: flex;
                height: 100%;
                align-items: baseline;
                gap: rem(10);
                padding: rem(25) 0;

                @include respond(smallmobile) {
                    gap: rem(4);
                }

                .collection_popup_card_img {
                    display: block;
                    height: 25vw !important;

                    @include respond(ipadProMobile) {
                        height: 25vw !important;
                    }

                    @include respond(ipadMobile) {
                        height: 27vw !important;
                    }

                    @include respond(smallmobile) {
                        width: 100%;
                    }

                    img {
                        width: auto !important;
                        max-width: 95% !important;
                        margin: 0 auto;
                        height: 25vw !important;

                        @include respond(ipadProMobile) {
                            height: 25vw !important;
                        }

                        @include respond(ipadMobile) {
                            height: 27vw !important;
                        }
                    }
                }

                .collection_card_popup_details {
                    max-width: rem(111);
                    margin-bottom: rem(15);

                    .collection_whisky_popup__data {
                        display: block;
                        width: 100%;

                        h4 {
                            font-family: "Raleway";
                            font-size: rem(17);
                            font-weight: 500;
                            line-height: rem(20);
                            color: #000000;
                            margin: 0;
                            text-transform: uppercase;

                            @include respond(mediumMobile) {
                                font-size: rem(13);
                                line-height: rem(15);
                            }

                            @include respond(smallmobile) {
                                font-size: rem(9);
                                line-height: normal;
                            }
                        }

                        h3 {
                            font-family: "EB Garamond";
                            font-size: rem(28);
                            font-weight: 800;
                            line-height: rem(39);
                            margin: rem(12) 0 0;
                            text-transform: uppercase;
                            color: #000000;
                        }

                        .view_more_for_collection {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: rem(8) rem(4);
                            border: rem(1) solid #8E6A4A;
                            border-radius: rem(4);
                            background: #FFF;
                            font-family: "Raleway";
                            font-size: rem(12);
                            font-weight: 500;
                            line-height: normal;
                            text-transform: uppercase;
                            color: #8E6A4A;

                            @include respond(smallmobile) {
                                font-size: rem(10);
                                line-height: normal;
                                padding: rem(4);
                            }
                        }
                    }
                }
            }
        }
    }
}

.single_cask_whisky_details_card_section {
    display: block;
    padding: rem(120) 0;

    @include respond(mobile) {
        padding: rem(40) 0 rem(40) rem(24);
    }

    .desktop {
        display: block;

        @include respond(mobile) {
            display: none;
        }
    }

    .single_cask_mobile_slider {
        display: none;

        @include respond(mobile) {
            display: block;
        }

        .slick-slider {


            .slick-list {
                .slick-track {
                    display: flex;
                }

                .slick-active {
                    width: calc(100vw - 25vw) !important
                }
            }
        }
    }

    .single_cask_whisky_card {
        display: block;
        height: 100%;

        @include respond(mobile) {
            padding-right: rem(24);
        }

        .whisky_img_div {
            display: block;
            background: #F5F1EB;
            padding: rem(30);
            height: 29vw;
            text-align: center;

            @include respond(mobile) {
                height: 70vw;
                padding: rem(24);
            }

            @include respond(mediumMobile) {
                height: 65vw;
                padding: rem(24);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                width: auto !important;
                height: 100% !important;
            }
        }

        .whisky_details {
            display: block;
            padding-top: rem(24);
            height: calc(100% - 29vw);
            display: flex;
            flex-wrap: wrap;

            .whisky_data {
                display: block;
                text-align: center;
                width: 100%;

                h2 {
                    font-family: "Raleway";
                    font-size: rem(17);
                    font-weight: 500;
                    line-height: rem(23);
                    text-align: center;
                    color: #000000;
                    text-transform: uppercase;
                    margin: 0;

                    @include respond(ipadMobile) {
                        font-size: rem(15);
                        line-height: rem(19);
                    }

                    @include respond(mediumMobile) {
                        text-align: left;
                        font-size: rem(15);
                    }
                }

                h3 {
                    font-family: "EB Garamond";
                    font-size: rem(28);
                    font-weight: 800;
                    line-height: rem(39);
                    color: #000000;
                    text-transform: uppercase;
                    margin: 0;

                    @include respond(ipadProMobile) {
                        font-size: rem(24);
                        line-height: rem(29);
                    }

                    @include respond(ipadMobile) {
                        font-size: rem(20);
                        line-height: rem(25);
                    }

                    @include respond(mediumMobile) {
                        text-align: left;
                        font-size: rem(18);
                        font-weight: 500;
                    }
                }
            }

            .whisky_btn_group {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: rem(12);
                width: 100%;

                @include respond(mediumMobile) {
                    margin-top: rem(8);
                    justify-content: left;
                }

                .btn_buy_now {
                    width: rem(161);
                    height: rem(40);
                    border: rem(2) solid #8E6A4A;
                    color: #8E6A4A;
                    font-family: "Raleway";
                    font-size: rem(18);
                    font-weight: 500;
                    line-height: normal;
                    text-align: center;
                    border-radius: rem(4);
                    box-shadow: none;
                    outline: none;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include respond(ipadMobile) {
                        width: rem(120);
                        height: rem(35);
                        font-size: rem(15);
                    }

                    @include respond(mediumMobile) {
                        width: rem(131);
                        height: rem(32);
                        border: rem(1) solid #8E6A4A;
                        font-size: rem(14);
                        line-height: rem(19);
                    }
                }
            }
        }
    }
}

.single_cask_featured_banner_section {
    display: block;
    padding-bottom: rem(118);

    @include respond(mobile) {
        padding-bottom: rem(0);
    }

    .collection_container {
        @include respond(mobile) {
            max-width: 100%;
        }
    }

    .single_cask_featured_banner_bg_div {
        background: #171716;
        height: 40vw;
        position: relative;

        @include respond(mediumMobile) {
            height: rem(242);
        }

        @include respond(smallmobile) {
            height: rem(180);
        }

        .octave_collection_img {
            width: auto !important;
            height: 40vw !important;
            position: absolute;
            top: 0;
            right: 8vw;

            @include respond(mediumMobile) {
                height: rem(242) !important;
            }

            @include respond(smallmobile) {
                height: rem(180) !important;
            }

            &.desktop-img {
                display: block;


                @include respond(mobile) {
                    display: none;
                }
            }

            &.mobile-img {
                display: none;

                @include respond(mobile) {
                    display: block;
                }
            }
        }

        .single_cask_featured_octave_details_section {
            display: block;
            max-width: rem(600);
            position: absolute;
            left: 10vw;
            bottom: 10vw;

            @include respond(laptopdesktop) {
                max-width: rem(600);
                left: 7vw;
                bottom: 6vw;
            }

            @include respond(ipadProMobile) {
                max-width: rem(500);
                left: rem(80);
                bottom: rem(80);
            }

            @include respond(ipadMobile) {
                max-width: rem(350);
                left: rem(40);
                bottom: rem(40);
            }

            @include respond(mobile) {
                max-width: rem(300);
                left: rem(40);
                bottom: rem(40);
            }


            @include respond(mediumMobile) {
                max-width: rem(160);
            }

            @include respond(smallmobile) {
                left: rem(20);
                bottom: rem(10);
            }


            h5 {
                font-family: "Raleway";
                font-size: rem(18);
                font-weight: 500;
                line-height: rem(24);
                margin: 0;
                color: #FFFFFF;

                @include respond(mediumMobile) {
                    font-size: rem(12);
                    line-height: rem(16);
                }
            }

            h2 {
                font-family: "EB Garamond";
                font-size: rem(62);
                font-weight: 700;
                line-height: rem(56);
                letter-spacing: 0.01em;
                text-transform: capitalize;
                margin: rem(12) 0 rem(40);
                color: #FDFBF6;
                text-transform: uppercase;

                @include respond(laptopdesktop) {
                    font-size: rem(50);
                }

                @include respond(ipadMobile) {
                    font-size: rem(35);
                    line-height: rem(36);
                    margin: rem(6) 0 rem(25);
                }

                @include respond(mobile) {
                    font-size: rem(30);
                }

                @include respond(mediumMobile) {
                    font-size: rem(15);
                    line-height: rem(24);
                    margin: rem(8) 0 rem(18);
                }
            }

            .btn_featured_explore {
                width: rem(275);
                height: rem(60);
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "Raleway";
                font-size: rem(14);
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.13em;
                color: #FFFFFF;
                border-radius: rem(1);
                background: #8E6A4A;
                border: 0;
                outline: none;
                box-shadow: none;

                @include respond(ipadMobile) {
                    width: rem(225);
                    height: rem(40);
                    font-size: rem(12);
                }

                @include respond(mediumMobile) {
                    width: rem(120);
                    height: rem(35);
                    font-size: rem(10);
                }
            }
        }
    }
}

.single_cask_taste_section {
    display: block;
    padding: rem(165) 0;

    @include respond(mobile) {
        padding: rem(50) 0;
    }

    .cask_taste_details {
        display: block;
        text-align: center;
        max-width: rem(861);
        margin: 0 auto;

        h2 {
            font-family: "EB Garamond";
            font-size: rem(14);
            font-weight: 700;
            line-height: rem(17);
            letter-spacing: 0.1em;
            color: #1C1B1B;
            margin: 0 0 rem(30);
            text-transform: uppercase;
        }

        h3 {
            font-family: "EB Garamond";
            font-size: rem(54);
            font-weight: 700;
            line-height: rem(37);
            letter-spacing: 0.01em;
            text-transform: capitalize;
            margin: 0 auto rem(30);
            color: #8E6A4A;
            margin: 0 0 rem(40);
            // max-width: rem(479);

            @include respond(mobile) {
                font-size: rem(24);
                line-height: rem(24);
                margin: 0 0 rem(20);
            }
        }

        p {
            font-family: 'Raleway';
            font-size: rem(20);
            font-weight: 400;
            line-height: tem(31);
            margin: 0 0 rem(40);
            color: #8F8C86;

            @include respond(mobile) {
                font-size: rem(15);
                line-height: rem(24);
                margin: 0 0 rem(20);
            }
        }

        .btn_Explore {
            background: transparent;
            border: 2px solid #8E6A4A;
            color: #8E6A4A;
            width: rem(226);
            height: rem(40);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin: rem(75) auto 0;
            font-family: "Raleway";
            font-size: rem(15);
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.09em;
        }
    }
}


.single_cask_taste_banner_section {
    display: block;
    position: relative;

    .title_of_banner {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        // background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);

        h3 {
            margin: 0;
            font-family: "EB Garamond";
            font-size: rem(56);
            font-weight: 700;
            line-height: rem(58);
            letter-spacing: 0.04em;
            text-align: center;
            background: linear-gradient(268.3deg, #F8E0A6 1.15%, #D3AA74 105.82%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            padding: rem(20) 0 rem(53);
            text-transform: uppercase;

            @include respond(ipadMobile) {
                font-size: rem(35);
                line-height: rem(38);
                padding: rem(15) 0 rem(35);
            }


            @include respond(mobile) {
                font-size: rem(22);
                line-height: normal;
                padding: rem(14) 0;
            }

            @include respond(smallmobile) {
                font-size: rem(16);
            }
        }
    }

    .bg_img {
        width: 100% !important;
        height: auto !important;
    }

    h2 {
        margin: 0;
        font-family: "Raleway";
        font-size: rem(32);
        font-style: italic;
        font-weight: 400;
        line-height: rem(39);
        letter-spacing: 0.02em;
        color: #FFFFFF;
        position: absolute;
        left: 12vw;
        top: 18vw;
        max-width: rem(550);

        @include respond(laptopdesktop) {
            left: 6vw;
        }

        @include respond(laptopdesktop) {
            left: 7vw;
            font-size: rem(22);
            max-width: rem(400);
            line-height: rem(32);
        }

        @include respond(ipadMobile) {
            left: 7vw;
            font-size: rem(17);
            line-height: rem(30);
            max-width: rem(300);
        }
    }
}

.award_slider_section {
    display: block;
    background: #010101;

    .slick-slider {
        display: block;
        overflow: hidden;

        .slick-list {
            display: flex;

            .slick-track {
                display: flex;

            }
        }
    }

    .awards_slider_div {
        .award__slider_inner_div {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            padding: 0 rem(2);

            @include respond(mobile) {
                display: block;
                padding: rem(24) 0;
            }

            .award_content {
                display: flex;
                align-items: flex-end;
                height: 100%;

                .description_text {
                    display: block;
                    margin-bottom: 5vw;
                    max-width: rem(446);

                    @include respond(laptopdesktop) {
                        margin-bottom: 8vw;
                    }

                    p {
                        font-family: "Raleway";
                        font-size: rem(19);
                        font-weight: 350;
                        line-height: rem(27);
                        text-align: left;
                        margin: 0 0 rem(40);
                        color: #FFFFFF;

                        @include respond(mobile) {
                            text-align: center;
                        }
                    }

                    .btn_explore_whisky_slider {
                        background: transparent;
                        border: rem(2) solid #FFFFFF;
                        border-radius: rem(4);
                        padding: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: "Raleway";
                        font-size: rem(15);
                        font-weight: 500;
                        letter-spacing: 0.31em;
                        color: #FFFFFF;
                        text-transform: uppercase;
                        width: rem(304);
                        height: rem(49);

                        @include respond(mobile) {
                            margin: 0 auto;
                        }
                    }
                }
            }

            .whisky_bottle_section {
                padding-top: rem(48);
                text-align: center;

                .whisky_inner_div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;

                    .whisky_main_img {
                        width: auto !important;
                        height: 50vw !important;

                        @include respond(mobile) {
                            height: rem(380) !important;
                        }
                    }

                    .whisky_name_box {
                        width: 100%;
                        position: absolute;
                        top: 21%;
                        left: 0;

                        h2 {
                            font-family: "EB Garamond";
                            font-size: 2.5vw;
                            font-weight: 700;
                            letter-spacing: 0.04em;
                            text-transform: uppercase;
                            color: #FFFFFF;
                            margin: 0;

                            @include respond(mobile) {
                                font-size: rem(20);
                            }
                        }

                        h3 {
                            font-family: "Raleway";
                            font-size: 0.7vw;
                            font-weight: 500;
                            letter-spacing: 0.21em;
                            margin: 0;
                            color: #FFFFFF;
                            text-transform: uppercase;

                            @include respond(mobile) {
                                font-size: rem(10);
                            }
                        }
                    }
                }
            }

            .award_section {
                display: flex;
                align-items: flex-end;
                justify-content: right;

                .award_inner_div {
                    display: block;
                    margin-bottom: 6vw;
                    width: 100%;
                    text-align: right;

                    @include respond(mobile) {
                        text-align: center;
                    }


                    @include respond(laptopdesktop) {
                        margin-bottom: 9vw;
                    }

                    img {
                        width: 76% !important;
                        height: auto !important;
                        max-width: rem(446);

                        @include respond(mobile) {
                            margin: rem(24) auto 0;
                            width: rem(190) !important;
                        }
                    }
                }
            }
        }
    }
}

.for_mobile {
    display: none;

    @include respond(mobile) {
        display: block;
    }
}

.for_desktop {
    display: block;


    @include respond(mobile) {
        display: none;
    }
}


.single_cask_awards_section {
    display: block;
    background-image: url("/single-cask/award-back-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: rgba(24, 24, 24, 0.9);

    @include respond(mobile) {
        background-image: url("/single-cask/award-back-bg-mobil.png");
    }

    .awards_details {
        display: block;
        height: calc(100% - rem(64));

        .awards_details_inner_div {
            padding-top: rem(98);

            @include respond(mobile) {
                padding-top: rem(40);
            }

            ul {
                padding: 0 rem(47) 0 0;
                margin: 0;
                display: flex;
                align-items: center;
                gap: rem(47);
                border-bottom: rem(0.3) solid #FFFFFF;
                overflow-x: auto;

                @include respond(mobile) {
                    gap: 0;
                }

                &::-webkit-scrollbar {
                    display: none;
                }

                li {
                    display: block;
                    width: rem(185);
                    min-width: rem(175);
                    height: rem(135);
                    cursor: pointer;
                    padding-bottom: rem(14);
                    position: relative;

                    @include respond(mobile) {
                        width: rem(130);
                        min-width: rem(130);
                        height: rem(90);
                    }

                    &.selected {
                        &::after {
                            position: absolute;
                            content: "";
                            width: 100%;
                            height: rem(2);
                            background: #D7C397;
                            bottom: 0;
                            left: 0;
                        }

                        h2 {
                            color: #D7C397;
                            font-weight: 600;

                            @include respond(mobile) {
                                display: none;
                            }

                        }
                    }

                    .award_img_div {
                        display: flex;
                        justify-content: center;
                        margin-bottom: rem(12);
                        height: rem(75);

                        @include respond(mobile) {
                            margin-bottom: 0;
                        }

                        img {
                            object-fit: contain;
                        }
                    }

                    h2 {
                        font-family: "Montserrat";
                        font-size: rem(12);
                        font-weight: 300;
                        line-height: rem(14);
                        letter-spacing: 0.01em;
                        color: #FFFFFF;
                        text-align: center;
                        margin: 0;
                        text-transform: uppercase;

                        @include respond(mobile) {
                            display: none;
                        }

                    }
                }
            }

            .awards_full_details_section {
                display: block;
                padding: rem(16) 0 rem(24);

                @include respond(mobile) {
                    padding: rem(16) 0 rem(64);
                }

                .title_of_award {
                    font-family: "Montserrat";
                    font-size: rem(16);
                    font-weight: 600;
                    line-height: rem(19);
                    letter-spacing: 0.01em;
                    margin: 0;
                    color: #FFFFFF;
                    padding: rem(8) 0;
                    display: none;

                    @include respond(mobile) {
                        display: block;
                    }
                }

                .award_scroll_div {
                    min-height: rem(362);
                    max-height: rem(362);
                    overflow-y: auto;

                    @include respond(mobile) {
                        min-height: rem(240);
                        max-height: rem(240);
                    }
                }

                table {
                    width: 100%;

                    tr {
                        @include respond(mobile) {
                            border-bottom: rem(1) solid #363636;
                        }

                        &:last-child {
                            @include respond(mobile) {
                                border-bottom: 0;
                            }
                        }

                        &.selected_row {
                            border-top: rem(1) solid #FFFFFF;
                            border-bottom: rem(1) solid #FFFFFF;
                            background: #000000CC;

                            @include respond(mobile) {
                                border-top: rem(1) solid #363636;
                                border-bottom: rem(1) solid #363636;
                            }
                        }

                        td {
                            padding: rem(15);

                            h2 {
                                font-family: "Raleway";
                                font-size: rem(12);
                                font-weight: 400;
                                line-height: rem(20);
                                letter-spacing: 0.02em;
                                margin: 0;
                                color: #D7C397;
                                cursor: pointer;
                            }

                            h3 {
                                font-family: "Raleway";
                                font-size: rem(12);
                                font-weight: 400;
                                line-height: rem(20);
                                letter-spacing: 0.02em;
                                color: #FFFFFF;
                                margin: 0;
                            }

                            h4 {
                                font-family: "Raleway";
                                font-size: rem(14);
                                font-weight: 700;
                                line-height: rem(20);
                                letter-spacing: 0.02em;
                                margin: 0;
                                background: linear-gradient(180deg, #775C3D 0%, #DDAB71 100%);
                                -webkit-background-clip: text;
                                background-clip: text;
                                -webkit-text-fill-color: transparent;
                            }

                            .img_of_bottle {
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                min-width: rem(70);

                                @include respond(mobile) {
                                    min-width: rem(29);

                                    img {
                                        width: rem(29) !important;
                                        height: rem(80) !important;
                                    }
                                }
                            }

                            .arrows_div {
                                display: block;
                                text-align: center;

                                img {
                                    cursor: pointer;
                                }
                            }

                            &.name_td {
                                @include respond(mobile) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .years_section {
        display: block;

        .years_div {
            display: block;
            width: 100%;
            position: relative;


            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                top: rem(25);
                left: 0;
                border-top: rem(2) solid;
                border-image-slice: 1;
                border-image-source: linear-gradient(90deg, rgba(182, 153, 94, 0) 0%, #B6995E 50%, rgba(182, 153, 94, 0) 100%);

            }

            .indecater_img {
                position: absolute;
                top: -38px;
                left: 50%;
                transform: translateX(-50%);
            }

            ul {
                overflow-y: auto;
                padding: rem(50) 0;
                margin: 0;
                display: flex;
                align-items: center;

                &::-webkit-scrollbar {
                    display: none;
                }

                li {
                    min-width: rem(194);
                    height: rem(48);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: "Montserrat";
                    font-size: rem(16);
                    font-weight: 300;
                    line-height: normal;
                    letter-spacing: 0.01em;
                    color: #FFFFFF;
                    cursor: pointer;
                    position: relative;

                    @include respond(mobile) {
                        min-width: rem(114);
                        font-size: rem(14);
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        background-image: url("/single-cask/vertical-line.png");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        width: rem(1);
                        height: rem(48);
                    }

                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }

                    &.active {
                        font-size: rem(32);
                        font-weight: 500;
                        color: #D7C397;

                        @include respond(mobile) {
                            font-size: rem(24);
                        }
                    }
                }
            }
        }
    }
}

.awards_section {
    display: block;
    height: 100vh;
    position: relative;

    @include respond(mobile) {
        height: rem(500);
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: linear-gradient(180deg, rgba(17, 16, 13, 0) 0%, rgba(17, 16, 13, 0.589851) 35.88%, rgba(17, 16, 13, 0.8) 81.63%),
            linear-gradient(180deg, rgba(10, 10, 10, 0) 0%, #0A0A0A 77.41%);

        @include respond(mobile) {
            height: rem(500);
            background: #00000099;
        }

    }

    .award_img {
        width: 100% !important;
        height: 100vh !important;

        @include respond(mobile) {
            height: rem(500) !important;
        }
    }

    .awards_inner_section {
        z-index: 1;
        position: absolute;
        bottom: 20vh;
        left: 0;
        width: 100%;
        height: auto;
        text-align: center;
        padding: rem(0) rem(30);

        @include respond(mobile) {
            bottom: rem(43);
            padding: 0 rem(16);
        }

        h2 {
            font-family: "EB Garamond";
            font-size: rem(32);
            font-weight: 700;
            line-height: rem(42);
            letter-spacing: 0.05em;
            text-transform: uppercase;
            margin: 0;
            color: #FFF;

            @include respond(mobile) {
                font-size: rem(22);
                line-height: rem(30);
            }
        }

        h3 {
            font-family: "Raleway";
            font-size: rem(20);
            font-weight: 300;
            line-height: rem(28);
            color: #FFF;
            margin: rem(15) 0 rem(32);

            @include respond(mobile) {
                font-size: rem(14);
                line-height: rem(28);
                margin: rem(8) 0 rem(25);
            }
        }

        .scroll_arrow {
            width: rem(100) !important;
            height: rem(100) !important;

            @include respond(mobile) {
                width: rem(60) !important;
                height: rem(60) !important;
            }
        }
    }
}