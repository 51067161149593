@media only screen and (max-width: 768px) {
  .rarest_society_Main {
    display: block;
  }
  .banner_main_section video {
    object-fit: cover;
    @include respond(desktop) {
      height: 100vh !important;
    }
    @include respond(ipad) {
      height: 50vh !important;
    }
    @include respond(mobile) {
      height: 40vh !important;
    }
  }

  .css-fjbkjs {
    bottom: rem(-203) !important;
    margin-right: 2rem !important;
    @include respond(ipadMobile) {
      margin-right: 0 !important;
    }
  }
}

.soundMainSection {
  opacity: 0;
}

.banner_main_section {
  position: relative;
}

.banner_main_section video {
  margin-bottom: rem(-7);
}

.soundMainSections {
  opacity: 1 !important;
}

/* sound on off  */

.css-om0y7o {
  position: absolute;
  bottom: rem(54.4);
  pointer-events: all;
  padding: rem(8);
  @include respond(desktop) {
    top: calc(50% - 43px);
    right: 0;
  }
  @include respond(ipad) {
    top: calc(50% - 43px);
    right: rem(20);
  }
  @include respond(mobile) {
    top: calc(50% - 43px);
    right: rem(20);
  }
}

.css-1ceik0d {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  min-width: 60px;
  @include respond(desktop) {
  }
  @include respond(ipad) {
  }
  @include respond(mobile) {
  }
}

.css-18m3c3 {
  display: flex;
  width: 15px;
  height: 16px;
  transition-duration: 0.5s;
}

.css-18m3c3 canvas {
  aspect-ratio: auto 42 / 16;
}

.css-w1atjl {
  width: 100%;
  height: 100%;
}

.css-fjbkjs {
  font-weight: 300;
  font-size: 1rem;
  line-height: 12px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  position: absolute;
  bottom: -40px;
  width: 99px;
  padding: 4px 0px 8px;
  margin-right: 4rem;
  z-index: 14;
  @include respond(desktop) {
  }
  @include respond(ipad) {
    top: 10%;
  }
  @include respond(mobile) {
    top: 10%;
  }
}

.wrapper {
  background-color: #fff;
  font-family: "Comfortaa", sans-serif;
  display: flex;
}

// #about {
//   color: white;
//   background: black;
// }

// .title {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 50%;
//   height: 100vh;
//   background: rgb(250, 249, 252);
//   position: sticky;
//   position: -webkit-sticky;
//   top: 0;
// }

// .scroll {
//   width: 50%;
//   color: white;
// }

// .scroll div {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
// }

// .scroll div > h2 {
//   width: 80%;
//   text-align: center;
// }

// .scroll div > p {
//   width: 80%;
// }

// .scroll div:nth-child(1) {
//   background: #d83f87;
// }

// .scroll div:nth-child(2) {
//   background: #4abdac;
// }

// .scroll div:nth-child(3) {
//   background: #44318d;
// }

// /* width */
// ::-webkit-scrollbar {
//   width: 7px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #f3aa93;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #f3aa93;
// }

.text-wrapper {
  position: absolute;
  text-align: center;
  min-width: 100%;
  @include respond(desktop) {
    bottom: 40px;
  }
  @include respond(ipad) {
    bottom: 40px;
    // top: 40%;
  }
  @include respond(mobile) {
    bottom: 40px;
    // top: 30%;
  }
  .headingText {
    color: hsla(0, 0%, 100%, 1);
    font-family: "Raleway";
    font-weight: 800;
    line-height: 1.6;
    text-transform: uppercase;
    text-align: center;
    @include respond(desktop) {
      font-size: rem(24);
      letter-spacing: rem(7);
    }
    @include respond(ipad) {
      font-size: rem(18);
      letter-spacing: rem(6);
    }
    @include respond(mobile) {
      font-size: rem(13);
      letter-spacing: rem(4);
    }
  }
  .description {
    color: hsla(0, 0%, 100%, 1);
    font-family: "Raleway";
    line-height: 1.4;
    margin-bottom: 0;
    text-align: center;
    margin-inline: auto;
    @include respond(desktop) {
      font-size: rem(16);
      font-weight: 400;
      max-width: rem(600);
    }
    @include respond(ipad) {
      font-size: rem(14);
      font-weight: 400;
      max-width: 60%;
    }
    @include respond(mobile) {
      font-size: rem(12);
      font-weight: 300;
      max-width: 90%;
      line-height: 1.3;
    }
  }
}
