.octave-journey-wrapper {
    background-color: rgba(215, 195, 151, 0.1);
    max-width: 100%;

    .journey_banner {
        position: relative;

        @include respond(ipadMobile) {
            padding-top: rem(90);
        }

        @include respond(mobile) {
            padding-top: rem(80);
        }
    }

    .journey_main_titles {
        font-family: "EB Garamond";
        font-size: rem(32);
        font-weight: 600;
        line-height: rem(41);
        text-align: center;
        color: rgba(119, 92, 61, 1);
        margin: 0px 0px rem(32);
        text-transform: uppercase;

        @include respond(mobile) {
            font-size: rem(24);
            font-weight: 700;
            line-height: rem(31);
            margin: 0px auto rem(10);
            max-width: rem(332);
        }
    }

    .journey_cards_section {
        width: 94%;
        margin: 0px auto;
        padding: rem(80) 0px;
        text-align: center;

        @include respond(mobile) {
            width: 100%;
            margin: rem(0);
            padding: rem(32) rem(16);
        }

        .journey_card {
            background: #FFF;
            padding: rem(24);
            border-radius: rem(8);
            height: 100%;
            box-shadow: 0 0 5px rgba(0, 0, 0, 14%);

            @include respond(mobile) {
                padding: rem(20);
                margin-bottom: rem(16);
                height: calc(100% - 16px);
            }

            .main_img {
                img {
                    width: 100% !important;
                    height: auto !important;
                }
            }

            .journey_details {
                display: block;

                @include respond(mobile) {
                    padding: rem(0) rem(16);
                }

                h3 {
                    font-family: "EB Garamond";
                    font-size: rem(24);
                    font-weight: 600;
                    line-height: rem(31);
                    text-transform: uppercase;
                    margin: rem(0);
                    color: #775C3D;
                    padding: rem(16) rem(0);

                    @include respond(mobile) {
                        font-size: rem(20);
                        line-height: rem(26);
                        padding: rem(14) rem(0);
                    }
                }

                p {
                    display: block;
                    font-family: "Raleway";
                    font-size: rem(16);
                    font-weight: 400;
                    line-height: rem(20);
                    text-align: justify;
                    color: #000000;
                    margin: 0px;

                    @include respond(mobile) {
                        font-size: rem(14);
                        line-height: rem(18);
                    }
                }
            }
        }

    }

    .octave_pack_section {
        display: block;

        .journey_bg_img {
            width: 100% !important;
            height: 100% !important;
        }
    }

    .explore_other_section {
        width: 94%;
        margin: 0px auto;
        display: block;
        padding: rem(80) rem(0);

        @include respond(mobile) {
            width: 100%;
            padding: rem(50) rem(16);
        }

        .journey_sub_titles {
            font-family: "Raleway";
            font-size: rem(16);
            font-weight: 400;
            line-height: rem(23);
            margin: 0px auto rem(32);
            color: #5C5C5C;
            text-align: center;
            max-width: rem(1100);

            @include respond(mobile) {
                font-size: rem(14);
                margin: 0px auto rem(24);
            }
        }

        .explore_card {
            display: block;
            height: 100%;

            @include respond(mobile) {
                margin-bottom: rem(16);
            }

            .explore_img {
                display: block;

                img {
                    width: 100% !important;
                    height: auto !important;
                }
            }

            .explore_details {
                display: block;
                text-align: center;

                h2 {
                    font-family: "EB Garamond";
                    font-size: rem(16);
                    font-weight: 600;
                    line-height: rem(14);
                    color: #775C3D;
                    margin: rem(28) rem(0) rem(7);
                    text-transform: uppercase;

                    @include respond(mobile) {
                        font-size: rem(11);
                        line-height: rem(10);
                        margin: rem(13) rem(0) rem(5);
                    }
                }

                p {
                    font-family: "Raleway";
                    font-size: rem(12);
                    font-weight: 400;
                    line-height: rem(15);
                    color: #5C5C5C;

                    @include respond(mobile) {
                        font-size: rem(8);
                        line-height: rem(11);
                    }

                }

                .btn_group {
                    display: flex;
                    gap: rem(7);

                    .btn_buyNow {
                        width: 100%;
                        height: rem(35);
                        background: #775C3D;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #FFF;
                        font-family: "Raleway";
                        font-size: rem(12);
                        font-weight: 500;
                        line-height: rem(14);
                        letter-spacing: -0.02em;
                        border: 1px solid #775C3D;
                        text-transform: uppercase;
                        border-radius: rem(2);

                        @include respond(mobile) {
                            font-size: rem(8);
                            line-height: rem(10);
                            border-radius: rem(1.44);
                            height: rem(25);
                        }
                    }

                    .btn_learn_more {
                        width: 100%;
                        height: rem(35);
                        background: transparent;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #775C3D;
                        font-family: "Raleway";
                        font-size: rem(12);
                        font-weight: 500;
                        line-height: rem(14);
                        letter-spacing: -0.02em;
                        border: 1px solid #775C3D;
                        text-transform: uppercase;
                        border-radius: rem(2);

                        @include respond(mobile) {
                            font-size: rem(8);
                            line-height: rem(10);
                            border-radius: rem(1.44);
                            height: rem(25);
                        }
                    }
                }
            }
        }
    }

}