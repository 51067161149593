.error-section {
  background: hsla(270, 13%, 6%, 1);
  min-width: 100%;
  min-height: 100vh;
  padding-top: rem(80);
  @include flex-center();
  text-align: center;
  .error-page-card {
    .error-text {
      color: hsla(44, 56%, 59%, 1);
      font-size: rem(16);
      font-weight: 500;
      font-family: "Raleway";
      padding: rem(50) 0;
    }
    .btn {
      font-size: rem(16);
      font-family: "Raleway";
      font-weight: 500;
      background: rgba(106, 42, 17, 0.35);
      -webkit-box-shadow: rem(4) rem(4) rem(5) rgba(0, 0, 0, 0.31);
      box-shadow: rem(4) rem(4) rem(5) rgba(0, 0, 0, 0.31);
      border-radius: 2.3125rem;
      padding: 1rem 2.2rem;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      border: 1px solid #f9c467;
    }
  }
}
