.aboutus-firstfold-container {
  background-color: hsla(0, 0%, 5%, 1);

  @include respond(desktop) {
    padding: rem(200) 0 rem(150);
  }

  @include respond(ipadMobile) {
    padding: rem(200) 0 rem(60);
  }

  @include respond(mobile) {
    padding: rem(140) 0 rem(60);
  }

  .inner-container {
    letter-spacing: rem(0.5);

    .headingText {
      color: hsla(37, 58%, 72%, 1);
      font-size: rem(51);
      font-family: "DM Serif Text";
      font-weight: 400;

      @include respond(ipadMobile) {
        font-size: rem(24);
      }
    }

    .logo {
      padding: rem(30) 0;

      @include respond(ipadMobile) {
        padding: rem(15) 0;
      }
    }

    .desc1 {
      color: hsla(0, 3%, 89%, 1);
      font-size: rem(16);
      font-family: "Raleway";
      font-weight: 600;
      margin-bottom: 0;
      padding-bottom: rem(20);

      @include respond(ipadMobile) {
        padding-bottom: rem(15);
        padding-inline: rem(20);
        color: #e3e1e1;
        line-height: 1.5;
      }
    }

    .desc2 {
      color: hsla(0, 0%, 80%, 1);
      font-size: rem(16);
      font-family: "Raleway";
      font-weight: 400;
      opacity: 0.8;
      max-width: rem(850);
      margin: auto;
      margin-bottom: 0;

      @include respond(ipadMobile) {
        padding-inline: rem(5);
        font-size: rem(14);
        line-height: 1.7;
      }
    }
  }
}

.aboutus-ourstory-container {
  position: relative;

  .headingText {
    color: hsla(32, 12%, 25%, 1);
    font-size: rem(34);
    font-family: "EB Garamond";
    font-weight: 400;
    margin-bottom: 0;
    padding: rem(40) 0;

    @include respond(ipadMobile) {
      padding: rem(30) 0;
      font-size: rem(20);
      color: #484139;
      font-weight: 600;
    }
  }

  .year-containerMobile {
    @include respond(ipadMobile) {
      padding-inline: rem(20);

      ul {
        margin-bottom: 0;
        padding-left: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: rem(0) rem(30);
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-bottom: rem(0);

        a {
          text-decoration: none;

          li {
            color: hsla(0, 0%, 61%, 1);
            font-family: "Sobon";
            font-weight: 400;
            font-size: rem(18);
            cursor: pointer;
            text-decoration: none;

            &:hover {
              color: hsla(40, 41%, 44%, 1);
              font-weight: 700;
              font-size: rem(24);
            }
          }
        }
      }
    }
  }

  .card-container {
    padding-bottom: rem(50);

    @include respond(desktop) {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
    }

    @include respond(ipadMobile) {
      margin-top: rem(30);
      max-width: 90%;
      margin-inline: auto;
      padding-bottom: rem(0);

      border-left: rem(2) solid #e2d9c7;
    }

    .left-innercontainer {
      position: relative;
      display: flex;
      align-items: center;

      .image {
        max-width: rem(499);
        max-height: rem(384);

        @include respond(ipadProMobile) {
          max-width: rem(400);
          max-height: rem(384);
        }

        @include respond(ipadMobile) {
          width: 80%;
          height: auto;
        }
      }

      @include respond(desktop) {
        justify-content: flex-end;
        padding-right: rem(30);
      }

      @include respond(ipadMobile) {
        justify-content: flex-start;
        padding-left: rem(8);
      }

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 48%;
        transform: translateY(-50%) translateX(15px);
        height: 115%;
        width: rem(2);
        background-color: hsla(40, 32%, 83%, 1);

        @include respond(mobile) {
          display: none;
        }

        @include respond(ipad) {
          display: none;
        }
      }
    }

    .right-innercontainer {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      max-width: rem(470);
      padding-left: rem(30);
      padding-top: rem(20);

      @include respond(ipadMobile) {
        padding-left: rem(0);
        padding-top: rem(20);
        padding-bottom: rem(20);
        padding-left: rem(8);
      }

      .year {
        color: hsla(40, 41%, 44%, 1);
        font-size: rem(62);
        font-family: "Sabon";
        font-weight: 400;
        line-height: 1;
        margin-bottom: 0;

        @include respond(ipadMobile) {
          font-size: rem(32);
        }
      }

      .headingsub-text {
        color: hsla(0, 0%, 37%, 1);
        font-size: rem(16);
        font-family: "Raleway";
        font-weight: 400;
        margin-bottom: 0;
        padding: rem(20) 0;

        @include respond(ipadMobile) {
          font-weight: 600;
          color: #5f5f5f;
          padding: rem(15) 0;
        }
      }

      .subtext {
        color: hsla(0, 0%, 37%, 1);
        font-size: rem(16);
        font-family: "Raleway";
        font-weight: 400;
        margin-bottom: 0;

        @include respond(ipadMobile) {
          font-size: rem(14);
          color: #5f5f5f;
          line-height: 1.7;
        }
      }
    }

    .bottomright-innercontainer {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: rem(30);

      .image {
        max-width: rem(499);
        max-height: rem(384);

        @include respond(ipadProMobile) {
          max-width: rem(400);
          max-height: rem(384);
        }

        @include respond(ipadMobile) {
          width: 80%;
          height: rem(200);
        }
      }
    }

    .bottomleft-innercontainer {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      padding-top: rem(20);
      padding-right: rem(30);
      padding-left: rem(100);
      position: relative;

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 45%;
        transform: translateY(-50%) translateX(15px);
        height: 120%;
        width: rem(2);
        background-color: hsla(40, 32%, 83%, 1);

        @include respond(mobile) {
          display: none;
        }

        @include respond(ipad) {
          display: none;
        }
      }

      .year {
        color: hsla(40, 41%, 44%, 1);
        font-size: rem(62);
        font-family: "Sabon";
        font-weight: 400;
        line-height: 1;
        margin-bottom: 0;
        text-align: right;
        width: 100%;
      }

      .headingsub-text {
        color: hsla(0, 0%, 37%, 1);
        font-size: rem(16);
        font-family: "Raleway";
        font-weight: 400;
        margin-bottom: 0;
        padding: rem(20) 0;
        text-align: right;
        width: 100%;
      }

      .subtext {
        color: hsla(0, 0%, 37%, 1);
        font-size: rem(16);
        font-family: "Raleway";
        font-weight: 400;
        text-align: right;
        width: 100%;

        margin-bottom: 0;

        span {
          font-weight: 600;
          color: hsla(0, 0%, 37%, 1);
        }
      }
    }

    .year-container {
      position: -webkit-sticky;
      position: sticky;
      top: rem(170);
      left: rem(50);

      ul {
        a {
          text-decoration: none;

          li {
            color: hsla(0, 0%, 61%, 1);
            font-family: "Robon";
            font-weight: 400;
            font-size: rem(18);
            cursor: pointer;
            padding-top: rem(30);
            text-decoration: none;

            &:hover {
              color: hsla(40, 41%, 44%, 1);
              font-weight: 700;
              font-size: rem(24);
            }
          }
        }
      }
    }
  }

  .cheers-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: rem(80);

    @include respond(ipadMobile) {
      padding: rem(30) 0 rem(60);
    }

    .headingText {
      color: hsla(32, 12%, 25%, 1);
      font-size: rem(63);
      font-style: italic;
      font-family: "EB Garamond";
      font-weight: 500;
      margin-bottom: 0;
      padding: rem(0) 0;

      @include respond(ipadMobile) {
        font-size: rem(32);
      }
    }

    .shopnowbtn {
      background-color: hsla(32, 20%, 43%, 1);
      font-size: rem(13);
      color: hsla(0, 0%, 100%, 1);
      font-family: "Roboto";
      font-weight: 500;
      padding: rem(20) rem(60) rem(18);
      border-radius: 0;
      margin-top: rem(80);

      @include respond(ipadMobile) {
        margin-top: rem(30);
        padding: rem(16) rem(50);
        letter-spacing: rem(0.6);
      }
    }
  }
}

.aboutus-ourteam-container {
  position: relative;
  z-index: 5;
  background-color: white;

  @include respond(ipadMobile) {
    .row {
      flex-wrap: nowrap !important;
      overflow-x: auto;
      padding-bottom: rem(20);
      margin-inline: rem(0);
    }
  }

  .main-heading {
    color: hsla(32, 12%, 25%, 1);
    font-size: rem(63);
    font-family: "EB Garamond";
    font-weight: 400;
    margin-bottom: 0;
    line-height: 1;
    border-top: rem(1) solid hsla(40, 32%, 83%, 1);
    border-bottom: rem(1) solid hsla(40, 32%, 83%, 1);
    padding: rem(30) rem(0);
    max-width: 96%;
    margin-inline: auto;

    @include respond(ipadMobile) {
      font-size: rem(32);
      padding: rem(25) 0;
      max-width: 94%;
    }
  }

  .card-container {
    width: 85%;
    margin: auto;
    padding: rem(80) 0;

    @include respond(ipadMobile) {
      width: 100%;
      padding-top: rem(30);
      padding-bottom: 0;
    }

    .image-wrapper {
      height: 100%;

      .image {
        @include bgCover();
        width: 100%;
        padding: rem(186) rem(0);

        @include respond(ipadMobile) {
          padding: 8rem 0;
        }
      }
    }

    .card-name {
      color: hsla(40, 41%, 44%, 1);
      font-size: rem(34);
      font-family: "Sabon";
      font-weight: 400;
      margin-bottom: 0;
      padding-top: rem(15);

      @include respond(ipadMobile) {
        font-size: rem(25);
      }
    }

    .designation-text {
      color: hsla(32, 12%, 25%, 1);
      font-size: rem(16);
      font-family: "Raleway";
      font-weight: 600;
      margin-bottom: 0;
      padding: rem(15) 0;

      @include respond(ipadMobile) {
        font-size: rem(12);
        color: #484139;
        padding-right: rem(20);
        line-height: 1.17;
      }
    }

    .description {
      color: hsla(32, 0%, 47%, 1);
      font-size: rem(16);
      font-family: "Raleway";
      font-weight: 400;
      margin-bottom: 0;

      @include respond(ipadMobile) {
        font-size: rem(12);
        color: #787877;
        line-height: 1.6;
        padding-right: rem(10);
      }
    }
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .shopnowbtn {
      background-color: hsla(32, 20%, 43%, 1);
      font-size: rem(13);
      color: hsla(0, 0%, 100%, 1);
      font-family: "Roboto";
      font-weight: 500;
      padding: rem(20) rem(60) rem(18);
      border-radius: 0;
      margin-bottom: rem(80);
      margin-inline: auto;

      @include respond(ipadMobile) {
        padding: rem(16) rem(50);
        margin-top: rem(40);
        margin-bottom: rem(40);
      }
    }
  }
}

.aboutus-exploretheduncan-container {
  background-image: linear-gradient(to right,
      hsla(37, 58%, 71%, 1),
      hsla(42, 68%, 80%, 1),
      hsla(42, 69%, 81%, 1),
      hsla(42, 69%, 85%, 1),
      hsla(44, 65%, 86%, 1));
  // padding: rem(40) 0 rem(145);
  padding: rem(40) 0;
  position: relative;

  @include respond(ipadMobile) {
    // padding: rem(20) 0 rem(260);
    padding: rem(60) 0 rem(20);
  }

  .inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    max-width: rem(1065);
    // min-height: rem(275);
    margin: auto;

    @include respond(ipadMobile) {
      // min-height: rem(130);
      background-position: center center;
    }

    .headingText {
      color: #484139;
      font-size: rem(45);
      font-family: "EB Garamond";
      font-weight: 400;
      line-height: 1;
      margin-bottom: 0;
      text-transform: uppercase;

      @include respond(ipadMobile) {
        font-size: rem(24);
        max-width: 80%;
        line-height: 1.3;
        letter-spacing: rem(1);
      }
    }

    .description {
      color: hsla(0, 0%, 37%, 1);
      font-size: rem(16);
      font-family: "Raleway";
      font-weight: 400;
      max-width: 40%;
      margin-inline: auto;
      margin-bottom: 0;
      text-align: center;
      padding: rem(10) 0;
      line-height: 1.5;

      @include respond(ipadMobile) {
        font-size: rem(16);
        max-width: 90%;
        line-height: 1.3;
        letter-spacing: rem(1);
      }
    }

    .explorebtn {
      background-color: transparent;
      font-size: rem(13);
      color: hsla(240, 1%, 30%, 1);
      font-family: "Roboto";
      font-weight: 500;
      padding: rem(18) rem(80);
      border-radius: rem(1);
      margin-top: rem(200);
      letter-spacing: rem(0.5);
      border: rem(2) solid hsla(240, 1%, 30%, 1);
    }
  }

  .tabinner-container {
    position: absolute;
    min-width: 100%;

    @include respond(desktop) {
      bottom: 0;
    }

    @include respond(ipadMobile) {
      bottom: 0;
    }

    .tabcontainer {
      padding: rem(0) rem(100);
      // background-image: linear-gradient(
      //   to right,
      //   hsla(60, 2%, 9%, 1),
      //   hsla(60, 2%, 34%, 1),
      //   hsla(60, 2%, 9%, 1)
      // );
      background-color: rgba(24, 24, 24, 1);
      border-bottom: rem(1) solid rgba(119, 92, 61, 1);

      @include respond(ipadMobile) {
        padding: rem(25) rem(25);
      }

      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        position: relative;

        @include respond(ipadMobile) {
          flex-wrap: wrap;
          gap: rem(10) rem(10);



        }

        li {
          cursor: pointer;
          height: rem(112);
          display: flex;
          justify-content: center;
          align-items: center;

          @include respond(ipadMobile) {
            height: rem(60);

            // min-width: rem(120);
            img {
              max-height: rem(35);
            }
          }
        }
      }
    }
  }
}