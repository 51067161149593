.aboutthe-rarest-container {
  padding: rem(100) 0;

  @include respond(mobile) {
    padding: rem(40) 0;
  }

  .left-outerwrapper {
    .left-innerwrapper {
      width: 65%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      text-align: center;
      margin: auto;

      @include respond(mobile) {
        width: 100%;
      }

      .headingText {
        color: hsla(32, 12%, 25%, 1);
        font-size: rem(63);
        font-weight: 400;
        font-family: "EB Garamond";
        line-height: 1;
        text-transform: uppercase;

        @include respond(mobile) {
          color: hsla(0, 0%, 0%, 1);
          font-size: rem(26);
          max-width: 60%;
          margin: auto;
          line-height: 1.1;
        }
      }

      .description {
        color: hsla(0, 0%, 37%, 1);
        font-size: rem(16);
        font-weight: 400;
        font-family: "Raleway";
        margin: 0;
        padding: rem(20) 0;

        @include respond(mobile) {
          font-size: rem(14);
          text-align: center;
          padding-inline: rem(40);
        }
      }

      .number-container {
        line-height: 1;
        position: relative;

        span {
          color: hsla(37, 58%, 72%, 1);
          font-size: rem(101);
          font-weight: 400;
          font-family: "DM Serif Text";
          letter-spacing: rem(0.2);

          @include respond(mobile) {
            font-size: rem(44);
          }

          // &:first-child {
          //   &::after {
          //     content: "";
          //     position: absolute;
          //     left: 38%;
          //     top: 10%;
          //     transform: translateY(-50%) translateX(15px);
          //     transform: rotate(20deg);
          //     height: rem(86);
          //     width: rem(3);
          //     background-color: hsla(37, 58%, 72%, 1);
          //     @include respond(mobile) {
          //       height: rem(40);
          //     }
          //     @include respond(ipad) {
          //       display: none;
          //     }
          //   }
          // }

          &:not(:first-child) {
            padding-left: rem(70);

            @include respond(mobile) {
              padding-left: rem(40);
            }
          }
        }
      }

      .learnmorebtn {
        color: hsla(5, 42%, 6%, 1);
        font-size: rem(13);
        font-weight: 500;
        font-family: "Roboto";
        margin-top: rem(25);
        padding: rem(18) rem(120);
        border: rem(2) solid hsla(240, 1%, 30%, 1);
        border-radius: rem(1);
      }
    }
  }

  .right-innerwrapper {
    @include respond(mobile) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .learnmorebtn {
        color: hsla(32, 20%, 43%, 1);
        font-size: rem(10);
        font-weight: 600;
        font-family: "Raleway";
        text-align: center;
        margin-top: rem(30);
        padding: rem(13) rem(30);
        border: rem(1) solid hsla(32, 20%, 43%, 1);
        border-radius: rem(1);
      }
    }

    .image {
      // margin-top: rem(25);
      max-width: rem(600);
      max-height: rem(505);

      @include respond(ipadProMobile) {
        max-width: 90%;
        max-height: auto;
      }

      @include respond(mobile) {
        max-width: rem(315);
        max-height: rem(265);
        margin-top: rem(20);
      }
    }
  }
}

.port-ellen-container {
  width: 96%;
  margin: auto;
  padding: rem(170) 0;

  @include respond(ipadMobile) {
    text-align: center;
    width: 100%;
    padding: rem(60) 0 rem(400);
  }

  @include bgCover();

  .left-innerwrapper {
    max-width: 45%;
    padding-left: rem(130);

    @include respond(ipadMobile) {
      max-width: 96%;
      padding-left: rem(0);
      margin: auto;
    }

    .top-headingtext {
      color: hsla(0, 0%, 37%, 1);
      font-size: rem(28);
      font-weight: 400;
      font-style: italic;
      font-family: "Baskerville";

      @include respond(ipadMobile) {
        color: hsla(32, 59%, 24%, 1);
        font-size: rem(20);
      }
    }

    .headingText {
      color: hsla(0, 0%, 100%, 1);
      font-size: rem(63);
      font-weight: 400;
      font-family: "Baskerville";
      text-transform: uppercase;
      line-height: 1;
      margin-bottom: 0;
      max-width: 40%;

      @include respond(ipadMobile) {
        max-width: 100%;
        font-size: rem(34);
        font-weight: 500;
        padding-top: rem(25);
      }
    }

    .years-text {
      color: hsla(90, 2%, 16%, 1);
      font-size: rem(24);
      font-weight: 400;
      text-transform: uppercase;
      font-family: "Raleway";
      padding: rem(30) 0 0;
      margin: 0;

      @include respond(ipadMobile) {
        display: none;
      }
    }

    .description-text {
      color: hsla(0, 0%, 37%, 1);
      font-size: rem(16);
      font-weight: 400;
      font-family: "Raleway";
      margin-bottom: 0;
      padding: rem(20) rem(50) rem(70) 0;
      letter-spacing: rem(0.5);

      @include respond(ipadMobile) {
        padding: rem(20) rem(22);
        font-size: rem(14);
        color: hsla(0, 0%, 0%, 1);
      }
    }

    .btn {
      padding: rem(18) rem(48);
      background-color: hsla(0, 0%, 100%, 1);
      border-radius: rem(1);
      color: hsla(5, 42%, 6%, 1);
      font-size: rem(13);
      font-weight: 500;
      font-family: "Roboto";

      @include respond(mobile) {
        color: hsla(32, 32%, 35%, 1);
        font-weight: 600;
        font-size: rem(10);
        padding: rem(13) rem(30);
        font-family: "Raleway";
        border-radius: rem(1);
        margin-top: rem(10);
      }
    }
  }
}

.galary-container {
  padding: rem(30) 0 rem(50);

  @include respond(mobile) {
    padding: rem(30) 0;
  }

  .headingText {
    color: hsla(0, 0%, 11%, 1);
    font-size: rem(34);
    font-weight: 600;
    font-family: "EB Garamond";
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 0;
    text-align: center;
    padding: rem(40) 0 rem(40);

    @include respond(mobile) {
      font-size: rem(20);
      font-weight: 700;
      padding: rem(30) 0 rem(20);
    }
  }

  .slider-container {
    display: flex;
    overflow-x: auto;
    flex-basis: 100%;
    flex-grow: 90%;
    gap: 0 rem(40);
    padding: rem(10) 0;

    @include respond(mobile) {
      gap: 0 rem(10);
      padding: rem(20) 0 rem(30);
      scrollbar-color: green;
    }

    .slider-card {
      padding: rem(280) rem(400);
      @include bgCover();
      display: flex;
      flex-basis: 100%;
      flex-grow: 100%;

      @include respond(mobile) {
        padding: rem(101) rem(148);
        flex-basis: 100%;
        flex-grow: 100%;
      }
    }
  }

  // ::-webkit-scrollbar-thumb {
  //   background: red;
  //   border-radius: 10px;
  // }
  .slider-container::-webkit-scrollbar {
    @include respond(desktop) {
      display: none;
    }

    //     -ms-overflow-style: none;
    //   scrollbar-width: rem(10);
  }
}

.kinclaith-outer-wrapper {
  @include respond(desktop) {
    padding-bottom: rem(50);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.rarest-collection-signupforfree {
  @include respond(desktop) {
    margin-top: rem(80);
  }

  @include respond(mobile) {
    margin-top: rem(60);
  }
}

.kinclaithtop-heading {
  color: hsla(0, 0%, 11%, 1);
  font-size: rem(34);
  font-weight: 600;
  font-family: "EB Garamond";
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 0;
  text-align: center;
  padding: rem(40) 0 rem(20);

  @include respond(mobile) {
    font-size: rem(21);
    font-weight: 700;
    line-height: 1.1;
    padding: rem(15) 0 rem(5);
  }
}

.kinclaith-container {
  border-radius: rem(4);
  max-width: 96%;
  margin: auto;
  padding: rem(125) 0;

  @include respond(desktop) {
    margin: rem(25) auto;
  }

  @include respond(ipadMobile) {
    margin: rem(25) auto;
  }


  @include respond(mobile) {
    padding: rem(50) 0;
    margin: rem(25) auto;
  }

  .verical-seperator {
    // content: "";
    position: absolute;
    right: 0;
    top: 55%;
    transform: translateY(-50%) translateX(15px);
    height: rem(310);
    width: rem(3);
    background-color: hsla(0, 0%, 100%, 1);

    @include respond(mobile) {
      display: none;
    }

    @include respond(ipad) {
      display: none;
    }
  }

  background-color: hsla(40, 21%, 95%, 1);

  .left-innerwrapper {
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include respond(mobile) {
      padding-top: rem(0);
    }

    .top-headingtext {
      color: hsla(40, 41%, 44%, 1);
      font-size: rem(28);
      font-weight: 400;
      font-style: italic;
      font-family: "Baskerville";

      @include respond(mobile) {
        font-size: rem(20);
      }
    }

    .headingText {
      color: hsla(32, 12%, 25%, 1);
      font-size: rem(63);
      font-weight: 400;
      font-family: "EB Garamond";
      text-transform: uppercase;
      line-height: 1;
      margin-bottom: 0;
      padding: rem(10) 0 rem(20);

      @include respond(desktop) {
        max-width: rem(470);
        margin-inline: auto;
      }

      @include respond(ipadMobile) {
        font-size: rem(40);
      }

      @include respond(mobile) {
        font-size: rem(26);
        font-weight: 500;
        padding: rem(15) 0;
      }
    }

    .description-text {
      color: hsla(0, 0%, 37%, 1);
      font-size: rem(18);
      font-weight: 400;
      font-family: "Raleway";
      margin-bottom: 0;
      padding: rem(0) rem(80) rem(40);
      letter-spacing: rem(0.5);

      @include respond(ipadMobile) {
        padding: rem(10) rem(20) rem(30);
      }

      @include respond(mobile) {
        padding: rem(10) rem(20) rem(30);
        color: hsla(32, 12%, 25%, 1);
        font-size: rem(14);
        font-weight: 400;
      }
    }

    .btn {
      padding: rem(18) rem(90);
      background-color: hsla(0, 0%, 100%, 1);
      border-radius: rem(1);
      border: rem(1) solid hsla(32, 20%, 43%, 1);
      color: hsla(32, 35%, 40%, 1);
      font-size: rem(13);
      font-weight: 500;
      font-family: "Roboto";

      @include respond(mobile) {}
    }
  }

  .right-innerwrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    @include respond(desktop) {
      padding-top: rem(60);
    }

    .bottleimg {
      max-width: rem(370);
      max-height: rem(455);
      display: flex;
      justify-self: center;
      align-items: center;

      @include respond(mobile) {
        max-width: rem(200);
        max-height: rem(245);
      }
    }

    .bottomimg {
      max-width: rem(378);
      max-height: rem(61);
      position: absolute;
      bottom: rem(-40);

      @include respond(mobile) {
        display: none;
      }
    }

    .btn {
      @include respond(desktop) {
        display: none;
      }

      @include respond(ipadMobile) {
        color: hsla(32, 35%, 40%, 1);
        font-weight: 600;
        font-size: rem(10);
        padding: rem(13) rem(30);
        font-family: "Raleway";
        border-radius: rem(1);
        margin-top: rem(40);
        background-color: hsla(0, 0%, 100%, 1);
        border: rem(1) solid hsla(32, 20%, 43%, 1);
      }
    }
  }
}

.marginbottom {
  @include respond(mobile) {
    margin-bottom: rem(50);
  }
}

.capercontainer {
  border-radius: rem(4);
  max-width: 96%;
  margin: rem(30) auto rem(60);
  padding: rem(125) 0;
  background-color: hsla(40, 21%, 95%, 1);

  .right-innerwrapper {
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: rem(45);

    .top-headingtext {
      color: hsla(40, 41%, 44%, 1);
      font-size: rem(28);
      font-weight: 400;
      font-style: italic;
      font-family: "Baskerville";
    }

    .headingText {
      color: hsla(32, 12%, 25%, 1);
      font-size: rem(63);
      font-weight: 400;
      font-family: "EB Garamond";
      text-transform: uppercase;
      line-height: 1;
      margin-bottom: 0;
      padding: rem(10) 0 rem(20);
    }

    .description-text {
      color: hsla(0, 0%, 37%, 1);
      font-size: rem(18);
      font-weight: 400;
      font-family: "Raleway";
      margin-bottom: 0;
      padding: rem(0) rem(80) rem(40);
      letter-spacing: rem(0.5);
    }

    .btn {
      padding: rem(18) rem(90);
      background-color: hsla(0, 0%, 100%, 1);
      border-radius: rem(1);
      border: rem(1) solid hsla(32, 20%, 43%, 1);
      color: hsla(32, 35%, 40%, 1);
      font-size: rem(13);
      font-weight: 500;
      font-family: "Roboto";
    }
  }

  .left-innerwrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 55%;
      transform: translateY(-50%) translateX(15px);
      height: rem(310);
      width: rem(3);
      background-color: hsla(0, 0%, 100%, 1);
    }

    .bottleimg {
      max-width: rem(370);
      max-height: rem(455);
      display: flex;
      justify-self: center;
      align-items: center;
    }

    .bottomimg {
      max-width: rem(378);
      max-height: rem(61);
      position: absolute;
      bottom: rem(-50);
    }
  }
}

.gallery_slider_man {

  // @include respond(mobile){
  //   max-width: 94%;
  //   margin-inline: auto;
  // }
  .gallary-image-wrapper {
    @include respond(mobile) {
      max-width: 90% !important;
    }
  }

  .slick-initialized {
    .slick-list {
      .slick-track {
        display: flex;
      }

      @include respond(mobile) {
        max-height: rem(400);
      }

      img {
        @include respond(desktop) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
          text-align: center;
          margin-inline: rem(18);
          height: rem(600);
        }

        @include respond(ipadMobile) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
          text-align: center;
          height: rem(380);
          margin-inline: rem(10);
        }

      }
    }

    .slick-arrow {
      &.slick-next {
        right: 50px;
        z-index: 1;

        @include respond(mobile) {
          right: 20px;
        }

        &::before {
          background-repeat: no-repeat;
          background-image: url("/product/right-slider-arrow.png");
          background-size: 7px 10px;
          display: inline-block;
          width: 40px;
          height: 40px;
          content: "";
          border: 2px solid #4e361a;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.7);
          background-position: center;

          @include respond(mobile) {
            width: 25px;
            height: 25px;
          }
        }
      }

      &.slick-prev {
        left: 30px;
        z-index: 1;

        @include respond(mobile) {
          left: 15px;
        }

        &::before {
          background-repeat: no-repeat;
          background-image: url("/product/left-slider-arrow.png");
          background-size: 7px 10px;
          display: inline-block;
          width: 40px;
          height: 40px;
          content: "";
          border: 2px solid #4e361a;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.7);
          background-position: center;

          @include respond(mobile) {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}