.cart-container {
  min-width: 100%;
  min-height: 100vh;
  background-color: hsla(0, 0%, 0%, 1);
  position: relative;
  display: none;

  &.open {
    display: flex;
  }

  .cart-innercontainer {
    position: absolute;
    background-color: white;
    min-width: 30%;
    min-height: 100vh;
    right: 0;
    z-index: 10;
    top: rem(0);

    display: flex;
    flex-direction: column;
    padding: rem(30);
    justify-content: space-between;
    .top-wrapper {
      .headingText {
        color: hsla(0, 0%, 11%, 1);
        font-family: "EB Garamond";
        font-weight: 600;
        font-size: rem(34);
        text-transform: uppercase;
        padding-bottom: rem(20);
        border-bottom: rem(2) solid hsla(36, 24%, 41%, 1);
      }
      .carditem {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: rem(20);
        border-bottom: rem(1) solid hsla(36, 36%, 77%, 1);
        .left-inner {
          background-color: hsla(40, 21%, 95%, 1);
          border: none;
          outline: none;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: rem(10) rem(20) 0;
          img {
            max-width: rem(80);
            max-height: rem(100);
          }
        }
        .right-inner {
          min-width: 70%;
          padding-left: rem(25);
          .cardheading {
            color: hsla(90, 2%, 16%, 1);
            font-family: "Raleway";
            font-weight: 400;
            font-size: rem(18);
            text-transform: capitalize;
            max-width: rem(200);
            padding-bottom: rem(20);
          }
          .bottom-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .bottom-left {
              border: rem(1) solid hsla(36, 24%, 41%, 1);
              padding: rem(5) rem(20);
              border-radius: rem(1);
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              .plus {
                color: hsla(48, 0%, 51%, 1);
                font-family: "Baskerville";
                font-weight: 600;
                font-size: rem(20);
                min-width: rem(10);
              }
              .minus {
                color: hsla(48, 0%, 51%, 1);
                font-family: "Baskerville";
                font-weight: 400;
                font-size: rem(20);
                min-width: rem(10);
              }
              .number {
                color: hsla(48, 14%, 7%, 1);
                font-family: "Baskerville";
                font-weight: 600;
                font-size: rem(20);
                padding-inline: rem(20);
              }
            }
            .bottom-right {
              color: hsla(32, 19%, 36%, 1);
              font-family: "Helvetica";
              font-weight: 400;
              font-size: rem(24);
            }
          }
        }
      }
    }
    .bottom-section {
      border-top: rem(2) solid hsla(36, 24%, 41%, 1);
      padding-top: rem(20);
      .top-inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left-section {
          .headingText {
            color: hsla(90, 2%, 16%, 1);
            font-family: "Raleway";
            font-weight: 500;
            font-size: rem(18);
            text-transform: capitalize;
          }
          .subtext {
            color: hsla(32, 19%, 36%, 1);
            font-family: "Raleway";
            font-weight: 500;
            font-size: rem(14);
          }
        }
        .right-section {
          color: hsla(32, 19%, 36%, 1);
          font-family: "Helvetica";
          font-weight: 400;
          font-size: rem(24);
        }
      }
      .checkoutbtn {
        background-color: hsla(32, 20%, 43%, 1);
        text-align: center;
        min-width: 100%;
        color: hsla(0, 0%, 100%, 1);
        font-weight: 500;
        font-size: rem(13);
        font-family: "Roboto";
        padding: rem(15) 0;
        margin-top: rem(10);
      }
    }

    @include respond(mobile) {
      min-width: 100%;
      padding-left: rem(25);
      padding-top: 2rem;
    }
    @include respond(ipad) {
      min-width: 40%;
      padding: 0;
    }
  }

  .close-button {
    position: absolute;
    right: rem(30);
    top: rem(45);
    max-width: rem(20);
    max-height: rem(20);
    z-index: 11;
    cursor: pointer;
    color: white;
    x-btn {
      &:hover {
        color: $white;
      }
    }
  }
}
.success-modal {
  .modal-content {
    .modal-header {
      justify-content: flex-end;
      .modal-close {
        cursor: pointer;
      }
    }
    .modal-body {
      .section-heading-2 {
        color: #775c3d;
        font-family: "EB Garamond";
        font-weight: 500;
        font-size: rem(24);
      }
    }
  }
}
.showsignuperror{
  color: 
  rgb(248, 35, 35);
  font-size: rem(14);
  font-weight: 400;
  font-family: "Raleway";
  letter-spacing: rem(0.5);
  
}