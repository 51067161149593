.btn{
    font-family: "Roboto";
    font-size: rem(13);
    font-weight: 500;
    padding: rem(18) rem(60);
    text-transform: uppercase;
    letter-spacing: rem(0.7);
    cursor: pointer;
}

.btn_disabled {
    cursor: inherit !important;
    background-color: #cccccc !important;
    color: #666666 !important;
}