.landingpage {
  // @include respond(desktop) {
  //   position: relative;
  //   .slider-horizontalline {
  //     &::before {
  //       content: "";
  //       position: absolute;
  //       width: rem(100);
  //       height: rem(1);
  //       bottom: rem(35);
  //       z-index: 2;
  //       background-color: hsla(41, 44%, 72%, 1);
  //       right: 40%;
  //       opacity: 0.8;
  //     }
  //     &::after {
  //       content: "";
  //       position: absolute;
  //       width: rem(100);
  //       height: rem(1);
  //       bottom: rem(35);
  //       z-index: 2;
  //       background-color: hsla(41, 44%, 72%, 1);
  //       left: 40%;
  //       opacity: 0.8;
  //     }
  //   }
  // }

  .slick-slider {
    position: relative;

    .slick-prev,
    .slick-next,
    .slick-arrow {
      display: none;
    }

    .slick-dots {
      position: absolute;
      bottom: rem(20);

      @include respond(desktop) {
        display: inline-block;
        justify-content: center;
        // transform: rotate(90deg);
        // height: rem(30);
        // width: rem(200);
        // top: 50%;
        // right: 0%;
        bottom: rem(32);

        li {
          height: rem(12);
          width: rem(12);
        }

        li button:before {
          transform: rotate(45deg);
          font-size: 0;
          height: rem(12);
          width: rem(12);
        }

        li button {
          border: rem(1) solid hsla(41, 44%, 72%, 1);
          transform: rotate(45deg);
          width: rem(12);
          height: rem(12);
        }

        li.slick-active {
          background-color: hsla(41, 44%, 72%, 1);
          transform: rotate(45deg);
          width: rem(12);
          height: rem(12);

          button {
            border: none;
          }
        }
      }

      @include respond(ipadMobile) {
        padding-bottom: rem(-10);
      }
    }
  }

  @include respond(ipadMobile) {
    .slider-dotsformobile {
      .slick-slider {
        position: relative;

        .slick-prev,
        .slick-next,
        .slick-arrow {
          display: none;
        }

        .slick-dots {
          position: absolute;
          bottom: rem(20);

          li {
            position: relative;
            display: inline-block;
            width: rem(12);
            height: 0;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;
          }

          li button:before {
            transform: rotate(45deg);
            font-size: 0;
          }

          li button {
            border: rem(1) solid hsla(41, 44%, 72%, 1);
            transform: rotate(45deg);
            width: rem(12);
            height: rem(12);
          }

          li.slick-active {
            background-color: hsla(41, 44%, 72%, 1);
            transform: rotate(45deg);
            width: rem(12);
            height: rem(12);
            margin-inline: rem(8);

            button {
              border: none;
            }
          }
        }
      }
    }
  }

  .slider-banner {
    padding: rem(70) 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: rem(800);
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond(ipad) {
      // padding: rem(60) 0 rem(40);
      padding: 0;
      min-height: rem(600);
    }

    @include respond(ipadMobile) {
      padding: rem(60) 0;
      // padding: 0;
      max-height: rem(700);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
    }

    @include respond(mediumMobile) {
      min-height: rem(900);
    }

    @include respond(smallmobile) {
      min-height: rem(1000);
    }

    .right-innercontainer {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;

      .bottle-wrapper {
        @include respond(desktop) {
          min-height: rem(701);
          display: flex;
          align-items: center;
        }

        .img {
          max-height: rem(700);
          max-width: rem(320);
          // min-width: rem(320);
        }

        @include respond(ipadMobile) {
          .img {
            max-width: rem(135);
            max-height: rem(256);
          }
        }
      }

      @include respond(ipadMobile) {
        justify-content: center;
        padding-top: rem(40);
      }

      .the-octaveBtn_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: rem(70);

        @include respond(ipadMobile) {
          padding-left: rem(50);
        }

        .img {
          padding-bottom: rem(0);
          // margin-top: 3.125rem;
          max-height: rem(152);

          @include respond(desktop) {
            max-width: rem(300);
          }

          @include respond(ipadMobile) {
            max-width: rem(84);
            max-height: rem(87);
            margin-top: 3.125rem;
          }
        }

        .btnClass {
          background-color: hsla(0, 0%, 100%, 1);
          color: hsla(17, 24%, 6%, 1);
          margin-top: rem(50);
          padding: rem(11) rem(30);
          font-size: rem(13);
          font-weight: 500;
          font-family: "Roboto";
          border-radius: 0;

          @include respond(ipadMobile) {
            display: none;
          }
        }
      }
    }

    .left-innercontainer {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include respond(desktop) {
        max-width: rem(500);
        margin-inline: auto;
      }

      .btnClass {
        background-color: hsla(0, 0%, 100%, 1);
        color: #120d0b;
        margin-top: rem(10);
        padding: rem(11) rem(30);
        font-size: rem(13);
        font-weight: 500;
        font-family: "Roboto";
        border-radius: 0;
        letter-spacing: rem(2);

        @include respond(ipadMobile) {
          font-size: rem(10);
          padding: rem(11) rem(30);

          letter-spacing: rem(0.8);
          cursor: pointer;
        }
      }

      .top-inner {
        text-align: center;
        // padding-right: rem(100);

        @include respond(ipadMobile) {
          padding-inline: rem(10);
          padding-bottom: rem(20);
        }

        .brand_logo_img {
          @include respond(desktop) {
            max-width: rem(360);
            // max-height: rem(50);
          }

          @include respond(ipadMobile) {
            max-width: rem(220);
            max-height: rem(100);
          }
        }

        .top-title {
          font-family: "Baskerville";
          font-size: rem(22);
          font-weight: 400;
          font-style: italic;
          color: #ffffff;
          margin: 0;
          padding: rem(35) 0 rem(0);

          @include respond(ipadMobile) {
            font-size: rem(16);
            opacity: 0.8;
            padding: rem(10) 0;
          }
        }

        h2 {
          font-family: "EB Garamond";
          font-size: rem(53);
          font-weight: 400;
          color: hsla(0, 0%, 100%, 1);
          text-transform: uppercase;
          line-height: 1;
          letter-spacing: rem(1);

          @include respond(desktop) {
            padding: rem(8) rem(0);

            letter-spacing: rem(1);
          }

          @include respond(ipadMobile) {
            font-size: rem(32);
            // padding-inline: rem(30);
            letter-spacing: rem(2);

          }
        }

        .description {
          font-family: "Raleway";
          font-size: rem(16);
          letter-spacing: rem(0.3);
          font-weight: 500;
          line-height: 1.5;
          color: #ffffff;
          padding: 0;
          text-align: center;

          @include respond(ipadMobile) {
            font-size: rem(12);
            font-weight: 500;

            padding-bottom: 0;
          }
        }
      }

      .bottom-inner {
        padding-top: rem(30);

        @include respond(ipadMobile) {
          padding-top: rem(10);
          display: none;
        }
      }
    }
  }

  // .slider-bannerthird {
  //   padding: rem(180) 0;
  //   background-position: center;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   @include respond(ipad) {
  //     padding: rem(50) 0;
  //   }
  //   @include respond(mobile) {
  //     padding: rem(20) 0;
  //   }
  //   .right-innercontainer {
  //     display: flex;
  //     justify-content: space-between;
  //     height: 100%;
  //     flex-direction: column;
  //     align-items: center;
  //     .bottle-wrapper {
  //       img {
  //         padding-top: rem(20);
  //         width: rem(650);
  //         max-height: rem(380);
  //       }
  //     }
  //     .the-octaveBtn_wrapper {
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: center;
  //       align-items: center;
  //       .img {
  //         padding-bottom: rem(0);
  //         margin-top: rem(50);
  //       }
  //     }
  //   }
  //   .left-innercontainer {
  //     min-height: 100%;
  //     display: flex;
  //     flex-direction: column;
  //     padding-top: rem(50);
  //     align-items: center;
  //     .top-inner {
  //       text-align: center;
  //       p {
  //         font-family: "Baskerville";
  //         font-size: rem(21);
  //         font-weight: 400;
  //         font-style: italic;
  //         color: hsla(0, 0%, 100%, 1);
  //         margin: 0;
  //       }
  //       h2 {
  //         font-family: "EB Garamond";
  //         font-size: rem(53);
  //         font-weight: 400;
  //         color: hsla(0, 0%, 100%, 1);
  //         text-transform: uppercase;
  //         padding: rem(8) 0 rem(10);
  //         // max-width: 80%;
  //         line-height: 1;
  //         margin: auto;
  //       }
  //       .description {
  //         font-family: "Raleway";
  //         font-size: rem(16);
  //         font-weight: 500;
  //         color: hsla(44, 56%, 59%, 1);
  //         // max-width: 80%;
  //         // margin: auto;
  //         padding-top: rem(20);
  //         padding-inline: rem(30);
  //         text-align: center;
  //       }
  //     }
  //     .bottom-inner {
  //       padding-top: rem(40);
  //       .btnClass {
  //         background-color: hsla(0, 0%, 100%, 1);
  //         color: hsla(17, 24%, 6%, 1);
  //         margin-top: rem(50);
  //         padding: rem(11) rem(30);
  //         font-size: rem(13);
  //         font-weight: 500;
  //         font-family: "Roboto";
  //         border-radius: 0;
  //         @include respond(ipad) {
  //           display: none;
  //         }
  //         @include respond(mobile) {
  //           display: none;
  //         }
  //       }
  //     }
  //   }
  // }
}