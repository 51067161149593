.the_charity_details_section {
  display: block;
  height: 50vw;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;

  @include respond(mobile) {
    height: rem(775);
    background-position: bottom center;
    padding: rem(80) rem(16) rem(50);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }

  @include respond(mediumMobile) {
    height: rem(761);
  }

  .mobile_journey_image {
    display: none;
    text-align: center;
    margin-top: rem(102);

    @include respond(mobile) {
      display: block;
    }

    img {
      width: 100% !important;
      height: auto !important;
      max-width: rem(500) !important;
      margin: 0 auto;
    }
  }

  .special_charity_div {
    display: block;
    position: absolute;
    top: 50%;
    left: 8%;
    transform: translateY(-50%);
    max-width: 32vw;

    @include respond(ipadProMobile) {
      max-width: rem(447);
      // left: 2%;
    }

    @include respond(ipadMobile) {
      max-width: rem(307);
      left: 5%;
    }

    @include respond(mobile) {
      position: relative;
      top: inherit;
      left: inherit;
      transform: none;
      max-width: 100%;
      text-align: center;
    }

    h2 {
      font-family: "Lato";
      font-size: rem(44);
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: rem(0.5);
      text-transform: uppercase;
      margin: 0 0 rem(30);
      color: #ffc70b;
      max-width: rem(450);

      @include respond(ipadMobile) {
        font-size: rem(32);
        margin: 0 0 rem(22);
        line-height: rem(36);
      }

      @include respond(mobile) {
        font-size: rem(26);
        font-weight: 500;
        line-height: rem(30);
        margin: 0 auto rem(25);
        text-transform: uppercase;
      }
    }

    p {
      font-family: "Lato";
      font-size: rem(26);
      font-weight: 700;
      line-height: 1.2;
      text-align: left;
      margin: 0 0 rem(32);
      color: #ffffff;

      letter-spacing: rem(0.5);
      @include respond(desktop) {
        max-width: rem(380);
      }

      @include respond(ipadMobile) {
        font-size: rem(20);
        margin: 0 0 rem(22);
        // line-height: 1.1;
      }

      @include respond(mobile) {
        font-size: rem(19);
        line-height: 1.2;
        letter-spacing: rem(1);
        text-align: center;
        max-width: rem(300);
        margin: 0 auto rem(30);
      }
    }

    .btn_buy {
      width: rem(185);
      height: rem(55);
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Raleway";
      font-size: rem(16);
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.13em;

      border-radius: rem(6);
      border: rem(2) solid #ffffff;
      color: #fff;

      @include respond(ipadMobile) {
        font-size: rem(12);
        width: rem(225);
        height: rem(43);
      }

      @include respond(mobile) {
        font-size: rem(12);
        line-height: rem(20);
        text-align: center;
        max-width: rem(500);
        margin: 0 auto;
      }
    }
  }
  .btn_buy {
    width: rem(185);
    height: rem(55);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Raleway";
    font-size: rem(18);
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.13em;

    border-radius: rem(6);
    border: rem(2) solid #ffffff;
    color: #fff;

    @include respond(ipadMobile) {
      font-size: rem(12);
      width: rem(225);
      height: rem(43);
    }

    @include respond(mobile) {
      font-size: rem(15);
      line-height: rem(20);
      text-align: center;
      max-width: rem(180);
      margin: 0 auto;
    }
  }
}
.galary-container868 {
    padding: rem(80) 0 rem(40);
  
    @include respond(mobile) {
      padding: rem(70) 0 rem(30);
    }
  
    .slider-container {
      display: flex;
      overflow-x: auto;
      flex-basis: 100%;
      flex-grow: 90%;
      gap: 0 rem(40);
      padding: rem(10) 0;
  
      @include respond(mobile) {
        gap: 0 rem(10);
        padding: rem(20) 0 rem(30);
        scrollbar-color: green;
      }
  
      .slider-card {
        padding: rem(280) rem(400);
        @include bgCover();
        display: flex;
        flex-basis: 100%;
        flex-grow: 100%;
  
        @include respond(mobile) {
          padding: rem(101) rem(148);
          flex-basis: 100%;
          flex-grow: 100%;
        }
      }
    }
  
    .slider-container::-webkit-scrollbar {
      @include respond(desktop) {
        display: none;
      }
    }
  }
