.new_number_868_collection_section {
    display: block;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    position: relative;
    // background-image: url("/number868/banner_bg_868.jpg");
    min-height: rem(900);

    @include respond(ipadPro) {
        min-height: rem(800);
        background-position: 89% center;
    }

    @include respond(ipadMobile) {
        background-image: none !important;
        background-position: bottom;
        min-height: initial;
        background-color: #000000;
    }

    .collection_container {
        max-width: 90%;

        @include respond(ipadMobile) {
            max-width: 100%;
        }
    }


    .new_number_868_collection_inner_div {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8vw;

        @include respond(ipadPro) {
            left: 4vw;
        }

        @include respond(ipadMobile) {
            position: relative;
            top: inherit;
            transform: none;
            left: inherit;
            padding: rem(220) 0 rem(38);
            text-align: center;
        }

        @include respond(mobile) {
            padding: rem(180) 0 rem(38);
        }

        @include respond(mediumMobile) {
            padding: rem(140) 0 rem(38);
        }

        .new_number_868_banner_logo_section {
            max-width: rem(544);

            @include respond(ipadPro) {
                max-width: rem(450);
            }

            @include respond(ipadMobile) {
                max-width: 100%;
            }

            .mobile_banner_img {
                display: none;

                @include respond(ipadMobile) {
                    display: block;
                    width: 100% !important;
                    height: auto !important;
                }
            }

            h3 {
                font-family: "Lato";
                font-size: rem(18);
                font-weight: 700;
                line-height: rem(18);
                letter-spacing: 0.12em;
                color: #ffffff;
                text-transform: uppercase;
                margin: 0 0 rem(12);

                @include respond(mediumMobile) {
                    font-size: rem(14);
                    line-height: rem(15);
                }
            }

            h2 {
                font-family: "Lato";
                font-size: rem(40);
                font-weight: 700;
                line-height: rem(48);
                letter-spacing: 0.02em;
                text-transform: uppercase;
                margin: 0;
                color: #ffc70b;

                @include respond(mediumMobile) {
                    font-size: rem(26);
                    line-height: rem(28);
                }
            }

            p {
                font-family: "Raleway";
                font-size: rem(18);
                font-weight: 300;
                line-height: rem(24);
                margin: rem(32) 0;
                color: #ffffff;

                @include respond(ipadMobile) {
                    max-width: rem(500);
                    margin: rem(32) auto;
                }

                @include respond(mediumMobile) {
                    font-size: rem(14);
                    line-height: rem(18);
                    margin: rem(18) auto rem(0);
                    padding: 0 rem(16);
                }
            }

            .new_number_btn_buy {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                max-width: rem(178);
                height: rem(49);
                border-radius: rem(6);
                background: #fff;
                font-family: "Lato";
                font-size: rem(18);
                font-weight: 800;
                line-height: normal;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: #986917;
                box-shadow: 1px 1px 4px 0px #0000002b;
                margin: 0;
                padding: 0;

                @include respond(ipadMobile) {
                    margin: 0 auto;
                }

                @include respond(mediumMobile) {
                    max-width: rem(144);
                    height: rem(36);
                    font-size: rem(12);
                }
            }
        }
    }
}

.collection_container868 {
    @include respond(desktop) {
        max-width: 90%;
    }
}

.number_868_collection_abut_us_section {
    padding: rem(140) 0;

    @include respond(ipadMobile) {
        padding: rem(80) 0 rem(10);
    }

    .collection_row_reverse {
        @include respond(ipadMobile) {
            flex-direction: column-reverse;
        }
    }

    .about_img_section {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;

        // @include respond(ipadMobile) {
        //   display: none;
        // }

        img {
            width: 100% !important;
            height: auto !important;
            max-width: rem(622) !important;
        }
    }

    .about_text_div {
        display: block;
        text-align: center;
        margin: 0px auto;
        max-width: rem(600);
        position: relative;

        @include respond(ipadMobile) {
            max-width: rem(650);
            // padding-bottom: rem(80);
        }

        .about_text_img {
            display: none;

            @include respond(ipadMobile) {
                display: block;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        h3 {
            font-family: "Lato";
            font-size: rem(24);
            font-weight: 700;
            line-height: rem(31);
            letter-spacing: 0.06em;
            text-transform: uppercase;
            color: #222f61;
            margin: 0 0 rem(16);

            @include respond(mobile) {
                font-size: rem(14);
                letter-spacing: 0.05em;
                margin: 0 0 rem(12);
                line-height: rem(15);
            }
        }

        h2 {
            font-family: "Lato";
            font-size: rem(44);
            font-weight: 700;
            line-height: rem(48);
            letter-spacing: 0.02em;
            margin: 0;
            color: #ffc70b;
            text-transform: uppercase;

            @include respond(mobile) {
                font-size: rem(26);
                line-height: rem(28);
            }
        }

        p {
            font-family: "Raleway";
            font-size: rem(21);
            font-weight: 350;
            line-height: rem(33);
            margin: rem(40) 0 0;
            color: #222f61;

            @include respond(mobile) {
                font-size: rem(14);
                margin: rem(32) 0 0;
                line-height: rem(22);
            }
        }
    }
}

.number_868_collection_tasting_nots {
    display: block;
    padding: rem(80) 0;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    position: relative;
    // background-image: url("/number868/tasting_nots_bg.png");

    @include respond(mediumMobile) {
        padding: rem(60) 0;
    }

    .tasting_text_div {
        display: block;
        max-width: rem(524);
        padding-top: rem(60);

        @include respond(ipadMobile) {
            padding-top: 0;
            margin-bottom: rem(32);
            max-width: 100%;
        }

        h2 {
            font-family: "Lato";
            font-size: rem(40);
            font-weight: 700;
            line-height: rem(44);
            letter-spacing: 0.02em;
            color: #ffc70b;
            margin: 0;
            text-transform: uppercase;

            @include respond(mediumMobile) {
                font-size: rem(26);
                line-height: rem(25);
            }
        }

        p {
            font-family: "Lato";
            font-size: rem(27);
            font-weight: 400;
            line-height: rem(32);
            letter-spacing: 0.06em;
            color: #ffffff;
            margin: rem(24) 0 0;

            @include respond(mediumMobile) {
                font-size: rem(16);
                line-height: rem(17);
                margin: rem(16) 0 0;
            }
        }

        .tasting_nots_btn_buy {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: rem(176);
            height: rem(56);
            border-radius: rem(6);
            background: #ffffff;
            font-family: "Raleway";
            font-size: rem(18);
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.08em;
            color: #a26e15;
            text-transform: uppercase;
            box-shadow: 1px 1px 4px 0px #0000002b;
            padding: 0;
            @include respond(ipadMobile) {
                max-width: rem(144);
                height: rem(36);
                font-size: rem(12);
                font-weight: 800;
            }
        }

        .tasting_nots_details_section {
            display: block;
            padding: rem(40) 0 rem(16);

            @include respond(ipadMobile) {
                display: grid;
                gap: rem(5);
                grid-template-columns: repeat(2, 1fr);
                padding: rem(24) 0 rem(12);
            }

            .details {
                display: flex;
                gap: rem(40);
                margin-bottom: rem(24);

                @include respond(ipadMobile) {
                    display: block;
                }

                h5 {
                    width: rem(137);
                    min-width: rem(137);
                    font-family: "Lato";
                    font-size: rem(16);
                    font-weight: 700;
                    line-height: rem(28);
                    letter-spacing: 0.05em;
                    color: #ffc70b;
                    text-transform: uppercase;
                    margin: 0;

                    @include respond(mediumMobile) {
                        width: auto;
                        min-width: auto;
                        font-size: rem(12);
                        line-height: rem(13);
                        margin: 0 0 rem(10);
                    }
                }

                h6 {
                    font-family: "Lato";
                    font-size: rem(26);
                    font-weight: 700;
                    line-height: rem(28);
                    color: #ffffff;
                    margin: 0;
                    text-transform: uppercase;

                    @include respond(mediumMobile) {
                        font-size: rem(18);
                        line-height: rem(19);
                    }
                }
            }
        }
    }

    .tasting_nots_img_section {
        display: block;

        .tasting_not_main_img {
            width: 100% !important;
            height: auto !important;
        }
    }

    .number_868_tasting_box {
        display: block;
        padding-top: rem(99);

        @include respond(ipadMobile) {
            padding-top: rem(80);
        }

        .number_868_testing_nots_box_section {
            gap: rem(32) 0;
            margin: rem(60) 0;

            @include respond(mediumMobile) {
                margin: rem(32) 0;
            }

            .number_868_row_of_testing_notes {
                justify-content: center;
                gap: rem(24) 0;
            }

            .testing_box {
                display: block;
                padding: rem(40);
                border: rem(2) solid #a88716;
                height: 100%;
                border-radius: rem(2);

                @include respond(mediumMobile) {
                    padding: rem(24);
                }

                h2 {
                    margin: 0 0 rem(16);
                    color: #ffffff;
                    text-transform: uppercase;
                    font-family: "Raleway";
                    font-size: rem(26);
                    font-weight: 500;
                    line-height: rem(27);
                    letter-spacing: 0.14em;
                    text-align: center;
                    text-transform: uppercase;

                    @include respond(mediumMobile) {
                        font-size: rem(20);
                        line-height: rem(21);
                    }
                }

                p {
                    font-family: "Raleway";
                    font-size: rem(17);
                    font-weight: 400;
                    line-height: rem(25);
                    margin: 0;
                    color: #ffffff;
                    text-align: center;

                    @include respond(mediumMobile) {
                        font-size: rem(16);
                        line-height: rem(23);
                    }
                }
            }
        }
    }

    .images_section_of_tasting {
        display: block;

        .six_images_section {
            width: 100% !important;
            height: auto !important;
        }

        h3 {
            font-family: "Lato";
            font-size: rem(18);
            font-weight: 500;
            line-height: rem(18);
            letter-spacing: 0.03em;
            text-align: center;
            color: #ffc70b;
            padding: rem(32) 0;
            text-transform: uppercase;

            @include respond(mediumMobile) {
                font-size: rem(12);
                line-height: rem(12);
                padding: rem(12) 0 rem(22);
            }
        }
    }
}

.number_868_toptext {
    color: #ffc70b;
    text-align: center;
    font-family: "Lato";
    font-size: rem(40);
    font-weight: 700;
    line-height: rem(44);
    letter-spacing: 0.02em;
    text-align: center;
    margin: 0;
    position: relative;

    @include respond(ipadMobile) {
        margin: 0 0 rem(20);
    }

    @include respond(mobile) {
        font-size: rem(26);
        line-height: normal;
        margin: 0;
    }

    &::after {
        content: "";
        position: absolute;
        right: rem(20);
        top: 50%;
        height: rem(2);
        width: rem(410);
        background-color: hsla(40, 41%, 44%, 1);

        @include respond(smalldesktop) {
            width: rem(310);
        }

        @include respond(ipadProMobile) {
            width: rem(200);
        }

        @include respond(ipadMobile) {
            top: 48%;
            width: rem(80);
            height: rem(1);
            right: rem(48);
        }

        @include respond(mobile) {
            top: 48%;
            width: rem(40);
            height: rem(1);
            right: 0;
            display: none;
        }
    }

    &::before {
        content: "";
        position: absolute;
        left: rem(20);
        top: 50%;
        height: rem(2);
        width: rem(410);
        background-color: hsla(40, 41%, 44%, 1);

        @include respond(smalldesktop) {
            width: rem(310);
        }

        @include respond(ipadProMobile) {
            width: rem(200);
        }

        @include respond(ipadMobile) {
            top: 48%;
            width: rem(80);
            height: rem(1);
            left: rem(48);
        }

        @include respond(mobile) {
            top: 48%;
            width: rem(40);
            height: rem(1);
            left: 0;
            display: none;
        }
    }
}

.foundation_details_section {
    display: block;
    padding: rem(120) 0;

    @include respond(mobile) {
        padding: rem(24) 0;
    }

    .foundation_img_section {
        display: block;

        img {
            width: 100% !important;
            height: auto !important;
        }
    }

    .foundation_text_div {
        display: block;
        padding-left: rem(50);
        max-width: rem(600);

        @include respond(ipadMobile) {
            max-width: 100%;
            padding: rem(40) 0 0;
        }

        h2 {
            font-family: "Lato";
            font-size: rem(44);
            font-weight: 700;
            line-height: rem(48);
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #ffc70b;
            margin: 0 0 rem(16);

            @include respond(mobile) {
                font-size: rem(26);
                line-height: rem(26);
            }
        }

        h3 {
            font-family: "Lato";
            font-size: rem(24);
            font-weight: 700;
            line-height: rem(25);
            letter-spacing: 0.14em;
            text-transform: uppercase;
            color: #222f61;
            margin: 0;

            @include respond(mobile) {
                font-size: rem(16);
                line-height: rem(17);
            }
        }

        p {
            font-family: "Raleway";
            font-size: rem(18);
            font-weight: 400;
            line-height: rem(28.8);
            color: #1b2747;
            padding: rem(32) 0 0;
            margin: 0;

            @include respond(mobile) {
                font-size: rem(13);
                line-height: rem(20);
            }
        }
    }
}