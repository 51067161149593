.gin-container {
  padding: 0 2.5vw;

  @include respond(mediumMobile) {
    padding: 0px;
  }

  .image-sliderContainer {
    margin: rem(250) 0 rem(50);

    @include respond(ipadMobile) {
      margin: rem(190) 0 rem(0);
    }

    @include respond(mobile) {
      margin: rem(150) 0 rem(0);
    }

    @include respond(smallmobile) {
      margin: rem(150) 0 rem(0);
    }


    .slider-card {
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      min-height: 40rem;
      width: 100%;
      position: relative;

      @include respond(ipadMobile) {
        min-height: 38vh;
      }

      .card-bg {
        min-width: 100%;
        max-height: rem(500);

        @include respond(ipadMobile) {
          max-height: 24vh;
        }
      }

      .bottle {
        position: absolute;
        bottom: 0%;
        left: 50%;
        transform: translate(-50%, -15%);
        max-width: rem(370);
        max-height: rem(450);

        @include respond(ipadMobile) {
          max-width: rem(240);
          max-height: rem(280);
          transform: translate(-50%, -50%);
        }

        @include respond(mediumMobile) {
          max-height: rem(170);
        }

        @include respond(smallmobile) {
          max-width: rem(120);
        }
      }

      .bottle-shadow {
        position: absolute;
        bottom: 0%;
        left: 50%;
        transform: translate(-50%, -40%);

        @include respond(ipadMobile) {
          bottom: 19.5%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: rem(110);
          max-height: rem(14);
        }
      }
    }

    .slick-dots {
      display: none !important;
    }

    .slick-slider {
      .slick-arrow {
        &.slick-next {
          right: rem(50);
          z-index: 2;
          top: 37%;

          @include respond(ipadMobile) {
            right: rem(20);
            top: 30%;
          }

          &::before {
            background-repeat: no-repeat;
            background-image: url("/indianSummerGin/right-arrow.svg");
            background-size: rem(15) rem(15);
            display: inline-block;
            width: rem(40);
            height: rem(40);
            content: "";
            border: rem(3) solid rgba(133, 112, 88, 1);
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.7);
            background-position: center;

            @include respond(ipadMobile) {
              width: rem(25);
              height: rem(25);
              background-size: rem(8) rem(11);
              border: rem(1) solid rgba(133, 112, 88, 1);
            }
          }
        }

        &.slick-prev {
          left: rem(30);
          z-index: 1;
          top: 37%;

          @include respond(ipadMobile) {
            left: rem(15);
            top: 30%;
          }

          &::before {
            background-repeat: no-repeat;
            background-image: url("/indianSummerGin/left-arrow.svg");
            background-size: rem(15) rem(15);
            display: inline-block;
            width: rem(40);
            height: rem(40);
            content: "";
            border: rem(3) solid rgba(133, 112, 88, 1);
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.7);
            background-position: center;

            @include respond(ipadMobile) {
              width: rem(25);
              background-size: rem(8) rem(11);
              border: rem(1) solid rgba(133, 112, 88, 1);

              height: rem(25);
            }
          }
        }
      }
    }
  }

  .wanderland-container {
    text-align: center;

    .header-container {
      &.slider_header_container_div {
        height: auto;
      }
    }

    h3 {
      color: #1b1b1b;
      font-size: rem(34);
      font-weight: 600;
      font-family: "EB Garamond";
      line-height: 1.5;
      margin-bottom: 0;
      padding-bottom: rem(20);

      @include respond(ipadMobile) {
        font-size: rem(16);
      }

      span {
        padding-left: rem(10);
        color: rgba(182, 153, 94, 1);
        font-size: rem(32);
        font-style: italic;
        font-weight: 600;
        font-family: "Raleway";
        line-height: 1.6;

        @include respond(ipadMobile) {
          font-size: rem(16);
        }
      }
    }

    .card-container {
      display: flex;
      align-items: center;

      gap: rem(15);
      margin-bottom: rem(80);

      @include respond(ipadMobile) {
        overflow-x: auto;
        margin-bottom: rem(65);
        padding-bottom: rem(15);
        gap: rem(10);
      }

      .card {
        background-color: rgba(255, 255, 255, 1);
        outline: none;
        border: none;
        box-shadow: rem(5) rem(5) rem(10) rgba(0, 0, 0, 0.08);

        @include respond(desktop) {
          flex-basis: 20%;
        }

        @include respond(ipadMobile) {
          min-width: rem(250);
        }

        p {
          color: rgba(27, 27, 27, 1);
          font-family: "Poppins";
          font-size: rem(16);
          font-weight: 600;
          text-transform: uppercase;
          padding-top: rem(15);
        }
      }
    }
  }
}

.gin_section {
  display: block;
  padding: rem(0) 0 rem(100);

  @include respond(ipadMobile) {
    padding: rem(4) 0 rem(100);
  }

  @include respond(smallmobile) {
    padding: rem(0) 0 rem(0);
  }

  .gin_head {
    display: block;
    margin: 0 auto;
    text-align: center;

    @include respond(mediumMobile) {
      padding: rem(0) rem(16);
    }

    h2 {
      color: #b6995e;
      text-align: center;
      font-family: "EB Garamond";
      font-size: rem(64);
      font-weight: 700;
      line-height: normal;
      margin-bottom: rem(20);
      text-transform: uppercase;

      @include respond(ipadMobile) {
        font-size: rem(24);
      }

      @include respond(smallmobile) {
        font-size: rem(22);
        margin-bottom: rem(16);
      }
    }

    p {
      text-align: center;
      font-family: Raleway;
      font-size: rem(16);
      font-weight: 400;
      line-height: 21.7px;
      color: #181818;
      max-width: 791px;
      margin: 0 auto;

      @include respond(ipadMobile) {
        font-size: rem(14);
        max-width: 500px;
      }
    }
  }

  .gin_collection_section {
    margin-top: rem(60);
    display: block;
    background: linear-gradient(0deg, #fff5f5 0%, #fff5f5 100%), #fbf7e6;

    @include respond(ipadMobile) {
      position: relative;
      padding-top: rem(130);
    }

    @include respond(mediumMobile) {
      padding: rem(130) rem(16) rem(0);
    }

    .gin_inner_div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: rem(14);
      padding: rem(33) rem(83);

      @include respond(ipadMobile) {
        flex-direction: column-reverse;
      }

      @include respond(mediumMobile) {
        padding: rem(0) rem(16);
      }

      .gin_detail_section {
        width: 48%;

        @include respond(ipadMobile) {
          width: 100%;
        }

        .inner_details {
          display: block;

          @include respond(ipadMobile) {
            text-align: center;
          }

          p {
            color: #000;
            font-family: Raleway;
            font-size: rem(16);
            font-style: normal;
            font-weight: 300;
            line-height: rem(27);
            // text-transform: capitalize;
            margin-bottom: rem(26);
          }

          h3 {
            color: #000;
            font-family: "EB Garamond";
            font-size: rem(36);
            font-weight: 400;
            line-height: rem(45);
            letter-spacing: 1.8px;
            text-transform: uppercase;
            margin: 0px;
            max-width: 445px;

            @include respond(ipadMobile) {
              margin: 0px auto;
              position: absolute;
              top: 35px;
              left: 50%;
              width: 100%;
              transform: translateX(-50%);
              text-align: center;
            }

            @include respond(mediumMobile) {
              font-size: rem(24);
              line-height: 28.989px;
              max-width: 270px;
            }
          }

          a {
            &.read_more_gin {
              display: block;
              background: #b6995e;
              text-decoration: none;
              color: #fff;
              font-family: Raleway;
              font-size: rem(16);
              font-style: normal;
              font-weight: 600;
              line-height: rem(30);
              text-transform: capitalize;
              width: 171px;
              height: 51px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: rem(26);

              @include respond(ipadMobile) {
                margin: rem(0) auto rem(26);
              }
            }
          }

          .award_section {
            display: block;

            @include respond(mediumMobile) {
              margin: 0 0 rem(25);
            }

            h2 {
              color: #775c3d;
              font-family: "EB Garamond";
              font-size: rem(18);
              font-weight: 600;
              line-height: normal;
              text-transform: capitalize;
              margin-bottom: rem(9);
            }

            .awards_img {
              display: flex;
              align-items: center;
              justify-content: left;
              gap: 10px;

              @include respond(ipadMobile) {
                justify-content: center;
              }

              img {
                width: 46px;
                height: 50px;
              }
            }
          }

          .years_details {
            display: block;
            margin: rem(34) 0;

            @include respond(mediumMobile) {
              margin: rem(20) 0;
            }

            h4 {
              color: #8e5e37;
              font-family: Raleway;
              font-size: rem(16);
              font-weight: 300;
              line-height: normal;
              margin: rem(3) 0;
            }
          }
        }
      }

      .gin_image_section {
        width: 66%;
        height: 597px;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80%;
        display: block;

        @include respond(ipadMobile) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60%;
          background-size: contain;
          height: 57vw;
        }

        @include respond(mediumMobile) {
          height: 380px;
          width: 100%;
        }

        @include respond(mobile) {
          width: 60%;
        }

        @include respond(smallmobile) {
          height: 300px;
        }

        img {
          display: block;
          max-width: 80%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px auto;
          object-fit: contain;

          @include respond(ipadMobile) {
            height: 48vw;
          }

          @include respond(mediumMobile) {
            height: 66vw;
          }
        }
      }
    }

    .nots_section {
      display: flex;
      width: 100%;

      @include respond(ipadMobile) {
        display: block;
      }

      .nots_details {
        border-collapse: inherit;
        border: 0.5px solid #525252;
        padding: rem(27) rem(48);
        width: 33.33%;

        @include respond(ipadMobile) {
          border-left: 0px;
          border-right: 0px;
          text-align: center;
          width: 100%;

          &.last_nots {
            border-bottom: 0px;
          }
        }

        @include respond(mediumMobile) {
          padding: rem(18) rem(27);
        }

        h2 {
          color: #282828;
          font-family: Raleway;
          font-size: rem(18);
          font-weight: 700;
          line-height: normal;
          text-transform: capitalize;
          margin-bottom: rem(13);
        }

        p {
          color: #282828;
          font-family: Raleway;
          font-size: rem(14);
          font-weight: 400;
          line-height: normal;
          // text-transform: capitalize;
          margin: 0px;
        }

        &.center_nots {
          border-left: 0px;
          border-right: 0px;

          @include respond(ipadMobile) {
            border: 0px;
          }
        }
      }
    }
  }
}