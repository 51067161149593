.faqs-section__search {
  display: flex;
  margin-top: rem(30);

  input {
    border-radius: rem(10);
    border-color: transparent;
    &::placeholder {
      color: lightcyan;
    }
    &:focus-visible {
      outline-color: grey;
    }

    @include respond(desktop) {
      background-color: $white;
      min-height: rem(60);
      max-width: calc(100% - rem(80));
      flex-basis: calc(100% - rem(80));
      padding: rem(15) rem(20);
    }
    @include respond(ipadMobile) {
      min-height: rem(45);
      max-width: calc(100% - rem(65));
      flex-basis: calc(100% - rem(65));
      padding: rem(6) rem(20);
      background-color: grey;
    }
  }
  &--btn {
    border-radius: rem(5);
    background-color: wheat;
    display: inline-block;
    margin-left: rem(20);
    @include flex-center();
    @include respond(desktop) {
      height: rem(60);
      width: rem(60);
    }
    @include respond(ipadMobile) {
      height: rem(45);
      width: rem(45);
    }
  }
}

.faqs-accordian {
  background-color: $white;
  overflow: hidden;
  @include respond(desktop) {
    border-radius: rem(20);
    margin-top: rem(62);
    max-width: calc(100% - rem(80));
    flex-basis: calc(100% - rem(80));
  }
  @include respond(ipadMobile) {
    border-radius: rem(10);
    margin-top: 2.5rem;
  }
  &__video {
    margin-top: rem(18);
    width: rem(288);
    height: rem(188);
    border-radius: rem(10);
    overflow: hidden;
    position: relative;
    background-image: url("/images/about-us.png");
    @include bgCover();
    video {
      height: 100%;
      width: 100%;
    }
    a {
      cursor: pointer;
      position: absolute;
      height: 100%;
      width: 100%;
      @include flex-center();
    }
  }
  .accordion-item {
    border: none;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - rem(60));
      height: 1px;
      background-color: green;
    }
  }
  .accordion-body {
    padding: 0 rem(30) rem(20) rem(30);
    max-width: 50rem;

    p {
      margin-bottom: 0;
    }
    p,
    ul {
      font-size: rem(12);
      font-weight: 500;
      color: grey;
      line-height: 1.6;
    }
    ul {
      list-style-type: disc;
      padding-left: rem(20);
      margin-top: rem(20);
    }
  }
  .accordion-button {
    font-weight: 700;

    padding: rem(20) rem(30);
    &::after {
      width: rem(12);
      height: rem(6);
      background-size: contain;
      background-image: url("/svg/arrow-down.svg");
    }

    @include respond(ipadMobile) {
      font-size: rem(12);
      line-height: 1.4;
    }
    &:not(.collapsed),
    &:focus {
      box-shadow: none;
      background-color: transparent;
      border: none;
      outline: none;
    }
  }
}
.accordion-button:focus {
  box-shadow: none !important;
}

.faqs-section {
  @include respond(ipadMobile) {
    padding-top: 0;
  }
  &__row {
    @include respond(ipadMobile) {
      padding: 2.5rem 0;
    }
  }
}
.accordion-collapse {
  .accordion-body {
    text-align: left;
  }
}
