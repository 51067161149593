.color-variant-wrapper {
    position: relative;
    width: 100%;
    @include respond(mobile) {
      display: flex;
      flex-direction: column-reverse;
    }
    p {
      color: #f9f8f7;
  
      @include respond(desktop) {
        font-size: rem(18);
        transform: translate(35px, -50%);
      }
  
      @include respond(ipadMobile) {
        font-size: rem(16);
      }
  
      @include respond(ipad) {
        transform: translate(15px, -50%);
      }
  
      @include respond(desktopIpad) {
        margin-bottom: 0;
        font-weight: 300;
        position: absolute;
        top: 50%;
        right: 0;
      }
    }
  }
  
  .color-variant {
    @include respond(desktopIpad) {
      max-width: rem(210);
      flex-basis: rem(230);
    }
  
    @include flex-between();
    position: relative;
    align-items: center;
  
    &::after {
      content: "";
      position: absolute;
      width: calc(100% - 10px);
      height: 2px;
      background-color: #c4c4c4;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  
    &__child {
      display: inline-block;
      width: rem(15);
      height: rem(15);
      border-radius: 50%;
      position: relative;
      z-index: 1;
      &.active {
        width: rem(27);
        height: rem(27);
        border: 1px solid #f9c467;
      }
    }
  }