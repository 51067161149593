.footer-container {
  background-color: #181716;
  padding: rem(80) 0 rem(60);
  display: flex;
  justify-content: center;
  align-items: center;
  color: hsla(40, 85%, 97%, 1);
  font-family: "Baskerville";
  font-weight: 400;
  letter-spacing: rem(0.5);

  @include respond(ipad) {
    padding: rem(60) 0;
  }

  @include respond(mobile) {
    padding: rem(50) 0;
  }

  .text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      padding-bottom: rem(40);
      max-width: rem(136);
      max-height: rem(122);

      @include respond(ipad) {
        padding-bottom: rem(30);
        min-height: rem(100);

      }

      @include respond(mobile) {
        padding-bottom: rem(15);
        height: rem(55);
        min-width: rem(65);
      }
    }

    .inner-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      letter-spacing: rem(.5);
      opacity: 0.7;
      font-weight: 400;
      color: hsla(40, 85%, 97%, 1);
      font-family: "Baskerville";

      @include respond(desktop) {
        font-size: rem(20);
      }

      @include respond(ipad) {
        font-size: rem(15);
      }

      @include respond(mobile) {
        font-size: rem(12);
      }

      span:not(:first-child) {
        @include respond(desktop) {
          font-size: rem(16);
        }

        @include respond(ipad) {
          font-size: rem(14);
        }

        @include respond(mobile) {
          font-size: rem(12);
        }
      }
    }

    .social-icon_wrapper {
      @include respond(desktop) {
        display: none;
      }

      @include respond(ipad) {
        display: none;
      }

      @include respond(mobile) {
        padding-top: rem(30);
      }

      .social_img {
        padding: 0;
        height: rem(25);
      }
    }

    .lower-text {
      padding-top: rem(20);
      opacity: 0.7;
      font-size: rem(18);
      color: hsla(40, 85%, 97%, 1);
      letter-spacing: rem(0.5);

      ul {
        display: flex;
        align-items: center;

        @include respond(ipadMobile) {
          padding-left: 0;
        }

        @include respond(smallmobile) {
          flex-wrap: wrap;
          justify-content: center;
          gap: 5px;
        }

        li {
          a {
            color: hsla(40, 85%, 97%, 1);
          }

          &:first-child {
            @include respond(smallmobile) {
              width: 100%;
              text-align: center;
            }
          }

          &:nth-child(2) {
            @include respond(smallmobile) {
              padding-left: rem(0) !important;
            }
          }
        }

        li:not(:first-child) {
          padding-left: rem(10);
        }
      }

      @include respond(ipad) {
        font-size: rem(14);
        padding-bottom: 0;
      }

      @include respond(mobile) {
        font-size: rem(9);
        padding-top: rem(20);
        font-weight: 400;
        font-family: "Baskerville";
        padding-bottom: 0;
      }
    }
  }
}

.cld-video-player.cld-fluid .vjs-poster img {
  display: none;
}

.cld-video-player .vjs-control-bar {
  display: none !important;
}

.cld-video-player .vjs-big-play-button {
  font-size: 5em;
  width: 1em !important;
  height: auto;
  border: 0;
  margin: 0;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}