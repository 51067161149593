.octave-journey-product-wrapper {
    background-color: rgba(215, 195, 151, 0.1);
    max-width: 100%;

    @include respond(desktop) {
        padding-top: rem(130);
    }

    @include respond(ipadMobile) {
        padding-top: rem(80);
    }

    .octave_product_banner {
        margin-top: 10px;
        width: 100%;
        height: 503px;
        background-image: url("/journey/product-banner-img.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;

        @include respond(ipadMobile) {
            background-image: none;
            height: auto;
        }

        @include respond(mobile) {
            margin-top: 0px;
        }

        .mobile_octave_journey_img {
            display: none;


            @include respond(ipadMobile) {
                display: block;
                width: 100% !important;
                height: auto !important;
            }
        }

        .octave_product_banner_detail {
            max-width: rem(364);
            position: absolute;
            top: 50%;
            left: 6%;
            transform: translateY(-50%);

            @include respond(ipadMobile) {
                position: relative;
                top: inherit;
                left: inherit;
                max-width: 100%;
                background: #171412;
                transform: inherit;
                padding: rem(24) rem(16);
                text-align: center;
            }

            .octave_logo {
                @include respond(mobile) {
                    width: rem(115) !important;
                    height: rem(32) !important;
                }
            }

            h2 {
                color: #F5F5F5;
                font-family: "EB Garamond";
                font-size: rem(28);
                font-weight: 600;
                line-height: rem(36);
                padding: rem(21) rem(0) rem(8);
                text-transform: uppercase;

                @include respond(mobile) {
                    font-size: rem(16);
                    line-height: rem(20);
                    padding: rem(16) rem(0);
                }
            }

            p {
                font-family: "Raleway";
                font-size: rem(16);
                font-weight: 400;
                line-height: rem(24);
                margin-bottom: rem(24);
                color: #FFF;

                @include respond(mobile) {
                    font-size: rem(14);
                    line-height: rem(24);
                    margin-bottom: rem(16);
                }
            }

            h4 {
                font-family: "EB Garamond";
                font-size: rem(22);
                font-weight: 600;
                line-height: rem(28);
                margin: rem(21) rem(0) rem(24);
                color: #E8C280;

                @include respond(mobile) {
                    margin: rem(16) rem(0);
                }
            }

            .btn_buy_product_now {
                width: rem(210);
                height: rem(47);
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "Raleway";
                font-size: rem(16);
                font-weight: 500;
                line-height: rem(18);
                letter-spacing: 0.06em;
                background: #775C3D;
                color: #FFF;
                outline: none;
                box-shadow: none;
                border: 0px;
                border-radius: 0px;

                @include respond(ipadMobile) {
                    margin: 0px auto;
                    height: rem(50);
                    width: rem(119);
                    padding: rem(0);
                }
            }

            .all_details {
                padding: rem(14) rem(0);
                gap: rem(26);
                display: flex;
                align-items: center;
                justify-content: center;
                border-top: 1.5px solid #C29C52;
                border-bottom: 1.5px solid #C29C52;

                @include respond(mobile) {
                    padding: rem(12) rem(0);
                    gap: rem(24);
                }

                .horzontal_line {
                    background: #E8C280;
                    color: #E8C280;
                    font-size: 10px;
                }

                .abv_details {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: rem(11);

                    @include respond(mobile) {
                        gap: rem(8);
                    }

                    .title {
                        font-family: "EB Garamond";
                        font-size: rem(17);
                        font-weight: 400;
                        line-height: rem(22);
                        color: #E8C280;
                        text-transform: uppercase;

                        @include respond(mobile) {
                            font-size: rem(14);
                            line-height: rem(18);
                        }
                    }

                    h3 {
                        font-family: "Raleway";
                        font-size: rem(17);
                        font-weight: 400;
                        line-height: rem(24);
                        color: #E8C280;
                        margin: 0px;

                        @include respond(mobile) {
                            font-size: rem(14);
                            line-height: rem(24);
                        }
                    }
                }
            }
        }
    }

    .main_journey_section {
        display: block;
        padding: rem(88) rem(0);
        width: 94%;
        margin: 0px auto;

        @include respond(mobile) {
            width: 100%;
            padding: rem(40) rem(16) rem(20);
        }

        .main_img_journey {
            width: 100% !important;
            height: auto !important;
            max-height: 410px !important;

            @include respond(mobile) {
                height: rem(410) !important;
            }
        }

        .main_journey_details {
            display: block;

            h3 {
                font-family: "EB Garamond";
                font-size: rem(36);
                font-weight: 700;
                line-height: rem(32);
                letter-spacing: 0.06em;
                color: #775C3D;
                margin: rem(0) rem(0) rem(20);
                text-transform: uppercase;

                @include respond(mobile) {
                    text-align: center;
                    margin: rem(10) rem(0);
                    font-size: rem(24);
                    line-height: rem(31);
                }
            }

            p {
                font-family: "Raleway";
                font-size: rem(16);
                font-weight: 400;
                line-height: rem(24);
                color: #181818;
                margin: rem(0) rem(0) rem(20);

                @include respond(mobile) {
                    text-align: center;
                    margin: rem(0) rem(0) rem(16);
                    font-size: rem(16);
                    line-height: rem(24);
                }
            }
        }
    }

    .video_testing_section {
        display: block;
        background: #181818;

        .inner_div {
            max-width: 94%;
            padding: rem(71) rem(0);
            margin: 0px auto;
            text-align: center;

            @include respond(mobile) {
                max-width: 100%;
                padding: rem(50) rem(16);
            }

            h2 {
                font-family: "EB Garamond";
                font-size: rem(36);
                font-weight: 700;
                line-height: rem(32);
                letter-spacing: 0.06em;
                color: #B6995E;
                text-transform: uppercase;
                margin-bottom: rem(24);

                @include respond(mobile) {
                    font-size: rem(24);
                    line-height: rem(32);
                    margin-bottom: rem(16);
                }
            }

            p {
                font-family: "Raleway";
                font-size: rem(16);
                font-weight: 400;
                line-height: rem(24);
                color: #FFF;
                margin: rem(0) auto rem(24);
                max-width: rem(934);

                @include respond(mobile) {
                    font-size: rem(14);
                    line-height: rem(24);
                }
            }

            .video_detail_section {
                margin-bottom: rem(80);

                @include respond(mobile) {
                    margin-bottom: rem(20);
                }

                &:last-child {
                    margin-bottom: rem(0);
                }

                .row {
                    @include respond(mobile) {
                        flex-direction: column-reverse;
                    }
                }

                .video_title {
                    text-align: left;

                    .video_main_title_text {

                        @include respond(mobile) {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: rem(8);
                            padding: rem(14) rem(0) rem(8);
                        }

                        h2 {
                            font-family: "EB Garamond";
                            font-size: rem(50);
                            font-weight: 600;
                            line-height: rem(65);
                            letter-spacing: -0.017em;
                            color: #B6995E;
                            text-transform: uppercase;
                            margin: 0px;

                            @include respond(mobile) {
                                font-size: rem(20);
                                line-height: rem(26);
                            }
                        }

                        h3 {
                            font-family: "EB Garamond";
                            font-size: rem(32);
                            font-weight: 600;
                            line-height: rem(41);
                            color: #B6995E;
                            margin: rem(8) rem(0);

                            @include respond(mobile) {
                                font-size: rem(20);
                                line-height: rem(26);
                                margin: 0px;
                            }
                        }
                    }

                    p {
                        font-family: "Raleway";
                        font-size: rem(16);
                        font-weight: 400;
                        line-height: rem(25);
                        color: #DFDFDF;
                        margin: 0px;

                        @include respond(mobile) {
                            font-size: rem(15);
                            line-height: rem(25);
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .explore_other_product_section {
        width: 94%;
        margin: 0px auto;
        display: block;
        padding: rem(80) rem(0);

        @include respond(mobile) {
            width: 100%;
            padding: rem(50) rem(16);
        }

        .journey_product_main_titles {
            color: #775C3D;
            font-family: "EB Garamond";
            font-size: rem(32);
            font-weight: 500;
            line-height: rem(50);
            text-align: center;
            text-transform: uppercase;
            margin: 0px 0px rem(24);

            @include respond(mobile) {
                font-size: rem(24);
                font-weight: 700;
                line-height: rem(31);
                margin: 0px 0px rem(10);
            }
        }

        .journey_sub_titles {
            font-family: "Raleway";
            font-size: rem(16);
            font-weight: 400;
            line-height: rem(23);
            margin: 0px auto rem(32);
            color: #5C5C5C;
            text-align: center;
            max-width: rem(1100);

            @include respond(mobile) {
                font-size: rem(14);
                line-height: rem(23);
                margin: 0px auto rem(24);
            }
        }

        .explore_product_card {
            display: block;
            height: 100%;
            @include respond(mobile) {
                margin-bottom: rem(16);
            }

            .explore_img {
                display: block;

                img {
                    width: 100% !important;
                    height: auto !important;
                }
            }

            .explore_product_details {
                display: block;
                text-align: center;

                h2 {
                    font-family: "EB Garamond";
                    font-size: rem(24);
                    font-weight: 600;
                    line-height: rem(22);
                    color: #775C3D;
                    margin: rem(33) rem(0) rem(8);
                    text-transform: uppercase;

                    @include respond(mobile) {
                        font-size: rem(11);
                        line-height: rem(11);
                        margin: rem(12) rem(0) rem(5);
                    }
                }

                p {
                    font-family: "Raleway";
                    font-size: rem(16);
                    font-weight: 400;
                    line-height: rem(24);
                    color: #5C5C5C;
                    margin-bottom: rem(26);

                    @include respond(mobile) {
                        font-size: rem(8);
                        line-height: rem(11);
                        margin-bottom: rem(11);
                    }

                }

                .btn_group {
                    display: flex;
                    gap: rem(7);

                    .btn_buyNow {
                        width: 100%;
                        height: rem(55);
                        background: #775C3D;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #FFF;
                        font-family: "Raleway";
                        font-size: rem(16);
                        font-weight: 500;
                        line-height: rem(18);
                        letter-spacing: -0.02em;
                        border: 1px solid #775C3D;
                        text-transform: uppercase;
                        border-radius: rem(2);

                        @include respond(mobile) {
                            border-radius: rem(1.4);
                            height: rem(32);
                            padding: rem(0);
                            font-size: rem(8);
                            line-height: rem(10);
                        }
                    }

                    .btn_learn_more {
                        width: 100%;
                        height: rem(55);
                        background: transparent;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #775C3D;
                        font-family: "Raleway";
                        font-size: rem(16);
                        font-weight: 500;
                        line-height: rem(18);
                        letter-spacing: -0.02em;
                        border: 1px solid #775C3D;
                        text-transform: uppercase;
                        border-radius: rem(2);

                        @include respond(mobile) {
                            border-radius: rem(1.4);
                            height: rem(32);
                            padding: rem(0);
                            font-size: rem(8);
                            line-height: rem(10);
                        }
                    }
                }
            }
        }
    }

}

.row_reverse {
    flex-direction: row-reverse;
}