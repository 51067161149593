.great-grand-section {
  background-color: hsla(40, 4%, 9%, 1);
  padding: rem(80) 0;
}

.section {
  text-align: center;
  @include respond(desktop) {
    padding: rem(40) 0;
  }
  @include respond(ipadMobile) {
    padding: rem(40) 0;
  }
}

.age-verifiation-section {
  min-height: 100vh;
  @include flex-center();
  @media only screen and (min-width: 2560px) {
    padding: 3rem 0;
  }
  @include respond(ipad) {
    align-items: center;
  }
}

.age-verification-card {
  text-align: center;
  .site-logo-wrapper {
    margin-right: 0;
    display: block;
    @include respond(desktop) {
      margin-bottom: rem(30);
    }
    @include respond(ipadMobile) {
      margin-bottom: rem(25);
    }
  }

  .age-verification-heading {
    line-height: 1;
    font-weight: 600;
    font-family: "EB Garamond";
    @include respond(desktop) {
      margin-bottom: rem(30);
      font-size: rem(34);
      background: radial-gradient(
        100% 250.08% at 0 50%,
        #ffd59f 0,
        #ca974a 36.99%,
        #ffcfa4 67.58%,
        #d59255 100%,
        #e19e61 0
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-transform: uppercase;
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(25);
    }
  }

  &__desc {
    font-weight: 500;
    font-family: "Raleway";
    font-style: normal;
    color: #f9f8f7;
    letter-spacing: rem(1);
    @include respond(desktop) {
      margin: rem(25) auto;
      font-size: rem(16);
      max-width: rem(600);
      line-height: rem(22);
    }
    @include respond(ipad) {
      margin: rem(20) auto;
      line-height: 1.4;
      font-size: rem(16);
      max-width: rem(600);
    }
    @include respond(mobile) {
      margin: rem(20) auto;
      line-height: 1.2;
      font-size: rem(14);
    }

    @include respond(mobile) {
      // max-width: rem(214);
    }
  }

  .input-error-wrapper {
    position: relative;
    .site-input-group__error {
      position: absolute;
      bottom: 0;
      left: 50%;
      @include respond(desktop) {
        transform: translateY(15px) translateX(-50%);
        min-width: rem(200);
      }
      @include respond(ipadMobile) {
        transform: translateY(18px) translateX(-50%);
        font-size: rem(11);
      }
    }
  }
  .age-verification-yes-no {
    @include flex-x-center();
    margin-bottom: rem(25);
    overflow: hidden;
    @include respond(ipadMobile) {
      margin-bottom: rem(20);
    }
    label {
      margin-bottom: 0;
    }
    input {
      position: absolute !important;
      height: 0;
      width: 0;
      border: 0;
      display: none;
      overflow: hidden;

      &:checked {
        + label {
          background-color: #382003;
          box-shadow: none;
          color: #ffcfa1;
        }
      }
    }
    .new-btn-primary {
      font-family: "Roboto";
      font-weight: 500;
      letter-spacing: rem(0.5);
      background: rgba(106, 42, 17, 0.35);
      cursor: pointer;
      border-radius: 2.3125rem;
      padding: 1rem 2.8rem;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      font-size: rem(14);
      border: rem(1) solid #f9c467;
    }
  }

  .age-verification-card__details {
    letter-spacing: rem(1);
    font-weight: 400;
    color: #f9f8f7;
    font-family: "Baskerville";
    font-style: italic;
    @include respond(desktop) {
      margin: 0 auto 1.5625rem;
      font-size: rem(18);
      line-height: 1.375rem;
    }
    a {
      color: #f9c467;
      cursor: pointer;
      text-decoration: none;
    }
  }
  .contact-us-form__btn {
    display: inline-block;
    .new-btn-primary {
      letter-spacing: rem(1);
      font-weight: 400;
      font-family: "Raleway";
      font-size: rem(12);
      background: rgba(106, 42, 17, 0.35);
      -webkit-box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.31);
      box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.31);
      border-radius: 2.3125rem;
      display: inline-block;
      padding: 1rem 3rem;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      font-family: libre_baskervillebold;
      border: 1px solid #f9c467;
    }
  }
  .age-verification-locationSelect {
    display: flex;
    margin: rem(50) 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .top-nav__search {
      display: flex;
      justify-content: center;
      align-items: center;
      .css-b62m3t-container {
        min-width: 40%;
      }
    }

    .react-select__control--is-focused {
      background-color: #382003 !important;
      border-color: #382003;
      outline: none;
    }
    .react-select__control--menu-is-open {
      border: none;
      outline: none;
    }
  }
}

.age-verification-locationSelect {
  display: flex;
  margin: rem(50) 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .top-nav__search {
    width: 100%;
    max-width: rem(230);

    .react-select {
      max-width: rem(230);
      .react-select__control {
        background-color: #382003;
      }
      .css-13cymwt-control {
        box-shadow: none !important;
      }
      .css-t3ipsp-control {
        box-shadow: none;
      }
      .css-b62m3t-container {
        max-width: 45%;
        @include respond(mobile) {
          width: 100%;
        }
        border: rem(1) solid hsla(36, 36%, 65%, 1);
        border-radius: rem(3);
        .css-13cymwt-control {
          background-color: #382003;
          border: transparent;
        }
        .css-1fdsijx-ValueContainer {
          padding: 0;
          .css-1dimb5e-singleValue {
            color: hsla(36, 36%, 65%, 1);
            font-size: rem(14);
            font-weight: 500;
            font-family: "Raleway";
            padding-left: rem(10);
            letter-spacing: rem(2);
            @include respond(ipad) {
              font-size: rem(13);
            }
            @include respond(mobile) {
              font-size: rem(12);
            }
          }
        }
      }
      .css-1hb7zxy-IndicatorsContainer {
        .css-1u9des2-indicatorSeparator {
          -webkit-align-self: stretch;
          -ms-flex-item-align: stretch;
          align-self: stretch;
          width: 1px;
          background-color: hsl(0, 0%, 80%);
          margin-bottom: 0;
          margin-top: 0;
          display: none;
        }
        .css-1xc3v61-indicatorContainer {
          .css-tj5bde-Svg {
            fill: hsla(36, 36%, 65%, 1);
            line-height: 1;
            stroke: hsla(36, 36%, 65%, 1);
            stroke-width: 0;
          }
        }
      }
      .css-1jqq78o-placeholder {
        color: hsla(36, 36%, 65%, 1);
        font-size: rem(12);
        font-weight: 500;
        font-family: "Raleway";
        padding-left: rem(10);
      }
      .css-13cymwt-control:hover {
        border-color: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        background-color: #382003;
      }
    }
  }
  .react-select__control--is-focused {
    background-color: #382003 !important;
    border-color: #382003;
    outline: none;
  }
  .react-select__control--menu-is-open {
    border: none;
    outline: none;
    background-color: #382003;
  }
}
.react-select__input-container {
  input {
    color: white !important;
  }
}
.select-placeholder-text {
  color: pink;
}

.opps-modal {
  .modal-header {
    justify-content: flex-end;
    border-bottom: none;
  }
  .section-heading-2 {
    text-align: center;
  }
  .modal-close {
    width: 12px;
    cursor: pointer;
  }

  @include respond(desktop) {
    margin: 0 auto 0 auto;
    max-width: rem(325);
  }

  @include respond(ipadMobile) {
    margin: 0 auto 0 auto;
  }

  .modal-content {
    background: rgba(249, 248, 247, 0.98);
    border-radius: 3px;
    border: none;

    justify-content: center !important;
    max-width: 90%;
    margin-inline: auto;
    @include respond(ipadMobile) {
      max-width: 80%;
      margin-inline: auto;
    }
  }
  .modal-body {
    @include respond(desktop) {
      padding: rem(37) rem(15);
    }
    @include respond(ipadMobile) {
      padding: rem(30) rem(10);
    }
    h3 {
      color: $color-secondary;
      font-size: rem(22);
      margin-bottom: rem(30) !important;
    }
    p {
      color: $color-secondary;
      font-size: rem(18);
      line-height: 22px;
      text-align: center;

      &:not(:last-child) {
        margin-bottom: rem(20);
      }
    }
  }
}
