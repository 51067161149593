.rederHomepage {
  width: 100%;
}

.secondFold-container {
  padding: rem(40) 0;
  max-width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  @include respond(ipad) {
    padding: rem(30) 0;
  }

  @include respond(mobile) {
    padding: rem(20) 0;
  }

  .headingText {
    font-family: "EB Garamond";
    font-size: rem(34);
    font-weight: 700;
    color: hsla(0, 0%, 11%, 1);
    text-transform: uppercase;
    text-align: center;
    margin: 0;

    @include respond(ipad) {
      font-size: rem(25);
      // color: hsla(32, 32%, 35%, 1);
      color: hsla(0, 0%, 11%, 1);
    }

    @include respond(mobile) {
      font-size: rem(20);
      color: hsla(0, 0%, 11%, 1);
      margin: 0;
    }
  }
}

.forthFold-container {
  background-color: $white;
  min-width: 100%;

  .headingText {
    font-family: "EB Garamond";
    font-size: rem(34);
    font-weight: 600;
    color: hsla(0, 0%, 11%, 1);
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    padding: rem(30) 0;

    @include respond(ipadMobile) {
      color: hsla(0, 0%, 11%, 1);
      font-size: rem(20);
      font-weight: 700;
      max-width: 80%;
      margin: auto;
      padding: rem(25) 0;
      letter-spacing: rem(0.5);
    }
  }

  .inner-container {
    .tabcontainer {
      padding: rem(0);
      // background-image: linear-gradient(
      //   to right,
      //   hsla(32, 34%, 34%, 1),
      //   hsla(33, 32%, 56%, 1),
      //   hsla(33, 35%, 32%, 1)
      // );
      // background: rgba(140, 111, 78, 1);
      background: #030104;

      &.tab_our_brand {
        ul {
          li {
            padding: 0px 15px;
          }
          .active {
            border-bottom: 0px;
            background: rgba(255, 255, 255, 0.2);
            padding: 0px 15px;
          }

          div {
            span {
              background: rgba(255, 255, 255, 0.2);

              .active {}
            }
          }
        }
      }

      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        position: relative;

        span {
          position: absolute;
          bottom: rem(0);
          height: rem(10);
          background-color: hsla(32, 59%, 24%, 1);
        }

        .active {
          border-bottom: rem(10) solid hsla(32, 59%, 24%, 1);
        }

        li {
          cursor: pointer;
          height: rem(112);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      @include respond(ipadMobile) {
        // background-color: hsla(33, 32%, 56%, 1);
        background-color: #030104;
        max-width: 100%;
        padding: rem(5) rem(10) rem(5) rem(5);
        gap: rem(5);
        margin: auto;
        display: grid;
        border-radius: rem(4);
        grid-template-columns: 50% 50%;

        .card-item {
          z-index: 2;
          border-radius: rem(4);
          border: rem(1) solid hsla(32, 59%, 24%, 1);
          // background-color: hsla(33, 32%, 56%, 1);
          background-color: #030104;

          text-align: center;
          padding: rem(13) 0;
        }

        .active {
          background-color: hsla(32, 59%, 24%, 1);
        }

        .img1 {
          max-width: rem(120);
          max-height: rem(23);
        }

        .img2 {
          max-width: rem(98);
          max-height: rem(24);
        }

        .img3 {
          max-width: rem(68);
          max-height: rem(31);
        }

        .img4 {
          max-width: rem(80);
          max-height: rem(40);
        }

        .img5 {
          max-width: rem(97);
          max-height: rem(30);
        }

        .img6 {
          max-width: rem(72);
          max-height: rem(13);
        }
      }
    }

    .bottleCard-container {
      padding: rem(160) rem(0) rem(80);

      @include respond(ipadMobile) {
        padding: rem(30) 0;
      }

      .card-wrapper {
        position: relative;

        .learn_more_btn {
          border-radius: rem(1);
          background: #fee698;
          color: #282828;
          font-family: Roboto;
          font-size: rem(13);
          font-weight: 500;
          line-height: normal;
          letter-spacing: 1.69px;
          text-transform: uppercase;
          padding: rem(18);
          text-decoration: none;
          position: absolute;
          bottom: rem(30);
          left: 50%;
          transform: translateX(-50%);
          visibility: hidden;
          max-width: max-content;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .card {
          height: rem(471);
          padding: rem(30) rem(0);
          display: flex;
          flex-basis: 100%;
          flex-grow: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          position: relative;
          overflow: hidden;

          @keyframes slide {
            from {
              transform: translateX(100%);
            }

            to {
              transform: translateX(-100%);
            }
          }

          .textWithAnimation {
            white-space: nowrap;
            overflow: hidden;
            animation: slide 8s linear infinite;
            font-size: 15px;
            color: #000;
            text-align: center;
            color: #e3e3e3;
            text-align: center;
            font-family: Poppins;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: 38.392px;
            position: absolute;
            top: rem(78);
            text-transform: uppercase;
            visibility: hidden;
          }

          @include respond(ipadMobile) {
            flex-grow: 100%;
            flex-basis: 100%;
            overflow-x: auto;
            min-width: rem(145);
            min-height: rem(152);
          }
        }

        img {
          position: absolute;
          bottom: 30px;
          left: 50%;
          transform: translateX(-50%);

          @include respond(desktop) {
            height: rem(400);
          }

          @include respond(ipadMobile) {
            width: rem(52);
            max-height: rem(220);
            right: 0;
          }
        }

        &:hover {
          .learn_more_btn {
            visibility: visible;
          }

          .card {
            border: 1px solid #121212;
            background: #ffffff !important;

            .textWithAnimation {
              visibility: visible;
            }
          }
        }
      }
    }
  }

  .bottleCard-containerMobile {
    display: flex;
    overflow-x: auto;
    row-gap: rem(10);
    margin-left: rem(20);

    @include respond(ipad) {
      justify-content: center;
      margin-left: 0;
    }

    @include respond(ipadMobile) {
      padding: rem(80) 0 rem(50);
    }

    .card-wrapper {
      position: relative;
      min-width: rem(200);
      min-height: rem(153);
      margin-right: rem(5);

      @include respond(ipad) {
        margin-right: rem(20);
      }

      .card {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        min-width: rem(145);
        min-height: rem(253);
      }

      .learn_more_btn {
        border-radius: rem(1);
        background: #fee698;
        color: #282828;
        font-family: Roboto;
        font-size: rem(8);
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.69px;
        text-transform: uppercase;
        padding: rem(5);
        text-decoration: none;
        position: absolute;
        bottom: rem(15);
        left: 50%;
        transform: translateX(-50%);
        visibility: visible;
        width: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        position: absolute;
        // top: rem(-50);
        // width: rem(52);
        height: rem(220);
        // left: 30%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        .card {
          background: #fff !important;
          border: 1px solid #121212;
        }

        .learn_more_btn {
          visibility: visible;
        }
      }
    }
  }
}

.ourstory-heading {
  font-family: "EB Garamond";
  font-size: rem(34);
  font-weight: 600;
  // color: hsla(32, 32%, 35%, 1);
  color: hsla(0, 0%, 11%, 1);
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  padding: rem(30) 0;

  @include respond(mobile) {
    font-size: rem(20);
    font-family: 700;
    letter-spacing: rem(0.5);
    padding: rem(30) rem(0);
  }
}

.fifthFold-container {
  // background-color: hsla(32, 20%, 43%, 1);
  min-width: 100%;
  // padding: rem(20);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include respond(mobile) {
    padding: rem(10) rem(10);
  }

  @include respond(ipad) {
    padding: rem(10) rem(10);
  }

  .innersection {
    // background-color: hsla(270, 13%, 6%, 1);
    padding: rem(70) 0;

    @include respond(ipadMobile) {
      padding: rem(50) 0 rem(40);
    }

    .left-inner-container {
      letter-spacing: rem(0.5);
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background-position: center;
      background-repeat: no-repeat;
      padding: rem(50) 0;

      @include respond(mobile) {}

      .headingText {
        font-family: "EB Garamond";
        font-size: rem(39);
        font-weight: 400;
        color: hsla(46, 98%, 80%, 1);
        text-transform: uppercase;
        text-align: center;
        margin: 0;
        opacity: 0.9;
        max-width: 50%;

        &.title_of_our_story {
          max-width: 100%;
          text-align: center;
          line-height: normal;
          display: grid;

          span {
            font-family: "EB Garamond";
            font-size: rem(39);
            font-weight: 400;
            color: hsla(46, 98%, 80%, 1);
            text-transform: uppercase;
            text-align: center;
            margin: 0;
            opacity: 0.9;

            @include respond(mobile) {
              font-size: rem(26);
              opacity: 1;
              padding-inline: rem(50);
              min-width: 100%;
              margin: auto auto;
            }

            @include respond(ipad) {
              font-size: rem(30);
              min-width: 100%;
            }
          }
        }

        @include respond(mobile) {
          font-size: rem(26);
          opacity: 1;
          padding-inline: rem(50);
          min-width: 100%;
          margin: auto auto;
        }

        @include respond(ipad) {
          font-size: rem(30);
          min-width: 100%;
        }
      }

      span {
        color: hsla(36, 36%, 65%, 1);
        font-family: "Baskerville";
        font-size: rem(21);
        font-weight: 400;
        text-align: center;
        padding: rem(-8) 0 0;
        font-style: italic;
        opacity: 0.8;

        @include respond(mobile) {
          font-size: rem(20);
          letter-spacing: rem(0.5);
        }

        @include respond(ipad) {
          font-size: rem(20);
        }
      }

      .subText {
        letter-spacing: rem(0.5);
        color: hsla(0, 0%, 100%, 1);
        font-family: "Baskerville";
        font-size: rem(18);
        font-weight: 400;
        text-align: center;
        max-width: 75%;
        margin: 0 auto;
        padding: rem(10) 0 rem(20);
        opacity: 0.6;
        line-height: 1.4;

        @include respond(mobile) {
          font-size: rem(14);
          min-width: 100%;
          padding: rem(30) rem(12);
          font-family: "Raleway";
          letter-spacing: rem(0.5);
        }

        @include respond(ipad) {
          font-size: rem(16);
          padding: rem(20) rem(0);
          min-width: 100%;
          letter-spacing: rem(0.5);
          padding: rem(10) 0 0;
          line-height: 1.2;
        }
      }

      .btn {
        color: $white;
        font-family: "Raleway";
        font-size: rem(13);
        font-weight: 600;
        background: #775C3D;
        padding: rem(11) rem(40);
        text-transform: uppercase;
        letter-spacing: rem(0.5);
        border-radius: 0;
        cursor: pointer;

        @include respond(mobile) {}

        @include respond(ipad) {}
      }
    }

    .right-container {
      width: 100%;

      img {
        width: 100%;
      }

      @include respond(ipadMobile) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: rem(10) rem(16) 0;

        img {
          width: 100%;
          height: rem(218);
        }

        .btn {
          color: hsla(0, 0%, 100%, 1);
          font-family: "Raleway";
          font-size: rem(10);
          font-weight: 600;
          background-color: transparent;
          padding: rem(11) rem(30);
          text-transform: uppercase;
          letter-spacing: rem(0.6);
          border-radius: 0;
          border: rem(1) solid hsla(0, 0%, 100%, 1);
          cursor: pointer;
          margin-top: rem(30);
        }
      }

      @include respond(ipad) {
        padding-top: 0;
      }
    }
  }
}

.sixthFold-container {
  background-color: $white;
  min-width: 100%;

  @include respond(desktop) {
    padding: rem(50) rem(50) rem(15);
  }

  @include respond(ipad) {
    padding: rem(80) rem(50) rem(15);
  }

  @include respond(mobile) {
    padding: rem(50) rem(10) 0;
  }

  .headingText {
    font-family: "EB Garamond";
    font-size: rem(34);
    font-weight: 600;
    color: hsla(22, 73%, 3%, 1);
    text-transform: uppercase;
    text-align: center;

    @include respond(ipad) {
      font-size: rem(25);
      font-weight: 700;
    }

    @include respond(mobile) {
      font-size: rem(20);
      font-weight: 700;
    }
  }

  .subText {
    color: hsla(30, 19%, 44%, 1);
    font-family: "Raleway";
    font-size: rem(16);
    font-weight: 500;
    text-align: center;
    max-width: 50%;
    margin: 0 auto;
    padding: rem(10) 0 rem(0);

    @include respond(ipad) {
      font-size: rem(14);
      font-weight: 400;
      min-width: 90%;
    }

    @include respond(mobile) {
      font-size: rem(12);
      font-weight: 400;
      min-width: 90%;
      letter-spacing: rem(0.5);
    }
  }

  .cardContainer {
    display: flex;
    justify-content: space-around;
    padding: rem(30) 0 rem(55);

    @include respond(ipadMobile) {
      padding: rem(10) 0 rem(20);
      margin-bottom: rem(0);
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      overflow-x: auto;
      margin-left: rem(10);
    }

    .card {
      background-color: hsl(39deg 33.32% 96.68%);

      @include respond(desktop) {
        padding: rem(41) rem(30);
        margin-inline: rem(3);
        display: flex;
        flex-basis: 20%;
        flex-grow: 20%;
        min-height: rem(280);
        border: none;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      @include respond(ipadMobile) {
        min-height: rem(206);
        min-width: rem(206);
        display: flex;
        padding: rem(41) rem(30);
        margin-inline: rem(3);
        margin-bottom: rem(20);
        flex-basis: 40%;
        flex-grow: 2;
      }
    }
  }

  .btncontainer {
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond(ipadMobile) {
      margin: rem(25) 0;
    }

    .btn {
      color: hsla(0, 0%, 100%, 1);
      font-family: "Roboto";
      font-size: rem(13);
      font-weight: 500;
      background-color: hsla(32, 20%, 43%, 1);
      padding: rem(11) rem(40);
      text-transform: uppercase;
      letter-spacing: rem(0.5);
      border-radius: 0;
      cursor: pointer;

      @include respond(ipad) {
        padding: rem(14) rem(50);
      }

      @include respond(mobile) {
        padding: rem(11) rem(30);
        border-radius: rem(0);
        font-size: rem(10);
        color: hsla(32, 20%, 43%, 1);
        background-color: transparent;
        border: rem(1) solid hsla(32, 20%, 43%, 1);
      }
    }
  }
}

.seventhFold-container {
  background-color: $white;
  min-width: 100%;

  padding: rem(0) rem(150) rem(135);

  @include respond(ipad) {
    padding: rem(30) 0;
    display: none;
  }

  @include respond(mobile) {
    padding: rem(10) 0;
    display: none;
  }

  .headingText {
    font-family: "EB Garamond";
    font-size: rem(34);
    font-weight: 600;
    color: hsla(0, 0%, 11%, 1);
    text-transform: uppercase;
    text-align: center;
    padding: rem(40) 0 rem(20);
    letter-spacing: rem(0.7);

    @include respond(ipad) {
      font-size: rem(27);
      font-weight: 600;
    }

    @include respond(mobile) {
      font-size: rem(20);
      font-weight: 700;
      padding: rem(20) 0 rem(5);
    }
  }

  .leftcard {
    @include respond(desktop) {
      min-height: rem(225);
      margin: rem(10) 0;
    }

    @include respond(ipad) {
      height: 100%;
    }

    @include respond(mobile) {
      width: rem(115);
      height: rem(115);
    }
  }

  .rightcard {
    margin: rem(10) 0 0;
    // padding: 11.6rem 0;
    min-height: rem(466);
    background-color: rgb(57, 52, 52, 0.1);

    @include respond(ipad) {}

    @include respond(mobile) {
      margin: 0;
      padding: rem(50) 0;
      min-width: 100%;
    }

    .textwrapper {
      display: flex;
      color: transparent;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-height: 100%;
      padding: 11.6rem 0;
      background-color: rgb(0, 0, 0, 0.1);

      @include respond(ipad) {}

      @include respond(mobile) {}
    }
  }

  .socialmedia-headingText {
    font-family: "EB Garamond";
    font-size: rem(41);
    font-weight: 600;
    color: hsla(40, 23%, 97%, 1);
    text-transform: uppercase;
    text-align: center;
    letter-spacing: rem(0.2);

    @include respond(ipad) {}

    @include respond(mobile) {}
  }

  p {
    letter-spacing: rem(0.6);
    font-family: "Raleway";
    font-size: rem(13);
    font-weight: 400;
    color: hsla(40, 23%, 97%, 1);
    padding-bottom: 0;
    text-align: center;
  }
}

.seventhFold-containerMobile {
  @include respond(desktop) {
    display: none;
  }

  @include respond(ipadMobile) {
    padding: rem(0) 0 rem(40);

    .headingText {
      font-family: "EB Garamond";
      font-weight: 700;
      color: hsla(0, 0%, 9%, 1);
      text-transform: uppercase;
      text-align: center;
      font-size: rem(20);
      font-weight: 700;
      padding: rem(30) 0;
      letter-spacing: rem(0.7);
      margin-bottom: 0;
    }

    .rightcard {
      margin: rem(5) 0;
      @include bgCover();

      @include respond(ipadMobile) {
        margin: 0;
        padding: rem(120) 0;
        min-width: 100%;
      }

      .textwrapper {
        display: flex;
        color: transparent;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .socialmedia-headingText {
          font-family: "EB Garamond";
          font-size: rem(26);
          font-weight: 600;
          color: hsla(40, 23%, 97%, 1);
          text-transform: uppercase;
          text-align: center;
          letter-spacing: rem(0.6);
        }

        p {
          letter-spacing: rem(0.6);
          font-family: "Raleway";
          font-size: rem(13);
          font-weight: 400;
          color: hsla(40, 23%, 97%, 1);
          padding-bottom: 0;
          text-align: center;
        }
      }
    }

    .leftcard {
      padding: rem(20);
      margin: rem(3) 0;
      @include bgCover();

      @include respond(mobile) {
        height: rem(115);
        width: rem(125);
      }

      @include respond(ipad) {
        height: rem(200);
        width: 32%;
        margin: rem(9) 0;
      }
    }

    .cardwrapper-responsive {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}

.eightfold-container {
  background-color: hsla(32, 18%, 42%, 1);

  @include respond(desktop) {
    padding: rem(50) rem(170) rem(135);
    width: 100%;
  }

  @include respond(ipad) {
    padding: rem(50) rem(30);
  }

  @include respond(mobile) {
    padding: rem(0);
    background-color: hsla(29, 26%, 50%, 1);
    letter-spacing: rem(0.7);
  }

  .headingText {
    font-family: "EB Garamond";
    font-size: rem(40);
    font-weight: 700;
    color: hsla(0, 0%, 100%, 1);
    text-transform: uppercase;
    text-align: center;
    padding: rem(30) 0 rem(50);
    margin-bottom: 0;

    letter-spacing: rem(0.7);

    @include respond(ipad) {
      padding: rem(0) 0 rem(30);
      font-size: rem(30);
    }

    @include respond(mobile) {
      padding: rem(30) 0 rem(20);
      font-size: rem(20);
    }
  }

  .card {
    background-position: center;
    padding: rem(120) rem(150) rem(100) rem(120);
    letter-spacing: rem(0.6);
    font-family: "EB Garamond";
    font-size: rem(32);
    font-weight: 400;
    color: hsla(0, 0%, 100%, 1);
    @include bgCover();
    text-transform: uppercase;
    margin: 10px 0;

    @include respond(ipad) {
      display: flex;
      padding: rem(50) rem(30);
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      font-size: rem(26);
      line-height: 1.2;
      margin: 10px 0;
    }

    @include respond(mobile) {
      display: flex;
      padding: rem(60);
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      font-size: rem(26);
      font-weight: 500;
      margin: rem(7) 0;
    }

    h3 {
      padding-bottom: rem(20);
      opacity: 0.9;
      line-height: 1.2;

      @include respond(ipad) {
        padding-bottom: rem(20);
      }

      @include respond(mobile) {
        padding-bottom: rem(15);
        font-size: rem(26);
        text-align: left;
        opacity: 0.9;
      }
    }

    span {
      font-family: "Roboto";
      font-size: rem(16);
      font-weight: 500;
      padding: rem(11) rem(24);
      border: rem(1) solid white;
      max-width: rem(145);
      border-radius: rem(3);
      text-align: center;
      cursor: pointer;

      @include respond(ipad) {
        padding: rem(13) rem(24);
        font-size: rem(13);
        font-weight: 600;
      }

      @include respond(mobile) {
        font-weight: 600;
        text-align: center;
        padding: rem(10) rem(24);
        font-size: rem(10);
        font-family: "Raleway";
      }
    }
  }
}

.ninthFoldContainer {
  letter-spacing: rem(0.6);
  // background-image: linear-gradient(to right,
  //     hsla(32, 34%, 34%, 1),
  //     hsla(33, 32%, 56%, 1),
  //     hsla(33, 35%, 32%, 1));
  // background: rgba(23, 23, 22, 0.8);

  background-image: url("/images/sign-up-bg.png");
  @include bgCover();
  padding: rem(45) rem(160);
  border-bottom: rem(1) solid rgba(119, 92, 61, 1);

  @include respond(ipad) {
    padding: rem(35) 0;
  }

  @include respond(mobile) {
    padding: rem(25) 0;
  }

  .headingText {
    font-family: "EB Garamond";
    font-size: rem(32);
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    line-height: 1.3;
    letter-spacing: rem(0.5);
    text-transform: uppercase;
    padding-right: rem(50);

    @include respond(ipad) {
      font-size: rem(24);
      font-weight: 600;
    }

    @include respond(mobile) {
      font-size: rem(18);
      font-weight: 500;
      padding-inline: rem(30);
      text-align: center;
    }
  }

  .row {
    align-items: center;

    .inputfield {
      @include respond(mobile) {
        padding-top: rem(20);
      }
    }

    .inputwrapper {
      position: relative;
      outline: none;
      border: none;

      @include respond(ipad) {
        padding-inline: rem(10);
      }

      @include respond(mobile) {
        padding-inline: rem(10);
      }

      span,
      button {
        position: absolute;
        font-family: "Raleway";
        font-size: rem(16);
        font-weight: 600;
        // color: rgb(181, 152, 93);
        color: rgba(50, 50, 50, 1);
        text-decoration: underline;
        right: 5%;
        top: rem(20);
        cursor: pointer;
        border: none;
        background: transparent;
        letter-spacing: rem(1);

        @include respond(ipad) {
          font-size: rem(14);
          font-weight: 400;
          right: 5%;
          top: rem(15);
        }

        @include respond(mobile) {
          font-size: rem(12);
          font-weight: 400;
          right: 6%;
          top: rem(12);
        }
      }

      form {
        .mb-3 {
          margin-bottom: 0 !important;

          .col-sm-10 {
            width: 100%;

            .form-control {
              padding: rem(20) rem(32);
              font-size: rem(16);
              font-family: "Raleway";
              font-weight: 500;
              color:
                #323232;
              letter-spacing: rem(0.5);

              @include respond(ipad) {
                padding: rem(15);
              }

              @include respond(mobile) {
                padding: rem(8) rem(15);
              }
            }

            .form-control:focus {
              box-shadow: none;
            }

            .form-control::placeholder {
              color: rgba(50, 50, 50, 1);
              font-family: "Raleway";
              letter-spacing: rem(1);
              font-size: rem(16);
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.onlyMobile {
  @include respond(desktop) {
    display: none !important;
  }
}

.onlyDesktop {
  @include respond(ipadMobile) {
    display: none !important;
  }
}

.videoplay-container {
  @include respond(desktop) {
    background-image: linear-gradient(hsla(45, 13%, 6%, 1),
        hsla(45, 13%, 6%, 0.9),
        hsla(45, 13%, 6%, 1));
  }

  @include respond(ipadMobile) {
    margin-top: rem(50);
  }
}