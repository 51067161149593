// common classes
@use "sass:math";

@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-x-center() {
  display: flex !important;
  justify-content: center;
}

@mixin flex-between() {
  display: flex;
  justify-content: space-between;
}

@mixin flex-end() {
  display: flex;
  justify-content: flex-end;
}

@mixin flex-y-center() {
  display: flex;
  align-items: center;
}

@mixin flex-column-end() {
  align-items: flex-end;
  @include flex-column();
}

@mixin flex-column() {
  display: flex;
  flex-direction: column;
}

@mixin flex-column-center() {
  @include flex-x-center();
  flex-direction: column;
}

@mixin flex-space-between() {
  @include flex-column-end();
  justify-content: space-between;
}
$em-base-mobile: 14;
@function remMobile($size-mobile, $base-mobile: $em-base-mobile) {
  $remSizeMobile: $size-mobile / $em-base-mobile;
  @return #{$remSizeMobile}rem;
}
@mixin respond($breakpoint) {
  @if $breakpoint==smalldesktop {
    @media only screen and (min-width: 992px) and (max-width: 1400px) {
      @content;
    }
  }

  @if $breakpoint==mediumdesktop {
    @media only screen and (min-width: 992px) and (max-width: 1919px) {
      @content;
    }
  }

  @if $breakpoint==largedesktop {
    @media only screen and (min-width: 1920px) {
      @content;
    }
  }

  @if $breakpoint==laptopdesktop {
    @media only screen and (max-width: 1920px) {
      @content;
    }
  }

  @if $breakpoint==extralargedesktop {
    @media only screen and (min-width: 2400px) {
      @content;
    }
  }

  @if $breakpoint==desktop {
    @media only screen and (min-width: 992px) {
      @content;
    }
  }

  @if $breakpoint==ipad {
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      @content;
    }
  }

  @if $breakpoint==desktopIpad {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }

  @if $breakpoint==ipadProMobile {
    @media only screen and (max-width: 1120px) {
      @content;
    }
  }

  @if $breakpoint==ipadPro {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint==ipadMobile {
    @media only screen and (max-width: 992px) {
      @content;
    }
  }

  @if $breakpoint==mobile {
    @media only screen and (max-width: 767px) {
      @content;
    }
  }

  @if $breakpoint==mediumMobile {
    @media only screen and (max-width: 540px) {
      @content;
    }
  }

  @if $breakpoint==smallmobile {
    @media only screen and (max-width: 360px) {
      @content;
    }
  }
}

$em-base: 16;

//functions
@function rem($size, $base: $em-base) {
  $remSize: math.div($size, $em-base);
  @return #{$remSize}rem;
}

@mixin bgNoRepeatCenter() {
  background-position: center;
  background-repeat: no-repeat;
}

@mixin bgCover() {
  background-size: cover;
  @include bgNoRepeatCenter();
}

@mixin bgContain() {
  background-size: contain;
  @include bgNoRepeatCenter();
}

@mixin text-truncate-1() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin inputStyle() {
  min-height: 65px;
  border-radius: 5px;
  background-color: transparent !important;
  border: 1px solid #d9dce1 !important;
  padding-top: 1em !important;
  padding-bottom: 0.375rem !important;
  font-size: 1.143rem !important;
  font-weight: 500;
  color: $dark-text !important;
  padding-left: 1.43rem;
  padding-right: 1.43rem;

  @include respond(mobile) {
    min-height: 45px;
    font-size: 1rem !important;
  }
}
@mixin text-truncate-multipline() {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin text-truncate-3() {
  @include text-truncate-multipline();
  -webkit-line-clamp: 3;
}

@mixin text-truncate-2() {
  @include text-truncate-multipline();
  -webkit-line-clamp: 2;
}

// btn mixin
// usage :- @include custom-btn($white, $color-primary, 1px solid $color-primary);
@mixin custom-btn($btn-color, $btn-background, $btn-border) {
  border: $btn-border;
  color: $btn-color;
  background-color: $btn-background;
}

// position
@mixin posFixed() {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
}

@mixin rowTopMargin() {
  @include respond(desktopIpad) {
    margin-top: rem(40);
  }
  @include respond(mobile) {
    margin-top: rem(20);
  }
}
@mixin cardStyle() {
  @include respond(desktopIpad) {
    padding: rem(30);
    // box-shadow: $shadow;
    border-radius: rem(10);
    background-color: $white;
    border: 0.5px solid $border-color;
  }
  @include respond(mobile) {
    border-radius: rem(20);
  }
}

@mixin text-truncate-1() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin text-truncate-multipline() {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin text-truncate-3() {
  @include text-truncate-multipline();
  -webkit-line-clamp: 3;
}