.cookie-container {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100vh;
  z-index: 100;
  @include flex-center();
  background-color: hsla(0, 0%, 0%, 0.7);
  display: none;
  &.open {
    display: flex;
  }
  .cookie-card {
    background-color: #ffffff;
    width: rem(550);
    padding: rem(40) rem(25);
    text-align: center;
    position: relative;
    @include respond(mobile) {
      width: rem(350);

      padding: rem(30) rem(20);
    }
    @include respond(ipad) {
      width: rem(500);

      padding: rem(30) rem(20);
    }

    .close-btn {
      position: absolute;
      top: rem(25);
      right: rem(25);
      cursor: pointer;
      @include respond(ipadMobile) {
        top: rem(15);
        right: rem(15);
      }
    }
    h3,
    p {
      margin-bottom: 0;
    }
    .top-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      h3 {
        font-size: rem(25);
        line-height: 1;
        font-family: "Poppins";
        font-weight: 800;
        // background: radial-gradient(circle, #b3936d 25%, #6e5435,#735839);
        background: #171717;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-transform: uppercase;
        padding-bottom: rem(20);
        @include respond(ipadMobile) {
          font-size: rem(20);
          font-weight: 600;
        }
      }
      p {
        font-size: rem(14);
        line-height: 1.5;
        color: #121212;
        font-family: "Poppins";
        font-weight: 300;
        letter-spacing: rem(0.3);

        @include respond(ipadMobile) {
          font-size: rem(12);
          font-weight: 300;
        }
      }
      .input-error-wrapper {
        position: relative;
        padding-top: rem(15);
        .site-input-group__error {
          position: absolute;
          bottom: 0;
          left: 50%;
          @include respond(desktop) {
            transform: translateY(15px) translateX(-50%);
            min-width: rem(200);
          }
          @include respond(ipadMobile) {
            transform: translateY(10px) translateX(-50%);
            font-size: rem(12);
          }
        }
      }
      .age-verification-yes-no {
        @include flex-x-center();
        margin-bottom: rem(20);
        overflow: hidden;
        @include respond(ipadMobile) {
          margin-bottom: rem(20);
        }
        label {
          margin-bottom: 0;
        }
        input {
          position: absolute !important;
          height: 0;
          width: 0;
          border: 0;
          display: none;
          overflow: hidden;

          &:checked {
            + label {
              background-color: #382003;
              box-shadow: none;
              color: #ffcfa1;
            }
          }
        }
        .new-btn-primary {
          min-width: rem(120);
          font-family: "Raleway";
          font-weight: 500;
          letter-spacing: rem(0.5);
          background: transparent;
          cursor: pointer;
          border-radius: rem(4);
          padding: rem(8) rem(9);
          color: #775c3d;
          text-align: center;
          text-transform: uppercase;
          font-size: rem(14);
          border: rem(0.5) solid #775c3d;
        }
      }
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: rem(0.5);
        background-image: radial-gradient(
          circle,
          #775c3d 0%,
          #ffffff 100%,
          #775c3d 0%
        );
        bottom: rem(0);
      }
    }
    .middle-section {
      padding-top: rem(15);
      h3 {
        font-size: rem(14);
        line-height: 1.6;
        color: #121212;
        font-family: "Poppins";
        font-weight: 400;
        letter-spacing: rem(0.5);
        opacity: 0.95;
        padding-bottom: rem(15);
        @include respond(ipadMobile) {
          font-size: rem(12);
          font-weight: 300;
          letter-spacing: rem(0.2);
          opacity: 1;
        }
      }
      .country-dropdown {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 2;
        flex-basis: 80%;
        gap: rem(10);
        .top-nav__search {
          display: flex;
          flex-basis: 50%;
          @include respond(ipadMobile) {
            flex-basis: 63%;
          }
          .react-select {
            min-width: 100%;
            max-width: rem(230);
            .css-1u9des2-indicatorSeparator {
              display: none;
            }
            .css-tj5bde-Svg {
              fill: #775c3d;
            }
            .css-qbdosj-Input {
              color: #775c3d;
              font-weight: 400;
              font-size: rem(14);
              font-family: "Raleway";
            }
            .react-select__input-container {
              input {
                color: #775c3d !important;
              }
            }
            .css-qbdosj-Input::after {
              display: none !important;
            }
            .react-select__control--menu-is-open {
              border: rem(0.5) solid #775c3d;
              box-shadow: none;

              outline: none;
            }
            .react-select__control--is-focused {
              border: rem(0.5) solid #775c3d;
              box-shadow: none;
              &:hover {
                border: rem(0.5) solid #775c3d;
                box-shadow: none;
              }
            }
            .css-13cymwt-control:hover {
              border: rem(0.5) solid #775c3d;

              box-shadow: none;
            }
            .css-13cymwt-control {
              border: rem(0.5) solid #775c3d;

              box-shadow: none;
            }
            .css-1dimb5e-singleValue {
              color: #775c3d;
              font-size: rem(14);
              font-family: "Raleway";
              font-weight: 400;
              letter-spacing: rem(2);
              @include respond(ipad) {
                font-size: rem(13);
              }
              @include respond(mobile) {
                font-size: rem(12);
              }
            }
            .css-8mmkcg {
              fill: #775c3d;
              stroke: #775c3d;
            }
            .css-1u9des2-indicatorSeparator {
              display: none;
            }
          }
        }
        .new-btn-primary {
          letter-spacing: rem(1);
          font-weight: 500;
          font-family: "Raleway";
          font-size: rem(14);
          // background: #775c3d;
          background: #171717;
          border: none;
          border-radius: rem(4);
          display: inline-block;
          padding: rem(9) rem(18);
          color: #fff;
          text-align: center;
          text-transform: uppercase;
        }
      }
    }
    .lower-section {
      padding-top: rem(15);
      font-size: rem(12);
      line-height: 1.5;
      color: #000000;
      font-family: "Poppins";
      font-weight: 300;
      letter-spacing: rem(0.6);
      // opacity: 0.8;
      padding-inline: rem(5);
      @include respond(ipadMobile) {
        font-size: rem(12);
        padding-inline: 0;
      }
    }
  }
}
.css-qbdosj-Input::after {
  content: "" !important;
  visibility: hidden;
  white-space: none;
  grid-area: 1/2;
  font: inherit;
  min-width: 0px;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: rem(5);
  height: 15px;
}
::-webkit-scrollbar-track-piece {
  background-color: white;
}
::-webkit-scrollbar-thumb:vertical {
  height: 30px;
  background-color: #775c3d;
}
.custom-info-window {
  display: flex;
  justify-content: center;
  gap: rem(15);
}
.gm-style-iw-t {
  background-color: red;
}
.gm-style-iw,
.gm-style-iw-c {
  background-color: #181818 !important;
}
.gm-style,
.gm-style-iw-c {
  overflow: hidden;
}
.gm-style-iw-d {
  overflow: hidden !important;
  padding-bottom: rem(10) !important;
  // min-width: rem(500);
}
.gm-ui-hover-effect > span {
  background-color: white;
}
.shop-name {
  text-transform: uppercase;
  font-weight: 500;
  font-size: rem(16);
  color: hsl(0, 0%, 100%);
  font-family: "EB Garamond";
  margin-bottom: 0;
}
.new-address-card__desc {
  color: hsl(0, 0%, 77%);
  font-size: rem(14);
  font-weight: 400;
  font-family: "Raleway";
  letter-spacing: 0.0375rem;
  text-align: left;
  line-height: 1.2;
}
.address-card {
  img {
    max-width: rem(125);
    max-height: rem(125);
  }
  padding-right: rem(15);
  .address-card__text {
    // min-width: rem(200);
  }
}
.shop-actions {
  a {
    span {
      color: hsl(0, 0%, 77%);
      font-size: 0.9375rem;
      font-weight: 400;
      font-family: "Raleway";
      letter-spacing: 0.0375rem;
    }
  }
}
.gm-style-iw,
.gm-style-iw-c {
  @include respond(ipadMobile) {
    min-width: rem(350) !important;
  }
}
