.blackbull-collection-main {
  .blackbull-collection-socialmediawrapper {
    max-width: 96%;
    margin: 0 auto rem(50);
    @include respond(mobile) {
      max-width: 100%;
      margin: 0 auto rem(0);
    }
    .seventhFold-container {
      background-color: hsla(29, 26%, 50%, 1);
      padding: rem(0) rem(150) rem(60);
      @include respond(ipad) {
        padding: rem(30) 0;
        display: none;
      }
      @include respond(mobile) {
        padding: rem(10) 0;
        display: none;
      }
      .headingText {
        font-family: "EB Garamond";
        font-size: rem(34);
        font-weight: 600;
        color: hsla(0, 0%, 100%, 1);
        text-transform: uppercase;
        text-align: center;
        padding: rem(40) 0 rem(20);
        letter-spacing: rem(0.7);
        @include respond(ipad) {
          font-size: rem(27);
          font-weight: 600;
          padding: rem(40) 0 rem(40);
        }
        @include respond(mobile) {
          font-size: rem(20);
          font-weight: 700;
          padding: rem(20) 0 rem(40);
        }
      }
      .leftcard {
        @include respond(desktop) {
          min-height: rem(225);
          margin: rem(10) 0;
        }
        @include respond(ipad) {
          height: 100%;
        }
        @include respond(mobile) {
          width: rem(115);
          height: rem(115);
        }
      }
      .rightcard {
        margin: rem(10) 0 0;
        min-height: rem(466);
        background-color: rgb(57, 52, 52, 0.1);
        @include respond(ipad) {
        }
        @include respond(mobile) {
          margin: 0;
          padding: rem(50) 0;
          min-width: 100%;
        }
        .textwrapper {
          display: flex;
          color: transparent;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          min-height: 100%;
          padding: 11.6rem 0;
          background-color: rgb(0, 0, 0, 0.1);

          @include respond(ipad) {
          }
          @include respond(mobile) {
          }
        }
      }
      .socialmedia-headingText {
        font-family: "EB Garamond";
        font-size: rem(41);
        font-weight: 600;
        color: hsla(40, 23%, 97%, 1);
        text-transform: uppercase;
        text-align: center;
        letter-spacing: rem(0.2);
        @include respond(ipad) {
        }
        @include respond(mobile) {
        }
      }
      p {
        letter-spacing: rem(0.6);
        font-family: "Raleway";
        font-size: rem(13);
        font-weight: 400;
        color: hsla(40, 23%, 97%, 1);
        padding-bottom: 0;
        text-align: center;
        @include respond(ipad) {
        }
        @include respond(mobile) {
        }
      }
    }
    .seventhFold-containerMobile {
      @include respond(desktop) {
        display: none;
      }
      @include respond(mobile) {
        padding: rem(0) 0 rem(40);
        .headingText {
          font-family: "EB Garamond";
          font-weight: 600;
          color: hsla(0, 0%, 11%, 1);
          text-transform: uppercase;
          text-align: center;
          font-size: rem(20);
          font-weight: 700;
          padding: rem(20) 0 rem(15);
          letter-spacing: rem(0.7);
        }
        .rightcard {
          margin: rem(5) 0;
          @include bgCover();

          @include respond(mobile) {
            margin: 0;
            padding: rem(120) 0;
            min-width: 100%;
          }
          .textwrapper {
            display: flex;
            color: transparent;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .socialmedia-headingText {
              font-family: "EB Garamond";
              font-size: rem(26);
              font-weight: 600;
              color: hsla(40, 23%, 97%, 1);
              text-transform: uppercase;
              text-align: center;
              letter-spacing: rem(0.6);
            }
            p {
              letter-spacing: rem(0.6);
              font-family: "Raleway";
              font-size: rem(13);
              font-weight: 400;
              color: hsla(40, 23%, 97%, 1);
              padding-bottom: 0;
              text-align: center;
            }
          }
        }
        .leftcard {
          padding: rem(20);
          height: rem(115);
          width: rem(115);
          margin: rem(10) 0;
          @include bgCover();
        }
      }
    }
  }
  .about-collection-container {
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: rem(0.5);
    text-align: center;
    padding-top: rem(60);
    @include respond(mobile) {
      letter-spacing: rem(0.2);
    }
    .top-text {
      color: hsla(0, 0%, 37%, 1);
      font-size: rem(24);
      font-weight: 400;
      font-family: "Raleway";
      padding-bottom: rem(10);
      @include respond(mobile) {
        color: hsla(0, 0%, 9%, 1);
        font-size: rem(20);
        font-style: italic;
        font-weight: 400;
        font-family: "Baskerville";
        padding-bottom: rem(20);
      }
    }
    .headingText {
      color: hsla(32, 12%, 25%, 1);
      font-size: rem(63);
      font-weight: 400;
      font-family: "EB Garamond";
      line-height: 1;
      margin-bottom: 0;
      padding-bottom: rem(30);
      @include respond(desktop) {
        max-width: rem(550);
        margin-inline: auto;
      }

      @include respond(mobile) {
        font-size: rem(26);
        padding-inline: rem(50);
        padding-bottom: rem(20);
      }
    }
    img{
      padding-bottom: rem(10);
    }
    .description {
      color: hsla(0, 0%, 37%, 1);
      font-size: rem(16);
      font-weight: 400;
      font-family: "Raleway";
      max-width: rem(650);
      margin: 0 auto;
      @include respond(mobile) {
        color: hsla(32, 59%, 24%, 1);
        font-size: rem(14);
        letter-spacing: rem(0.5);
        max-width: 90%;
      }
    }
  }

  .kyloerange-container {
    width: 80%;
    margin-inline: auto;
    padding: rem(50) 0 rem(25);

    @include respond(mobile) {
      width: 100%;
      padding: rem(40) 0;
    }
    .headingText {
      color: hsla(0, 0%, 11%, 1);
      font-size: rem(34);
      font-weight: 600;
      letter-spacing: rem(0.5);
      padding-bottom: rem(20);
      @include respond(mobile) {
        font-family: "EB Garamond";
        color: hsla(32, 32%, 35%, 1);
        font-size: rem(20);
        font-weight: 700;
        padding-bottom: rem(10);
      }
    }
    .imagewrapper {
      min-height: rem(600);
      text-align: center;
      width: 100%;
      @include bgCover();
      position: relative;
      padding: rem(50) 0 rem(120);
      @include respond(mobile) {
        min-height: rem(425);
        padding: rem(30) 0 rem(30);
        margin-top: rem(20);
      }
      .blackbull-logo {
        svg {
          @include respond(mobile) {
            max-width: rem(170);
            max-height: rem(35);
            text-align: center;
          }
        }
      }
      .bottle {
        position: absolute;
        // max-width: rem(152);
        max-height: rem(481);
        // left: 37%;
        // top: 11%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include respond(mobile) {
          // max-width: rem(100);
          max-height: rem(300);
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .bottom-logo {
        position: absolute;
        left: 32%;
        max-width: rem(225);
        max-height: rem(135);
        bottom: 14%;
        @include respond(mobile) {
          svg {
            left: 32%;
            max-width: rem(140);
            max-height: rem(85);
          }
          bottom: 10%;
        }
      }
    }
    .learnmorebtn {
      border: rem(1) solid hsla(32, 20%, 43%, 1);
      border-radius: rem(1);
      padding: rem(18) rem(50);
      color: hsla(32, 35%, 40%, 1);
      font-weight: 500;
      font-size: rem(13);
      font-family: "Roboto";
      margin-top: rem(20);
      @include respond(mobile) {
        font-size: rem(10);
        padding: rem(14) rem(30);
      }
    }
  }

  .blackbull12-headingtext {
    color: hsla(0, 0%, 11%, 1);
    font-size: rem(34);
    font-weight: 600;
    font-family: "EB Garamond";
    margin-bottom: 0;
    text-transform: uppercase;
    padding: rem(10) 0 rem(20);
    @include respond(mobile) {
      color: hsla(32, 32%, 35%, 1);
      font-size: rem(20);
      font-weight: 700;
      letter-spacing: rem(0.3);
    }
  }

  .blackbull-twelve-container {
    width: 96%;
    margin: 0 auto rem(80);
    padding: rem(160) 0 rem(160);
    position: relative;

    @include respond(mobile) {
      text-align: center;
      width: 96%;
      padding: rem(40) 0 rem(430);
      @include bgCover();
      margin: 0 auto rem(40);
    }
    @include bgCover();
    .left-innerwrapper {
      max-width: 45%;
      padding-left: rem(130);
      @include respond(mobile) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 96%;
        padding-left: rem(0);
        margin: auto;
      }
      .headingwrapper {
        display: flex;
        align-items: center;
        @include respond(mobile) {
          justify-content: center;
        }
        span {
          background-color: hsla(27, 33%, 48%, 1);
          padding: rem(22) rem(16);
          @include respond(mobile) {
            padding: rem(10.5) rem(8);
            margin-left: rem(5);
            svg {
              max-width: rem(34);
              max-height: rem(27);
            }
          }
        }
      }
      .headingText {
        color: hsla(39, 63%, 76%, 1);
        font-size: rem(63);
        font-weight: 400;
        font-family: "EB Garamond";
        text-transform: uppercase;
        line-height: 1;
        margin-bottom: 0;
        max-width: 50%;
        @include respond(mobile) {
          max-width: 40%;
          margin-right: rem(5);
          font-size: rem(26);
          //   font-weight: 500;
          //   padding-top: rem(25);
        }
      }
      .years-text {
        color: hsla(0, 0%, 100%, 1);
        font-size: rem(24);
        font-weight: 400;
        text-transform: uppercase;
        font-family: "Raleway";
        padding: rem(30) 0 0;
        margin: 0;
        @include respond(mobile) {
          color: hsla(36, 36%, 65%, 1);
          font-size: rem(20);
          padding: rem(20) 0 0;
        }
      }
      .description-text {
        color: hsla(0, 3%, 86%, 1);
        font-size: rem(18);
        font-weight: 400;
        font-family: "Raleway";
        margin-bottom: 0;
        padding: rem(20) rem(50) rem(70) 0;
        letter-spacing: rem(0.5);
        @include respond(mobile) {
          padding: rem(20) rem(22);
          font-size: rem(14);
          color: hsla(0, 3%, 86%, 1);
        }
      }
      .btn {
        padding: rem(18) rem(48);
        background-color: hsla(0, 0%, 100%, 1);
        border-radius: rem(1);
        color: hsla(5, 42%, 6%, 1);
        font-size: rem(13);
        font-weight: 500;
        font-family: "Roboto";
        @include respond(mobile) {
          position: absolute;
          color: hsla(0, 0%, 100%, 1);
          font-weight: 500;
          background-color: transparent;
          border: rem(1) solid hsla(0, 0%, 100%, 1);
          font-size: rem(10);
          padding: rem(13) rem(30);
          font-family: "Raleway";
          border-radius: rem(1);
          letter-spacing: rem(0.3);
          bottom: rem(20);
          text-align: center;
        }
      }
    }
    .bottle12image {
      position: absolute;
      right: 20%;
      bottom: 10%;
      max-width: rem(205);
      @include respond(mobile) {
        max-width: rem(102);
        max-height: rem(320);
        right: 36%;
        bottom: 13%;
      }
    }
  }

  .taleofTwo-legends-container {
    max-width: 96%;
    margin-inline: auto;
    @include bgCover();
    display: flex;
    position: relative;
    @include respond(mobile) {
      width: 100%;
      margin-top: rem(40);
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .two-legends {
        position: absolute;
        text-align: center;
        top: rem(20);
        right: 32%;
        svg {
          max-width: rem(150);
          max-height: rem(76);
        }
      }
      .left-innercontainer {
        position: absolute;
        bottom: rem(20);
        display: flex;
        align-items: center;
        right: 28%;
        .blackbull-logo {
          svg {
            max-width: rem(92);
            max-height: rem(20);
          }
        }
        .faldo {
          padding-left: rem(20);
          svg {
            max-width: rem(76);
            max-height: rem(48);
          }
        }
      }
    }
    .left-container {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      @include respond(mobile) {
        position: absolute;
        bottom: 0;
      }
      .left-innercontainer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: -60%;
          transform: translateY(-50%) translateX(0px);
          height: rem(85);
          width: rem(1);
          opacity: 0.8;
          background-color: hsla(42, 68%, 71%, 1);
          @include respond(mobile) {
            display: none;
          }
        }
        .blackbull-logo {
          padding-right: rem(15);
          @include respond(mobile) {
          }
        }
      }
    }
    .right-container {
      max-width: 50%;
      @include bgCover();
      @include respond(mobile) {
        height: rem(400);
        min-width: 50%;
      }
      img {
        width: 100%;
        @include respond(mobile) {
          height: 100%;
        }
      }
    }
    .blackbull-50 {
      position: absolute;
      left: 40.5%;
      top: 20%;
      max-width: rem(278);
      max-height: rem(360);
      @include respond(mobile) {
        max-width: rem(172);
        max-height: rem(208);
        left: 28%;
        top: 28%;
      }
    }
  }

  .nickfaldo-container {
    width: 80%;
    margin-inline: auto;
    padding: rem(50) 0 rem(0);
    @include respond(mobile) {
      width: 100%;
      padding: rem(40) 0 rem(0);
    }

    .headingText {
      color: hsla(0, 0%, 11%, 1);
      font-size: rem(34);
      font-weight: 600;
      letter-spacing: rem(0.5);
      padding-bottom: rem(20);
      font-family: "EB Garamond";
      @include respond(mobile) {
        color: hsla(32, 32%, 35%, 1);
        font-size: rem(20);
        font-weight: 700;
        letter-spacing: rem(0.3);
        padding-bottom: rem(0);
      }
    }
    .imagewrapper {
      min-height: rem(650);
      text-align: center;
      width: 100%;
      @include bgCover();
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding: rem(50) 0 rem(50);
      @include respond(mobile) {
        min-height: rem(435);
        padding: rem(30) 0 rem(40);
        .blackbull-logo {
          svg {
            max-width: rem(170);
          }
        }
      }
      .age-logo {
        position: absolute;
        top: 20%;
        left: 38.5%;
        @include respond(mobile) {
          top: 22%;
          left: 32%;
        }
      }
      .age-number {
        position: absolute;
        top: 19%;
        @include respond(mobile) {
        }
      }
      .bottle {
        max-width: rem(333);
        max-height: rem(540);
        text-align: center;
        margin-inline: auto;
        // left: 38%;
        // top: 11%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include respond(mobile) {
          max-width: rem(210);
          max-height: rem(330);
        }
      }
    }
    .learnmorebtn {
      border: rem(1) solid hsla(32, 20%, 43%, 1);
      border-radius: rem(1);
      padding: rem(18) rem(50);
      color: hsla(32, 35%, 40%, 1);
      font-weight: 500;
      font-size: rem(13);
      font-family: "Roboto";
      margin-top: rem(20);
      @include respond(mobile) {
        font-size: rem(10);
        padding: rem(14) rem(30);
      }
    }
  }
  .slider-container {
    padding: rem(20) rem(40);
    @include respond(mobile) {
      padding: rem(15) rem(15) rem(25);
      background-color: white;
    }
    .image {
      max-height: rem(760);
      width: 100%;
      @include respond(mobile) {
        min-height: rem(340);
        width: 100%;
      }
    }
    .slick-slider {
      @include respond(mobile) {
        ul {
          display: none !important;
        }
      }
      .slick-prev {
        @include respond(mobile) {
          left: 40%;
        }
        .slick-prev:before {
          font-size: rem(40);
          @include respond(mobile) {
            font-size: rem(24);
          }
        }
        &::before {
          font-size: rem(40);
          @include respond(mobile) {
            font-size: rem(24);
          }
        }
      }
      .slick-arrow,
      .slick-next {
        width: rem(40);
        height: rem(40);
        @include respond(mobile) {
          width: rem(24);
          height: rem(24);
          bottom: 0;
          top: 112%;
          right: 40%;
          color: hsla(32, 20%, 43%, 1);
        }
        .slick-prev:before {
          font-size: rem(40);
          @include respond(mobile) {
            font-size: rem(24);
          }
        }
        &::before {
          font-size: rem(40);
          @include respond(mobile) {
            font-size: rem(24);
            color: hsla(32, 20%, 43%, 1);
          }
        }
      }
      .slick-dots {
        bottom: rem(0);
        @include respond(mobile) {
        }
        li button::before {
          font-size: rem(18);
          color: hsla(38, 29%, 43%, 1);
          @include respond(mobile) {
          }
        }
      }
    }
  }
}
.nickfaldo-card-bottommargin{
  @include respond(desktop){
    margin-bottom: rem(25) !important;
  }

}
