.contact-us-wrapper {
  .gm-style-mtc-bbw {
    display: none;
  }

  background-color: $white;
  max-width: 100%;

  @include respond(desktop) {
    padding-top: rem(130);
  }

  @include respond(ipadMobile) {
    padding-top: rem(200);
  }

  @include respond(mobile) {
    padding-top: rem(140);
  }

  .headingText {
    text-transform: uppercase;
    font-family: "EB Garamond";
    color: #181818;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;

    @include respond(desktop) {
      line-height: 1.3;
      font-size: rem(42);
      padding-top: rem(50);
    }

    @include respond(ipadMobile) {
      font-size: rem(24);
      padding-inline: rem(30);
    }
  }

  .tab-container {
    display: flex;
    justify-content: center;

    @include respond(desktop) {
      padding-top: rem(50);
    }

    @include respond(ipadMobile) {
      padding: rem(30) 0;
    }

    ul {
      display: flex;
      color: #181818;
      font-size: rem(18);
      font-family: "Raleway";
      font-weight: 600;
      margin-bottom: 0;
      padding-left: 0;

      a {
        color: #181818;
      }

      .active {
        color: #775c3d;
        border-bottom: rem(3) solid #775c3d;
      }

      li {
        margin-inline: rem(10);
        cursor: pointer;

        // &:hover {
        //   color: #775c3d;
        //   border-bottom: rem(3) solid #775c3d;
        // }
      }
    }
  }

  .country-level-text {
    margin-bottom: 0;
    color: #181818;
    font-family: "Raleway";
    font-weight: 600;
    font-size: rem(16);
    text-align: right;
    padding: rem(0) rem(30) rem(8) 0;

    @include respond(ipadMobile) {
      display: none;
    }
  }

  .map-container {
    position: relative;
    max-width: 98.5vw;
    display: flex;
    justify-content: center;

    .map-innerleft_section {
      .left-inner-section {
        // position: absolute;
        // left: rem(20);
        // top: rem(25);
        // z-index: 2;
        background-color: rgba(24, 24, 24, 1);
        width: rem(550);
        // height: rem(710);
        padding: rem(18) 0 rem(18) rem(20);

        @include respond(ipadMobile) {
          height: 100%;
          padding: rem(20) 0 0 rem(20);
          width: 100%;
          left: 0;
          top: 0;
        }

        .top-section {
          display: flex;
          align-items: center;
          justify-content: space-between;

          @include respond(desktop) {
            margin: rem(0) rem(20) 0 0;

            .clearall-btn {
              text-align: center;
              color: rgba(255, 255, 255, 0.5);
              font-size: rem(12);
              font-weight: 500;
              font-family: "Raleway";
              cursor: pointer;
              letter-spacing: rem(1);
              text-decoration: underline;

            }
          }

          @include respond(ipadMobile) {
            display: none;
          }

          .location-text {
            color: rgba(215, 195, 151, 1);
            font-family: "Poppins";
            font-weight: 600;
            font-size: rem(20);
            text-transform: uppercase;
          }

          .down-arrow-container {
            color: rgba(215, 195, 151, 1);
            font-size: rem(16);
            font-family: "Poppins";
            font-weight: 400;
            text-transform: uppercase;
            cursor: pointer;
          }
        }

        .middle-section {
          padding: rem(0) rem(20) rem(20) 0;

          .location-select {
            input {
              @include respond(desktop) {
                padding: rem(12) rem(15);

                &::placeholder {
                  color: rgb(153, 153, 153);
                  font-size: rem(16);
                  font-weight: 500;
                  font-family: "EB Garamond";
                }
              }
            }
          }

          .result-text {
            color: rgba(153, 153, 153, 1);
            padding-top: rem(6);
            font-family: " Poppins";
            font-weight: 400;
            line-height: 1.5;
            font-size: rem(11);
            letter-spacing: rem(0.6);

            @include respond(ipadMobile) {
              display: none;
            }
          }

          .react-select-section {
            @include respond(desktop) {
              margin: rem(20) 0 rem(15);
            }

            .top-nav__search {
              background-color: rgba(15, 15, 15, 1);
              width: 100%;

              .react-select {
                ::-webkit-scrollbar {
                  width: rem(5);
                }

                ::-webkit-scrollbar-thumb {
                  background: rgba(52, 52, 52, 1);
                }

                .react-select__control {
                  background-color: transparent;
                  border: none;
                  border-radius: 0;
                  border-bottom: rem(0.5) solid rgba(215, 195, 151, 1);
                  outline: none;
                  padding: rem(5) 0;

                  &:hover {
                    border: none !important;
                    border-radius: 0;
                    border-bottom: rem(0.5) solid rgba(215, 195, 151, 1) !important;
                  }
                }

                .css-13cymwt-control {
                  box-shadow: none !important;
                }

                .css-8mmkcg {
                  display: inline-block;
                  fill: #d7c397 !important;
                  line-height: 1;
                  stroke: #d7c397 !important;
                  stroke-width: 0;
                }

                .css-t3ipsp-control {
                  box-shadow: none;
                }

                .css-1u9des2-indicatorSeparator {
                  display: none !important;
                }

                .css-1dimb5e-singleValue {
                  color: hsla(41, 44%, 72%, 1);
                }

                .css-b62m3t-container {
                  max-width: 45%;

                  @include respond(mobile) {
                    width: 100%;
                  }

                  border-bottom: rem(1) solid hsla(36, 36%, 65%, 1);
                  border-radius: rem(3);

                  .css-13cymwt-control {
                    background-color: transparent;
                    border-bottom: rem(0.5) solid hsla(41, 44%, 72%, 1);
                  }

                  .css-1fdsijx-ValueContainer {
                    padding: 0;

                    .css-1dimb5e-singleValue {
                      color: hsla(41, 44%, 72%, 1);
                      font-size: rem(14);
                      font-weight: 500;
                      font-family: "Raleway";
                      padding-left: rem(10);

                      @include respond(ipad) {
                        font-size: rem(13);
                      }

                      @include respond(mobile) {
                        font-size: rem(12);
                      }
                    }
                  }
                }

                .css-1u9des2-indicatorSeparator {
                  display: none !important;
                }

                .css-1hb7zxy-IndicatorsContainer {
                  .css-1u9des2-indicatorSeparator {
                    -webkit-align-self: stretch;
                    -ms-flex-item-align: stretch;
                    align-self: stretch;
                    width: 1px;
                    background-color: hsl(0, 0%, 80%);
                    margin-bottom: 0;
                    margin-top: 0;
                    display: none !important;
                  }

                  .css-1xc3v61-indicatorContainer {
                    .css-tj5bde-Svg {
                      fill: #d7c397;
                      line-height: 1;
                      stroke: hsla(36, 36%, 65%, 1);
                      stroke-width: 0;
                    }
                  }
                }

                .css-1jqq78o-placeholder {
                  color: rgba(255, 255, 255, 0.5);
                  font-size: rem(12);
                  font-weight: 600;
                  font-family: "Raleway";
                  padding-left: rem(2);
                }

                .css-13cymwt-control:hover {
                  border: rem(0.5) solid hsla(41, 44%, 72%, 1);
                  outline: none;
                  box-shadow: none;
                  background-color: transparent;
                }

                .css-t3ipsp-control:hover {
                  border: none;
                  border-radius: 0;
                  border-bottom: rem(0.5) solid hsla(41, 44%, 72%, 1);
                }
              }

              .react-select__control--menu-is-open {
                .css-tj5bde-Svg {
                  fill: #c6ac86;
                  line-height: 1;
                  stroke: hsla(36, 36%, 65%, 1);
                  stroke-width: 0;
                  transform: rotate(180deg);
                }
              }

              .css-tj5bde-Svg {
                fill: #eeeeee;
                line-height: 1;
                stroke: hsla(36, 36%, 65%, 1);
                stroke-width: 0;
              }
            }
          }

          .react-select-search-section {
            display: flex;
            gap: rem(5);

            @include respond(ipadMobile) {
              display: none;
            }

            .location-select {
              width: 100%;
              background-color: rgba(15, 15, 15, 1);
              display: flex;
              align-items: center;
              justify-content: center;

              .pac-target-input {
                width: 100%;
                background: transparent;
                outline: none;
                border: unset;
                border-bottom: rem(0.5) solid rgba(215, 195, 151, 1);
                color: rgba(215, 195, 151, 1);
              }

              .svg-container {
                position: relative;
                border-bottom: rem(0.5) solid rgba(215, 195, 151, 1);
                height: 100%;
                display: flex;
                width: rem(50);
                justify-content: center;
                align-items: center;

                span {
                  position: absolute;
                  background-color: rgba(255, 255, 255, 0.37);
                  width: rem(1);
                  left: rem(0);
                  height: rem(25);
                }

                img {
                  max-width: rem(25);
                  max-height: rem(25);
                }
              }
            }

            span {
              width: 50%;

              .top-nav__search {
                background-color: rgba(15, 15, 15, 1);
                width: 100%;

                .react-select {
                  ::-webkit-scrollbar {
                    width: rem(5);
                  }

                  ::-webkit-scrollbar-thumb {
                    background: rgba(52, 52, 52, 1);
                  }

                  .css-8mmkcg {
                    display: inline-block;
                    fill: #d7c397 !important;
                    line-height: 1;
                    stroke: #d7c397 !important;
                    stroke-width: 0;
                  }

                  .react-select__control {
                    background-color: transparent;
                    border: none;
                    border-radius: 0;
                    border-bottom: rem(0.5) solid rgba(215, 195, 151, 1);
                    outline: none;
                    padding: rem(5) 0;

                    &:hover {
                      border: none !important;
                      border-radius: 0;
                      border-bottom: rem(0.5) solid rgba(215, 195, 151, 1) !important;
                    }
                  }

                  .css-13cymwt-control {
                    box-shadow: none !important;
                  }

                  .css-t3ipsp-control {
                    box-shadow: none;
                  }

                  .css-1dimb5e-singleValue {
                    color: hsla(41, 44%, 72%, 1);
                  }

                  .css-b62m3t-container {
                    max-width: 45%;

                    @include respond(mobile) {
                      width: 100%;
                    }

                    border-bottom: rem(1) solid hsla(36, 36%, 65%, 1);
                    border-radius: rem(3);

                    .css-13cymwt-control {
                      background-color: transparent;
                      border-bottom: rem(0.5) solid hsla(41, 44%, 72%, 1);
                    }

                    .css-1fdsijx-ValueContainer {
                      padding: 0;

                      .css-1dimb5e-singleValue {
                        color: hsla(41, 44%, 72%, 1);
                        font-size: rem(14);
                        font-weight: 500;
                        font-family: "Raleway";
                        padding-left: rem(10);

                        @include respond(ipad) {
                          font-size: rem(13);
                        }

                        @include respond(mobile) {
                          font-size: rem(12);
                        }
                      }
                    }
                  }

                  .css-1hb7zxy-IndicatorsContainer {
                    .css-1u9des2-indicatorSeparator {
                      -webkit-align-self: stretch;
                      -ms-flex-item-align: stretch;
                      align-self: stretch;
                      width: 1px;
                      background-color: hsl(0, 0%, 80%);
                      margin-bottom: 0;
                      margin-top: 0;
                      display: none !important;
                    }

                    .css-1xc3v61-indicatorContainer {
                      .css-tj5bde-Svg {
                        fill: #d7c397;
                        line-height: 1;
                        stroke: hsla(36, 36%, 65%, 1);
                        stroke-width: 0;
                      }
                    }
                  }

                  .css-1jqq78o-placeholder {
                    color: rgba(255, 255, 255, 0.5);
                    font-size: rem(12);
                    font-weight: 600;
                    font-family: "Raleway";
                    padding-left: rem(2);
                  }

                  .css-13cymwt-control:hover {
                    border: rem(0.5) solid hsla(41, 44%, 72%, 1);
                    outline: none;
                    box-shadow: none;
                    background-color: transparent;
                  }

                  .css-t3ipsp-control:hover {
                    border: none;
                    border-radius: 0;
                    border-bottom: rem(0.5) solid hsla(41, 44%, 72%, 1);
                  }
                }

                .react-select__control--menu-is-open {
                  .css-tj5bde-Svg {
                    fill: #c6ac86;
                    line-height: 1;
                    stroke: hsla(36, 36%, 65%, 1);
                    stroke-width: 0;
                    transform: rotate(180deg);
                  }
                }

                .css-tj5bde-Svg {
                  fill: #eeeeee;
                  line-height: 1;
                  stroke: hsla(36, 36%, 65%, 1);
                  stroke-width: 0;
                }
              }

              .input-wrapper {
                width: 100%;
                height: 100%;

                input {
                  width: 100%;
                  background-color: hsla(0, 0%, 6%, 1);

                  border: none;
                  border-radius: 0;
                  border-bottom: rem(0.5) solid hsla(41, 44%, 72%, 1);
                  padding: rem(10.5) 0;
                  color: rgba(255, 255, 255, 0.5);
                  padding-left: rem(10);
                  height: 100%;

                  &::placeholder {
                    color: rgba(255, 255, 255, 0.5);
                    font-family: "Raleway";
                    font-weight: 600;
                    line-height: 1.6;
                    font-size: rem(12);
                    letter-spacing: rem(0.6);
                  }
                }

                input[type="search"]::-webkit-search-cancel-button {
                  display: none;
                }

                input[type="search"]:focus {
                  outline: none;
                  border: rem(0.5) solid transparent;
                  border-bottom: rem(0.5) solid hsla(41, 44%, 72%, 1);
                }
              }
            }
          }
        }

        .searchbar-section {
          width: 100%;
          height: rem(125);
          background-color: white;
        }

        .card-section {
          height: rem(575);
          overflow-y: auto;

          // &::-webkit-scrollbar {
          //   display: none;
          // }
          &::-webkit-scrollbar {
            width: rem(7);
          }

          &::-webkit-scrollbar-thumb {
            background: rgba(52, 52, 52, 1);
          }

          @include respond(ipadMobile) {
            height: 85%;
          }

          .card-item {
            display: flex;
            align-items: center;
            position: relative;
            padding: rem(15) rem(0);
            cursor: pointer;

            .left-section-card {
              @include bgCover();
              height: rem(140);
              width: rem(140);

              @include respond(ipadMobile) {
                height: rem(115);
                width: rem(115);
              }
            }

            .right-section-card {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              height: rem(140);
              color: hsla(0, 0%, 77%, 1);
              font-size: rem(15);
              font-weight: 400;
              font-family: "Raleway";
              letter-spacing: rem(0.6);
              text-align: left;
              line-height: 1.2;
              padding-left: rem(20);

              @include respond(ipadMobile) {
                padding-left: rem(12);
                font-size: rem(13);
                height: rem(115);
              }

              h3 {
                text-transform: uppercase;
                font-weight: 600;
                font-size: rem(20);
                color: hsla(0, 0%, 100%, 1);
                font-family: "EB Garamond";
                margin-bottom: 0;

                @include respond(ipadMobile) {
                  font-size: rem(16);

                }
              }

              p {
                margin-bottom: 0;
                padding-top: rem(15);
              }

              .close-text-wrapper {
                display: flex;
                align-items: center;

                @include respond(ipadMobile) {
                  font-size: rem(12);
                }

                :first-child {
                  color: hsla(0, 100%, 75%, 1);
                }

                :nth-child(2) {
                  padding-inline: rem(5);

                  @include respond(ipadMobile) {
                    padding-inline: rem(2);
                  }
                }

                :nth-child(3) {
                  color: white;
                }
              }

              .direction-wrapper {
                padding-top: rem(25);

                ul {
                  display: flex;
                  padding-left: 0;
                  margin-bottom: 0;

                  li {
                    display: flex;
                    align-items: center;

                    img {
                      margin-right: rem(6);
                    }

                    a {
                      color: hsla(0, 0%, 77%, 1);
                      font-size: rem(15);
                      font-weight: 400;
                      font-family: "Raleway";
                      letter-spacing: rem(0.6);

                      img {
                        margin-right: rem(6);
                      }
                    }

                    &:not(:first-child) {
                      margin-left: rem(20);
                    }
                  }
                }
              }
            }

            &::after {
              content: "";
              position: absolute;
              height: rem(1);
              width: 100%;
              background-color: hsla(0, 0%, 100%, 0.05);
              bottom: 0;
            }
          }
        }
      }
    }

    .map-inner-container {
      @include respond(desktop) {
        height: rem(710);
        width: 100%;
        border-radius: rem(1);
        overflow: "hidden";
      }

      .google-maps-container {
        height: rem(710);
      }
    }

    .right-inner-section {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: absolute;
      right: rem(20);
      top: rem(25);
      z-index: 2;
      background-color: rgba(24, 24, 24, 1);
      width: rem(400);
      padding: rem(30) rem(20) rem(5);

      .top-section {
        border: rem(0.25) solid #d7c397;
        min-width: 100%;

        ul {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          margin-bottom: 0;
          padding-left: 0;

          li {
            text-align: center;
            color: rgba(255, 255, 255, 0.5);
            font-size: rem(12);
            font-weight: 500;
            font-family: "Raleway";
            width: 33.33%;
            padding: rem(12) 0;
            position: relative;
            cursor: pointer;

            &:not(:first-child) {
              &::after {
                content: "";
                position: absolute;
                width: rem(1);
                background-color: rgba(255, 255, 255, 0.37);
                height: rem(25);
                left: rem(0);
                top: 20%;
                transform: translateX(-50%) translateY(-50);
              }
            }

            &.active {
              background-color: rgba(215, 195, 151, 1);
              font-weight: 700;
              color: rgba(109, 62, 24, 1);
            }
          }
        }

        .tab {
          width: 33.33%;

          text-align: center;
          color: rgba(255, 255, 255, 0.5);
          font-size: rem(12);
          font-weight: 500;
          font-family: "Raleway";
        }
      }

      span {
        text-align: center;
        color: rgba(255, 255, 255, 0.5);
        font-size: rem(12);
        font-weight: 500;
        font-family: "Raleway";
        padding: rem(10) 0 0;
        cursor: pointer;
        letter-spacing: rem(1);
        text-decoration: underline;
      }

      .bottom-section {
        margin-top: rem(20);

        .inner-bottom-section {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: rem(10);

          input {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.16);
          }

          /* Style the range input */
          input[type="range"] {
            -webkit-appearance: none;
            width: 100%;
            height: 10px;
            // background: #d7c397;
            outline: none;
            padding: 0;
            margin: 0;
          }

          input[type="range"] {
            width: 100%;
            position: relative;
          }

          /* Style the thumb (slider handle) */
          input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: rem(15);
            height: rem(15);
            background: #d7c397;
            /* Color of the slider handle */
            border-radius: 50%;
            cursor: pointer;
            margin-top: rem(0);
            /* Adjust the vertical alignment */
          }

          label {
            color: #eeeeee;
            font-size: rem(12);
            font-weight: 400;
            font-family: "Poppins";
            margin-bottom: 0;
            min-width: rem(40);
          }
        }

        .lower-text {
          margin-top: rem(10);
          color: #eeeeee;
          font-size: rem(12);
          font-weight: 400;
          font-family: "Poppins";
          text-align: center;
        }
      }
    }
  }

  .map-containerMobile {
    background-color: hsla(0, 0%, 9%, 0.9);
    width: 100%;
    padding: rem(15);
    position: relative;
    display: flex;
    flex-direction: column;

    @include respond(mobile) {
      justify-content: space-between;
    }

    @include respond(ipad) {
      max-height: 80vh;
    }

    .search-bar {
      position: relative;

      .svg-container {
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(-10%) translateY(50%);

        span {
          border-right: rem(1) solid rgba(255, 255, 255, 0.37);
          margin-right: rem(10);
          padding-right: rem(10);
        }

        img {
          max-width: rem(25);
          max-height: rem(25);
        }
      }

      input {
        width: 100%;
        background-color: hsla(0, 0%, 6%, 1);
        border: 1px solid transparent;
        padding: rem(15) 0;
        color: hsla(0, 0%, 100%, 0.55);
        padding-left: rem(20);

        &::placeholder {
          color: hsla(0, 0%, 100%, 0.55);
          font-family: "Raleway";
          font-weight: 600;
          line-height: 1.6;
          font-size: rem(15);
          letter-spacing: rem(0.6);
        }
      }

      input[type="search"]::-webkit-search-cancel-button {
        display: none;
      }

      .input-wrapper {
        input {
          width: 100%;
          background-color: hsla(0, 0%, 6%, 1);
          border: 1px solid transparent;
          padding: rem(15) 0;
          color: hsla(0, 0%, 100%, 0.55);
          padding-left: rem(20);

          &::placeholder {
            color: hsla(0, 0%, 100%, 0.55);
            font-family: "Raleway";
            font-weight: 600;
            line-height: 1.6;
            font-size: rem(15);
            letter-spacing: rem(0.6);
          }
        }

        input[type="search"]::-webkit-search-cancel-button {
          display: none;
        }
      }
    }

    .flip-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: rem(15) 0;

      span {
        background-color: #d7c397;
        padding: rem(5) rem(8);

        img {
          padding-left: rem(5);
        }
      }
    }

    .card-section {
      // height: rem(400);
      overflow-y: auto;

      // &::-webkit-scrollbar {
      //   display: none;
      // }
      &::-webkit-scrollbar {
        width: rem(7);
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(52, 52, 52, 1);
      }

      @include respond(ipadMobile) {
        height: rem(500);
      }

      .card-item {
        display: flex;
        align-items: center;
        position: relative;
        padding: rem(15) rem(0);
        cursor: pointer;

        .left-section-card {
          @include bgCover();
          height: rem(140);
          width: rem(140);

          @include respond(ipadMobile) {
            height: rem(115);
            width: rem(115);
          }
        }

        .right-section-card {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          height: rem(140);
          color: hsla(0, 0%, 77%, 1);
          font-size: rem(15);
          font-weight: 400;
          font-family: "Raleway";
          letter-spacing: rem(0.6);
          text-align: left;
          line-height: 1.2;
          padding-left: rem(20);

          @include respond(ipadMobile) {
            padding-left: rem(12);
            font-size: rem(13);
            height: rem(115);
          }

          h3 {
            text-transform: uppercase;
            font-weight: 600;
            font-size: rem(20);
            color: hsla(0, 0%, 100%, 1);
            font-family: "EB Garamond";
            margin-bottom: 0;

            @include respond(ipadMobile) {
              font-size: rem(16);
            }
          }

          p {
            margin-bottom: 0;
            padding-top: rem(10);
          }

          .close-text-wrapper {
            display: flex;
            align-items: center;

            @include respond(ipadMobile) {
              font-size: rem(12);
            }

            :first-child {
              color: hsla(0, 100%, 75%, 1);
            }

            :nth-child(2) {
              padding-inline: rem(5);

              @include respond(ipadMobile) {
                padding-inline: rem(2);
              }
            }

            :nth-child(3) {
              color: white;
            }
          }

          .direction-wrapper {
            padding-top: rem(20);

            ul {
              display: flex;
              padding-left: 0;
              margin-bottom: 0;

              li {
                display: flex;
                align-items: center;

                a {
                  color: hsla(0, 0%, 77%, 1);
                  font-size: rem(15);
                  font-weight: 400;
                  font-family: "Raleway";
                  letter-spacing: rem(0.6);

                  img {
                    margin-right: rem(6);
                  }
                }

                &:not(:first-child) {
                  margin-left: rem(20);
                }
              }
            }
          }
        }

        &::after {
          content: "";
          position: absolute;
          height: rem(1);
          width: 100%;
          background-color: hsla(0, 0%, 100%, 0.05);
          bottom: 0;
        }
      }
    }

    .mobile-filter-container {
      position: absolute;
      top: rem(-25);
      left: rem(10);
      background-color: #181818;
      z-index: 5;
      // height: rem(450);
      width: 94%;
      border-radius: rem(2);

      .clearFilter-text {
        text-align: right;
        color: #d7c397;
        font-size: rem(12);
        font-family: "Raleway";
        font-weight: 400;
        margin-bottom: 0;
        padding: rem(15) rem(20);
      }

      .filter-inner-wrapper {
        // height: rem(300);
        overflow-y: auto;

        .heading {
          font-size: rem(16);
          font-family: "Raleway";
          font-weight: 700;
          color: $white;
          text-align: left;
          letter-spacing: rem(0.6);
          padding-bottom: rem(5);
        }

        .location-type {
          padding-left: rem(20);
          padding-bottom: rem(20);

          .btn-wrapper,
          .drink-btnwrapper {
            display: flex;
            gap: rem(10);
            flex-wrap: wrap;

            .common-btn,
            .drink-btn {
              background-color: #242425;
              color: $white;
              font-size: rem(14);
              font-weight: 400;
              font-family: "Raleway";
              outline: none;
              padding: rem(6) rem(20);
              border-radius: rem(20);
              border: none;

              &:hover {
                background-color: #d7c397;
                color: #1d1d20;
              }

              &.active {
                background-color: #d7c397;
                color: #1d1d20;
              }
            }
          }

          .drink-btnwrapper {
            display: flex;
            gap: rem(10);
            flex-wrap: wrap;

            .drink-btn {
              background-color: #242425;
              color: $white;
              font-size: rem(14);
              font-weight: 400;
              font-family: "Raleway";
              outline: none;
              padding: rem(6) rem(20);
              border-radius: rem(20);
              border: none;

              &:hover {
                background-color: #d7c397;
                color: #1d1d20;
              }
            }
          }
        }
      }

      .lower-container {
        background-color: rgba(0, 0, 0, 0.25);
        height: rem(100);
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
          background-color: transparent;
          padding: rem(10) rem(30);
          border: rem(1) solid rgba(215, 195, 151, 1);
          border-radius: rem(1);
          margin-left: rem(20);
          cursor: pointer;
          font-weight: 500;
          font-family: "Raleway";
          font-size: rem(16);
          color: rgba(215, 195, 151, 1);
          letter-spacing: rem(0.5);
        }
      }
    }
  }
}

.contact-form-outerwrapper {
  margin: rem(40) 0 rem(60);

  @include respond(ipadMobile) {
    margin: rem(0) rem(0) rem(50);
  }

  .contact-form-wrapper {
    max-width: rem(615);
    margin-inline: auto;
    background-color: #f7f0dd;

    @include respond(desktop) {
      padding: rem(45) rem(75);
    }

    @include respond(ipadMobile) {
      padding: rem(27) rem(24);
    }

    .contact-form__btn {
      margin-top: rem(20);

      @include respond(ipadMobile) {
        display: flex;
        justify-content: center;
      }
    }

    .btn-primary {
      background-image: linear-gradient(to bottom, #9f8043 100%, #edd5a7 100%);
      outline: none;
      border: none;
      font-size: rem(16);
      font-family: "Raleway";
      font-family: 600;
      color: #ffffff;
      border-radius: rem(6);
      padding: rem(14) rem(16);
      text-transform: capitalize;
      letter-spacing: rem(0.5);

      @include respond(ipadMobile) {
        border-radius: rem(4);
        padding: rem(8) rem(10);
        font-size: rem(10);
      }
    }

    .contact-form {
      .css-13cymwt-control {
        padding: rem(7) rem(10);
        border: none;
        border-bottom: rem(1) solid #775c3d;

        @include respond(ipadMobile) {
          padding: rem(0) rem(5);
        }
      }

      .css-1u9des2-indicatorSeparator {
        display: none !important;
      }

      .react-select__control--menu-is-open {
        border: none;
        box-shadow: none;
        padding: rem(7) rem(10);
        outline: none;
        border-bottom: rem(1) solid #775c3d;

        @include respond(ipadMobile) {
          padding: rem(0) rem(5);
        }
      }

      .react-select__control--is-focused {
        padding: rem(7) rem(10);
        border: none;
        box-shadow: none;
        border-bottom: rem(1) solid #775c3d;

        @include respond(ipadMobile) {
          padding: rem(0) rem(5);
        }

        &:hover {
          box-shadow: none;
        }
      }

      label {
        color: #181818;
        font-family: "Raleway";
        font-weight: 400;
        font-size: rem(14);
        padding-bottom: rem(5);

        span {
          color: #c81212;
        }

        @include respond(ipadMobile) {
          font-size: rem(12);
        }
      }

      input,
      textarea {
        min-width: 100%;
        outline: none;
        border: none;
        border-radius: rem(3);
        padding: rem(14) rem(20);
        border-bottom: rem(1) solid #775c3d;

        @include respond(ipadMobile) {
          padding: rem(9) rem(10);
        }
      }

      .form-group-wrapper {
        margin-top: rem(20);

        @include respond(ipadMobile) {
          margin-top: rem(10);
        }
      }
    }

    .headingText {
      color: #775c3d;
      font-family: "EB Garamond";
      font-weight: 600;
      text-align: center;
      line-height: 1.3;

      @include respond(desktop) {
        font-size: rem(21);
        letter-spacing: rem(1);
      }

      @include respond(ipadMobile) {
        font-size: rem(16);
        letter-spacing: rem(0.3);
      }
    }

    .subheadingText {
      color: #181818;
      font-family: "Raleway";
      font-weight: 400;
      text-align: center;
      line-height: 1.3;
      margin-bottom: 0;
      padding-top: rem(10);

      @include respond(desktop) {
        font-size: rem(16);
        letter-spacing: rem(0.5);
      }

      @include respond(ipadMobile) {
        font-size: rem(12);
        letter-spacing: rem(0.5);
        max-width: 85%;
        margin-inline: auto;
      }
    }
  }

  .bottom-headingText {
    color: #181818;
    font-family: "Raleway";
    font-weight: 600;
    text-align: center;
    line-height: 1.3;
    margin-bottom: 0;

    @include respond(desktop) {
      font-size: rem(16);
      letter-spacing: rem(0.5);
      padding-top: rem(20);
    }

    @include respond(ipadMobile) {
      font-size: rem(12);
      letter-spacing: rem(0.5);
      padding-top: rem(15);
    }
  }

  .bottom-headingsubText {
    color: #181818;
    font-family: "Raleway";
    font-weight: 400;
    text-align: center;
    line-height: 1.3;
    margin-bottom: 0;

    @include respond(desktop) {
      font-size: rem(14);
      letter-spacing: rem(0.5);
      padding-top: rem(5);
    }

    @include respond(ipadMobile) {
      font-size: rem(10);
      padding-top: rem(3);
      letter-spacing: rem(0.2);
    }
  }
}

.online_store_section {
  display: block;
  height: rem(710);
  background-image: url("/images/store-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow-y: auto;

  .stores_cards_box {
    padding: rem(146) rem(24) rem(30);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: rem(24);

    @include respond(smalldesktop) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include respond(ipadProMobile) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond(ipadMobile) {
      padding: rem(30) rem(24);
      grid-template-columns: repeat(3, 1fr);
    }

    @include respond(mobile) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond(mediumMobile) {
      padding: rem(30) rem(16);
      gap: rem(16);
    }


    .store_card {
      border: 0.25px solid #323232;
      height: 15vw;
      position: relative;

      @include respond(smalldesktop) {
        height: 18vw;
      }

      @include respond(ipadProMobile) {
        height: 20vw;
      }

      @include respond(ipadMobile) {
        height: 26vw;
      }

      @include respond(mobile) {
        height: 41.5vw;
      }

      a {
        width: 100%;
        height: 100%;
        padding: rem(24);
        box-shadow: none;
        outline: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        align-content: center;

        @include respond(mediumMobile) {
          padding: rem(16);
        }
      }

      .arrow_icon {
        position: absolute;
        top: rem(12);
        right: rem(12);

        @include respond(mediumMobile) {
          top: rem(6);
          right: rem(6);
        }
      }

      .store_logo_img {
        width: 100%;
        height: auto;
      }

      h1 {
        width: 100%;
        text-align: center;
        margin: rem(10) 0 0;
        font-family: "Raleway";
        font-size: rem(20);
        font-weight: 500;
        line-height: rem(33);
        color: #181818;
        text-transform: uppercase;

        @include respond(mediumMobile) {
          font-size: rem(15);
          line-height: rem(28);
          margin: rem(4) 0 0;
        }
      }
    }
  }
}

.online_store_text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  h2 {
    font-family: "Raleway";
    font-size: rem(20);
    font-weight: 400;
    line-height: rem(33);
    text-align: center;
    color: #FFF;
    max-width: rem(333);
    padding-right: rem(24);
  }
}