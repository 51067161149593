.header-outerwrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 15;
  background-color: hsla(48, 17%, 6%, 0.8);
  border-bottom: 0.25px solid #b6995e;

  .header_menu_dropdown {
    button {
      padding: 0px;
      background: transparent;
      box-shadow: none;
      border: 0px;
      color: $white;
      font-size: rem(14);
      font-weight: 500;
      font-family: "Raleway";
      line-height: 1.18;
      text-transform: uppercase;

      &::after {
        display: none;
      }

      &:active {
        color: inherit;
        background: inherit;
        border-color: inherit;
      }
    }

    .dropdown-menu {
      border: 0.25px solid #d7c397;
      background: linear-gradient(
          0deg,
          rgba(28, 28, 28, 0.9) 0%,
          rgba(28, 28, 28, 0.9) 100%
        ),
        rgba(119, 92, 61, 0.9);
      box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.08);
      inset: 50.5px auto auto 0px !important;
      border-radius: 0px;

      .dropdown-item {
        color: #ffffff;
        color: #fff;
        text-align: center;
        font-family: "Raleway";
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        text-decoration: none;
        text-align: left;
        text-transform: capitalize;
        padding-top: 8px;
        padding-bottom: 8px;
        position: relative;

        &:last-child {
          &::before {
            display: none;
          }
        }

        &::before {
          content: "";
          background: linear-gradient(
            90deg,
            #b6995e 1.78%,
            rgba(182, 153, 94, 0) 100%
          );
          position: absolute;
          left: 0px;
          bottom: rem(0);
          display: block;
          width: 100%;
          height: 2px;
          background-size: contain;
          background-repeat: no-repeat;
        }

        &:hover {
          background: transparent;
          color: hsl(41, 44%, 72%);
        }
      }
    }
  }

  &.white_header {
    box-shadow: 0px 4px 70px 0px #0000000f;
    background: rgba(255, 255, 255, 0.7);
    border-color: transparent;

    .header_menu_dropdown {
      button {
        color: #000000;

        img {
          filter: invert(1);
        }
      }

      .dropdown-menu {
        .dropdown-item {
          color: #ffffff;

          &:hover {
            background: transparent;
            color: hsl(41, 44%, 72%);
          }
        }
      }
    }

    .header-container {
      .menu_mobile {
        filter: invert(1);
      }

      .left_section {
        ul {
          li {
            a {
              color: #000000;

              img {
                filter: invert(1);
              }
            }
          }
        }
      }

      .site-logo_wrapper {
        img {
          filter: invert(1);
        }
      }

      .right_section {
        ul {
          li {
            a {
              color: #000000;

              img {
                filter: invert(1);
              }
            }
          }
        }

        .social-media-wrapper {
          span {
            .cart_icon {
              filter: invert(1);
            }

            a {
              img {
                filter: invert(1);
              }
            }
          }
        }
      }
    }
  }

  .cart_icon_for_mobile {
    display: none;

    @include respond(ipadMobile) {
      display: block;
      position: absolute;
      right: rem(8);
      top: 59%;
      transform: translateY(-50%);
    }
  }

  .cart-count {
    position: absolute;
    top: -5px;
    right: -5px;
    color: white;
    border-radius: 50%;
    padding: 1px 2px;
    font-size: 12px;
    background: #b6995e;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-family: "Raleway";
    text-transform: uppercase;
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: auto;
    max-width: 94%;
    height: 120px;

    @include respond(desktop) {
      padding: 25px 0;

      .menu_mobile {
        display: none;
      }
    }

    @include respond(ipad) {
      padding: rem(14) 0;
      position: relative;
      height: auto;

      .menu_mobile {
        min-height: rem(30);
        position: absolute;
        left: 0;
      }
    }

    @include respond(mobile) {
      padding: rem(10) 0;
      position: relative;
      height: auto;

      .menu_mobile {
        position: absolute;
        left: 0;
      }
    }

    .left_section {
      @include respond(desktop) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-basis: 100%;
        height: 100%;

        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0;
          padding-left: 0;

          li {
            letter-spacing: rem(1);
            position: relative;

            .menuColor {
              color: hsla(41, 44%, 72%, 1);
            }

            &:not(:last-child) {
              padding-right: rem(30);

              @include respond(ipadProMobile) {
                padding-right: rem(20);
              }
            }

            a {
              color: $white;
              font-size: rem(14);
              font-weight: 500;
              font-family: "Raleway";
              line-height: 1.18;
              text-transform: uppercase;
              cursor: pointer;
            }

            .dropdown-item {
              position: absolute;
              top: rem(30);
              right: rem(10);
              background-color: hsla(45, 13%, 6%, 0.85);
              min-width: rem(260);
              border: rem(0.5) solid hsla(41, 44%, 72%, 0.5);
              border-radius: rem(5);

              li {
                padding: rem(9) 0;
                letter-spacing: rem(0.5);

                a {
                  color: hsla(41, 44%, 72%, 1);
                  font-family: "Raleway";
                  font-family: 400;
                  font-size: rem(14);
                  padding: rem(9) rem(15);
                  min-width: 100%;
                }

                &:hover {
                  a {
                    color: hsla(45, 13%, 6%, 1);
                  }
                }
              }

              li:hover {
                cursor: pointer;
                background-color: hsla(41, 44%, 72%, 1);
              }
            }

            .menuColor {
              color: hsla(41, 44%, 72%, 1);
            }

            @include respond(ipadMobile) {
              display: none;
            }
          }
        }

        //country dropdown
        .top-nav__search {
          width: 40%;

          &.white_header_dropdown {
            .react-select {
              .css-8mmkcg {
                fill: #000000;
                stroke: #000000;
              }

              .react-select__control {
                border: rem(0.5) solid #000000;
              }

              .css-1dimb5e-singleValue {
                color: #000000;
              }

              .css-b62m3t-container {
                border: rem(1) solid #000000;

                .css-13cymwt-control {
                  border: rem(0.5) solid #000000;
                }

                .css-1fdsijx-ValueContainer {
                  .css-1dimb5e-singleValue {
                    color: #000000;
                  }
                }
              }

              .css-1hb7zxy-IndicatorsContainer {
                .css-1u9des2-indicatorSeparator {
                  background-color: #000000;
                }

                .css-1xc3v61-indicatorContainer {
                  .css-tj5bde-Svg {
                    fill: #000000;
                    stroke: #000000;
                  }
                }
              }

              .css-1jqq78o-placeholder {
                color: #000000;
              }

              .css-13cymwt-control:hover {
                border: rem(0.5) solid #000000;
              }

              .react-select__control--menu-is-open {
                .css-tj5bde-Svg {
                  fill: #000000;
                  stroke: #000000;
                }
              }
            }
          }

          .react-select {
            max-width: rem(230);

            .css-1u9des2-indicatorSeparator {
              display: none !important;
            }

            .css-8mmkcg {
              fill: #775c3d;
              stroke: #775c3d;
            }

            .react-select__control {
              background-color: transparent;
              border: rem(0.5) solid hsla(41, 44%, 72%, 1);
              outline: none;
            }

            .css-13cymwt-control {
              box-shadow: none !important;
            }

            .css-t3ipsp-control {
              box-shadow: none;
            }

            .css-1dimb5e-singleValue {
              color: hsla(41, 44%, 72%, 1);
            }

            .css-b62m3t-container {
              max-width: 45%;

              @include respond(mobile) {
                width: 100%;
              }

              border: rem(1) solid hsla(36, 36%, 65%, 1);
              border-radius: rem(3);

              .css-13cymwt-control {
                background-color: transparent;
                border: rem(0.5) solid hsla(41, 44%, 72%, 1);
              }

              .css-1fdsijx-ValueContainer {
                padding: 0;

                .css-1dimb5e-singleValue {
                  color: hsla(41, 44%, 72%, 1);
                  font-size: rem(14);
                  font-weight: 500;
                  letter-spacing: rem(1);
                  font-family: "Raleway";
                  padding-left: rem(10);

                  @include respond(ipad) {
                    font-size: rem(13);
                  }

                  @include respond(mobile) {
                    font-size: rem(12);
                  }
                }
              }
            }

            .css-1hb7zxy-IndicatorsContainer {
              .css-1u9des2-indicatorSeparator {
                -webkit-align-self: stretch;
                -ms-flex-item-align: stretch;
                align-self: stretch;
                width: 1px;
                background-color: hsl(0, 0%, 80%);
                margin-bottom: 0;
                margin-top: 0;
                display: none;
              }

              .css-1xc3v61-indicatorContainer {
                .css-tj5bde-Svg {
                  fill: hsla(36, 36%, 65%, 1);
                  line-height: 1;
                  stroke: hsla(36, 36%, 65%, 1);
                  stroke-width: 0;
                  // transform: rotate(180deg);
                }
              }
            }

            .css-1jqq78o-placeholder {
              color: hsla(41, 44%, 72%, 1);
              font-size: rem(12);
              font-weight: 500;
              font-family: "Raleway";
              padding-left: rem(10);
            }

            .css-13cymwt-control:hover {
              border: rem(0.5) solid hsla(41, 44%, 72%, 1);
              outline: none;
              box-shadow: none;
              background-color: transparent;
            }
          }

          .react-select__control--menu-is-open {
            .css-tj5bde-Svg {
              fill: hsla(36, 36%, 65%, 1);
              line-height: 1;
              stroke: hsla(36, 36%, 65%, 1);
              stroke-width: 0;
              transform: rotate(180deg);
            }
          }

          .css-tj5bde-Svg {
            fill: hsla(36, 36%, 65%, 1);
            line-height: 1;
            stroke: hsla(36, 36%, 65%, 1);
            stroke-width: 0;
          }
        }
      }

      @include respond(ipadMobile) {
        display: none;
      }
    }

    .site-logo_wrapper {
      display: flex;
      justify-content: center;
      min-width: 15%;

      @include respond(desktop) {
        .site-logo {
          min-height: rem(90);
        }
      }

      @include respond(ipad) {
        .site-logo {
          min-height: rem(70);
        }
      }

      @include respond(mobile) {
        .site-logo {
          max-height: rem(35);
        }
      }
    }

    .right_section {
      @include respond(desktop) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-basis: 100%;
        height: 100%;

        ul {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0;
          padding-left: 0;

          li {
            a {
              color: hsla(0, 0%, 100%, 1);
            }

            font-size: rem(14);
            font-weight: 500;
            font-family: "Raleway";
            line-height: 1.18;
            letter-spacing: rem(1);
            text-transform: uppercase;
            cursor: pointer;
            color: $white;
            // &:last-child {
            //   position: relative;
            //   padding: 8px 16px;
            //   border-radius: 4px; /* Border radius on the main element */
            //   overflow: hidden;

            //   /* Inner content styles */
            //   a {
            //     position: relative;
            //     z-index: 1; /* Ensure it's above the gradient border */
            //     font-weight: 700;
            //     background: linear-gradient(
            //       94.81deg,
            //       #ffad17 -20.64%,
            //       #ffecca 38.33%,
            //       #ffc65e 86.44%,
            //       #61543c 129.68%
            //     );
            //     -webkit-background-clip: text;
            //     -webkit-text-fill-color: transparent;
            //     background-clip: text;
            //     color: transparent;
            //   }

            //   /* Pseudo-element for the gradient border */
            //   &::before {
            //     content: "";
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     right: 0;
            //     bottom: 0;
            //     border-radius: inherit; /* Matches the border-radius of the main element */
            //     padding: 1px; /* Adjust width of the border */
            //     background: linear-gradient(
            //       96.7deg,
            //       #ffad17 -19.78%,
            //       #ffecca 30.17%,
            //       #ffc65e 70.91%,
            //       #61543c 107.54%
            //     );
            //     -webkit-mask: linear-gradient(#fff 0 0) content-box,
            //       linear-gradient(#fff 0 0); /* Creates a transparent center */
            //     -webkit-mask-composite: destination-out;
            //     mask-composite: exclude; /* Removes the center area */
            //   }
            // }

            &:not(:last-child) {
              padding-right: rem(30);

              @include respond(ipadProMobile) {
                padding-right: rem(20);
              }
            }
          }
          .charity_menu {
            position: relative;
            padding: 8px 16px;
            border-radius: 4px; /* Border radius on the main element */
            overflow: hidden;

            /* Inner content styles */
            a {
              position: relative;
              z-index: 1; /* Ensure it's above the gradient border */
              font-weight: 700;
              background: linear-gradient(
                94.81deg,
                #ffad17 -20.64%,
                #ffecca 38.33%,
                #ffc65e 86.44%,
                #61543c 129.68%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              color: transparent;
            }

            /* Pseudo-element for the gradient border */
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border-radius: inherit; /* Matches the border-radius of the main element */
              padding: 1px; /* Adjust width of the border */
              background: linear-gradient(
                96.7deg,
                #ffad17 -19.78%,
                #ffecca 30.17%,
                #ffc65e 70.91%,
                #61543c 107.54%
              );
              -webkit-mask: linear-gradient(#fff 0 0) content-box,
                linear-gradient(#fff 0 0); /* Creates a transparent center */
              -webkit-mask-composite: destination-out;
              mask-composite: exclude; /* Removes the center area */
            }
          }
        }

        .social-media-wrapper {
          span {
            &:not(:first-child) {
              padding-left: rem(16);

              @include respond(ipadPro) {
                padding-left: rem(5);
              }
            }
          }
        }
      }

      @include respond(ipadMobile) {
        display: none;
      }
    }
  }
}

.Header-scroll {
  .header-outerwrapper {
    background-color: hsla(48, 17%, 6%, 0.8);

    .header_menu_dropdown {
      .dropdown-menu {
        transition: all 0.15s;
        inset: 30.5px auto auto 0px !important;

        .dropdown-item {
          color: #ffffff !important;

          &:hover {
            background: transparent;
            color: hsl(41, 44%, 72%) !important;
          }
        }
      }
    }

    &.white_header {
      box-shadow: 0px 4px 70px 0px #0000000f;
      background: rgba(255, 255, 255, 0.7);
      border-color: transparent;

      .header-container {
        .left_section {
          ul {
            li {
              a {
                color: #000000;

                img {
                  filter: invert(1);
                }
              }
            }
          }
        }

        .site-logo_wrapper {
          img {
            filter: invert(1);
          }
        }

        .right_section {
          ul {
            li {
              a {
                color: #000000;

                img {
                  filter: invert(1);
                }
              }
            }
          }

          .social-media-wrapper {
            span {
              .cart_icon {
                filter: invert(1);
              }

              a {
                img {
                  filter: invert(1);
                }
              }
            }
          }
        }
      }
    }
  }

  .header-container {
    height: 80px;

    @include respond(desktop) {
      padding: 0.6rem 0;
    }

    @include respond(ipad) {
      height: auto;
    }

    @include respond(mobile) {
      height: auto;
    }

    .site-logo_wrapper {
      @include respond(desktop) {
        .site-logo {
          min-height: rem(60);
          transition: all 0.28s ease-in-out 0s;
        }
      }

      @include respond(ipad) {
        .site-logo {
          min-height: rem(60);
        }
      }

      @include respond(mobile) {
        .site-logo {
          max-height: rem(35);
        }
      }
    }
  }
}

.blended_whisky_menu_box {
  border-bottom: 0.25px solid #d7c397;
  background: linear-gradient(
      0deg,
      rgba(28, 28, 28, 0.9) 0%,
      rgba(28, 28, 28, 0.9) 100%
    ),
    rgba(119, 92, 61, 0.9);
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(8px);
  width: 100vw;
  position: absolute;
  top: 123.5px;
  left: 0px;
  padding: rem(30) rem(80);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  &.scrolled {
    top: 70.5px;
  }

  &.close {
    height: 0px;
    max-height: 0px;
    overflow: hidden;
    padding: rem(0) rem(80);
    transition: max-height 0.25s ease-in-out;
    border-bottom: 0px;
  }

  &.open {
    height: initial;
    max-height: 500px;
    overflow: hidden;
    transition: max-height 0.25s ease-in;
  }

  .inner_menu {
    a {
      text-decoration: none;
      display: block;

      &:hover {
        h2 {
          color: hsl(41, 44%, 72%);
        }
      }
    }

    h2 {
      color: #fff;
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      padding: 8px;
      margin: 0px;
      position: relative;
      word-break: break-all;

      &::before {
        content: "";
        background-image: url("/product/horizontal_line.png");
        position: relative;
        left: -6px;
        top: 28px;
        display: block;
        width: 100%;
        height: 2px;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .list_of_menu {
      display: grid;

      a {
        color: #fff;
        text-align: center;
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        text-decoration: none;
        padding: 8px 6px;
        text-align: left;

        &:hover {
          color: hsl(41, 44%, 72%);
        }
      }
    }
  }

  .vertical_line_div {
    display: block;
    height: auto;
    width: 2px;
    margin: 0px 2px;

    &::before {
      content: "";
      background-image: url("/product/vertical_line.png");
      position: relative;
      left: 0px;
      top: 0px;
      display: block;
      width: 2px;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
