.hamburger-container {
  min-width: 100%;
  min-height: calc(100vh - 65px);
  background-image: linear-gradient(
    hsla(45, 13%, 6%, 1),
    hsla(45, 13%, 6%, 1),
    hsla(45, 13%, 6%, 1)
  );
  z-index: 16;
  position: relative;
  display: none;

  &.open {
    display: flex;
  }

  .mobile_multi_menu_acc {
    display: block;

    .accordion-header {
      width: max-content;

      .accordion-button {
        height: 42px;
        background: transparent;
        border: 0px;
        outline: none;
        box-shadow: none;
        padding: 0px;
        color: #fff;
        font-family: "Raleway";
        font-size: rem(14);
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &::after {
          display: none;
        }
      }
    }

    .accordion-collapse {
      max-width: 90%;
      .accordion-body {
        border: 0px;
        border-radius: 6px;
        background: #262421;
        box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.08);
        padding: rem(0);
        display: grid;

        a {
          display: flex;
          align-items: center;
          justify-content: left;
          height: 36px;
          color: #fff;
          font-family: "Raleway";
          font-size: rem(12);
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: rem(9) rem(15);
          position: relative;

          &:last-child {
            &::before {
              display: none;
            }
          }

          &::before {
            content: "";
            background: linear-gradient(
              90deg,
              #b6995e 1.78%,
              rgba(182, 153, 94, 0) 100%
            );
            position: absolute;
            left: 0px;
            bottom: rem(0);
            display: block;
            width: 100%;
            height: 2px;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  .dropdown-item {
    background-color: hsla(45, 13%, 6%, 0.85);
    max-width: 90%;
    border: rem(0.5) solid hsla(41, 44%, 72%, 0.5);
    border-radius: rem(5);
    margin: rem(10) 0;

    li {
      padding: rem(2) 0;
      line-height: 3;

      a {
        color: hsla(41, 44%, 72%, 1);
        font-family: "Raleway";
        font-family: 400;
        font-size: rem(14);
        padding: rem(9) rem(15);
        min-width: 100%;
      }

      &:hover {
        a {
          color: hsla(45, 13%, 6%, 1);
        }
      }
    }

    li:hover {
      cursor: pointer;
      background-color: hsla(41, 44%, 72%, 1);
    }

    &.our_collection_menu {
      border: 0px;
      border-radius: 6px;
      background: #262421;
      box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.08);
      backdrop-filter: blur(4px);
      padding: rem(8) rem(0);
      max-width: calc(100vw - rem(57));
      width: 100%;
      margin-right: rem(30);

      li {
        &:hover {
          background-color: initial;
        }

        a {
          color: #fff;
          font-family: "Raleway";
          font-size: rem(14);
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: rem(8) rem(20);
        }

        &:first-child {
          &::before {
            display: none;
          }
        }

        &::before {
          content: "";
          background: linear-gradient(
            90deg,
            #b6995e 1.78%,
            rgba(182, 153, 94, 0) 100%
          );
          position: relative;
          left: 0px;
          bottom: rem(0);
          display: block;
          width: 100%;
          height: 2px;
          background-size: contain;
          background-repeat: no-repeat;
        }

        .our_collection_links_tag {
          display: flex;
          align-items: center;
          height: 100%;
          min-height: rem(36);

          a {
            min-width: auto;
            max-width: calc(100vw - rem(86));
            margin-right: rem(30);
            white-space: break-spaces;
          }
        }

        .arrow_image {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 8px;
        }

        .dash_arrow {
          padding-left: rem(20);
        }
      }
    }
  }

  .menuColor {
    color: hsla(41, 44%, 72%, 1);
  }

  .hamburger-innercontainer {
    position: relative;
    overflow-y: scroll;
    max-width: 100%;
    min-height: calc(100vh - 65px);
    z-index: 10;
    background-color: $black;
    display: flex;
    flex-direction: column;
    padding: rem(0) 0 rem(0);

    @include respond(mobile) {
      min-width: 100%;
      padding-left: rem(25);
      padding-top: 2rem;
    }

    @include respond(ipad) {
      min-width: 90%;
      padding: 0;
    }

    .social-media-wrapper {
      position: absolute;
      bottom: 20%;

      @include respond(ipad) {
        padding: rem(40);
      }

      span {
        &:not(:first-child) {
          padding-left: rem(15);

          @include respond(ipad) {
            padding-left: rem(25);
          }
        }
      }
    }
  }

  .humberg-modal {
    position: absolute;
    left: rem(10);
    top: rem(30);
    z-index: 12;
  }

  .close-button {
    position: absolute;
    left: rem(0);
    top: rem(10);
    z-index: 11;
    cursor: pointer;
    color: white;

    x-btn {
      &:hover {
        color: $white;
      }
    }
  }

  .menulist {
    @include respond(ipad) {
      padding: rem(40) rem(40);
    }

    ul {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      @include respond(ipadMobile) {
        align-items: start;
      }
      li {
        line-height: 4;
        cursor: pointer;
        font-weight: 500;
        color: hsla(0, 0%, 100%, 1);
        font-family: "Raleway";
        font-size: rem(12);
        text-transform: uppercase;
        letter-spacing: rem(1);
        opacity: 0.8;
        a {
          text-decoration: none;
          color: hsla(0, 0%, 100%, 1);
        }

        &:hover {
          color: $white;
        }
      }
      // .submenu_list {
      //   &:last-child {
      //     margin-top: rem(10);
      //     // max-width: rem(125);
      //     position: relative;
      //     padding: rem(4) rem(10);
      //     border-radius: rem(4);
      //     text-align: center;
      //     @include respond(ipadMobile) {
      //       padding: rem(1) rem(18);
      //       // max-width: rem(120);
      //       line-height: 3;
      //     }
      //     a {
      //       position: relative;
      //       z-index: 2;
      //       font-weight: 700;
      //       background: linear-gradient(
      //         94.81deg,
      //         #ffad17 -20.64%,
      //         #ffecca 38.33%,
      //         #ffc65e 86.44%,
      //         #61543c 129.68%
      //       );
      //       -webkit-background-clip: text;
      //       -webkit-text-fill-color: transparent;
      //       background-clip: text;
      //       color: transparent;
      //     }

      //     &::before {
      //       content: "";
      //       position: absolute;
      //       top: 0;
      //       left: 0;
      //       right: 0;
      //       bottom: 0;
      //       border-radius: inherit; /* Matches the border-radius of the main element */
      //       padding: rem(2); /* Adjust width of the border */
      //       background: linear-gradient(
      //         96.7deg,
      //         #ffad17 -19.78%,
      //         #ffecca 30.17%,
      //         #ffc65e 70.91%,
      //         #61543c 107.54%
      //       );
      //       -webkit-mask: linear-gradient(#fff 0 0) content-box,
      //         linear-gradient(#fff 0 0); /* Creates a transparent center */
      //       -webkit-mask-composite: destination-out;
      //       mask-composite: exclude; /* Removes the center area */
      //     }
      //   }
      // }
      .charity_menu {
        margin-top: rem(10);
        // max-width: rem(125);
        position: relative;
        padding: rem(4) rem(10);
        border-radius: rem(4);
        text-align: center;
        @include respond(ipadMobile) {
          padding: rem(1) rem(18);
          // max-width: rem(120);
          line-height: 3;
        }
        a {
          position: relative;
          z-index: 2;
          font-weight: 700;
          background: linear-gradient(
            94.81deg,
            #ffad17 -20.64%,
            #ffecca 38.33%,
            #ffc65e 86.44%,
            #61543c 129.68%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          color: transparent;
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: inherit; /* Matches the border-radius of the main element */
          padding: rem(2); /* Adjust width of the border */
          background: linear-gradient(
            96.7deg,
            #ffad17 -19.78%,
            #ffecca 30.17%,
            #ffc65e 70.91%,
            #61543c 107.54%
          );
          -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0); /* Creates a transparent center */
          -webkit-mask-composite: destination-out;
          mask-composite: exclude; /* Removes the center area */
        }
      }
    }
  }

  @include respond(desktop) {
    display: none !important;
  }
}

.mobile_country_select {
  display: none;

  @include respond(ipadMobile) {
    display: block;
    max-width: 250px;
    text-align: right;
    margin-left: auto;
  }
}

.reactselect-wrapperHamberger {
  margin-top: rem(15);

  .css-t3ipsp-control {
    background-color: transparent !important;
    border-color: none !important;
    box-shadow: none !important;
  }

  .css-b62m3t-container {
    max-width: 90%;
    border: rem(1) solid hsla(36, 36%, 65%, 1);
    border-radius: rem(3);

    @include respond(mobile) {
      min-width: 50%;
    }

    .css-13cymwt-control {
      background-color: transparent;
      border: transparent;
    }

    .css-1fdsijx-ValueContainer {
      padding: 0;

      .css-1dimb5e-singleValue {
        color: hsla(36, 36%, 65%, 1);
        font-size: rem(12);
        font-weight: 500;
        font-family: "Raleway";
        padding-left: rem(10);
      }
    }
  }

  .css-1hb7zxy-IndicatorsContainer {
    .css-1u9des2-indicatorSeparator {
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      align-self: stretch;
      width: 1px;
      background-color: hsl(0, 0%, 80%);
      margin-bottom: 0;
      margin-top: 0;
      display: none;
    }

    .css-1xc3v61-indicatorContainer {
      .css-tj5bde-Svg {
        fill: hsla(36, 36%, 65%, 1);
        line-height: 1;
        stroke: hsla(36, 36%, 65%, 1);
        stroke-width: 0;
      }
    }
  }

  .css-1jqq78o-placeholder {
    color: hsla(36, 36%, 65%, 1);
    font-size: rem(12);
    font-weight: 500;
    font-family: "Raleway";
    padding-left: rem(10);
  }

  .css-13cymwt-control:hover {
    border-color: transparent;
    background-color: transparent;
  }
}

.css-t3ipsp-control:hover {
  outline: none !important;
  box-shadow: none !important;
}

.mobile_country_select {
  .css-1dimb5e-singleValue {
    color: #d7c398;
  }

  .white_header_dropdown {
    .react-select {
      &.css-b62m3t-container {
        border: rem(1) solid #000000;
      }

      .css-8mmkcg {
        fill: #000000;
        stroke: #000000;
      }

      .react-select__control {
        border: rem(0.5) solid #000000;
      }

      .css-1dimb5e-singleValue {
        color: #000000;
      }

      .css-b62m3t-container {
        border: rem(1) solid #000000;

        .css-13cymwt-control {
          border: rem(0.5) solid #000000;
        }

        .css-1fdsijx-ValueContainer {
          .css-1dimb5e-singleValue {
            color: #000000;
          }
        }
      }

      .css-1hb7zxy-IndicatorsContainer {
        .css-1u9des2-indicatorSeparator {
          background-color: #000000;
        }

        .css-1xc3v61-indicatorContainer {
          .css-tj5bde-Svg {
            fill: #000000;
            stroke: #000000;
          }
        }
      }

      .css-1jqq78o-placeholder {
        color: #000000;
      }

      .css-13cymwt-control:hover {
        border: rem(0.5) solid #000000;
      }

      .react-select__control--menu-is-open {
        .css-tj5bde-Svg {
          fill: #000000;
          stroke: #000000;
        }
      }
    }
  }
}
