.blackbull-new-collection-main {
  .new_balckbull_collection_banner {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    .nikfaldo_img_section {
      display: flex;
      height: 100%;
      align-items: flex-end;

      img {
        &.nikfaldo_main_img {
          height: auto !important;
        }
      }
    }

    img {
      width: 100% !important;
      height: 100% !important;

      &.nikfaldo_main_img {
        height: auto !important;
      }

      @include respond(mobile) {
        // height: 62vw !important;
      }
    }

    &.cards_of_black_bull {
      img {
        width: 100% !important;
        height: 100% !important;

        @include respond(mobile) {
          height: 62vw !important;
          margin-bottom: rem(30);
        }
      }
    }
  }

  .whisky_banner_desktop {
    display: block;

    @include respond(mobile) {
      display: none;
    }
  }

  .whisky_banner_mobile {
    display: none;

    @include respond(mobile) {
      display: block;
    }
  }
}

.whisky_collection_main_section {
  padding: 95px 5vw;
  background-repeat: no-repeat;
  background-image: url("/new-blackbull-collection/whisky-bg.jpg");
  background-position: center;
  background-size: cover;

  @include respond(mobile) {
    padding: 64px 0px 20px 16px;
  }

  .whisky_card_btn_for {
    background-color: #ffffff !important;
    color: #0b0a0a !important;
    font-size: rem(18);
    font-weight: 500;
    letter-spacing: rem(1);
    font-family: "Roboto";
    line-height: normal;
    border: none;
    outline: none;
    border-radius: rem(1);
    text-transform: uppercase;
    position: absolute;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem(4);
    bottom: 12%;
    left: 60.4%;
    width: rem(183);
    height: rem(40);

    @include respond(ipadProMobile) {
      font-size: rem(16);
      width: rem(140);
      height: rem(40);
      bottom: 12%;
      left: 56.4%;
    }

    @include respond(mobile) {
      bottom: 18%;
      left: 61.4%;
    }

    @include respond(mediumMobile) {
      font-size: rem(12);
      width: rem(100);
      height: rem(30);
      bottom: 22%;
      line-height: normal;
      left: 56.4%;
    }
  }

  .whisky_for_desktop {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(42);

    @include respond(ipadProMobile) {
      gap: rem(30);
    }

    @include respond(mobile) {
      display: none;
    }

    .whisky_card {
      position: relative;

      img {
        border-radius: rem(10);
      }

      &_btn {
        background-color: #a47853;
        color: #ffffff;
        font-size: rem(13);
        font-weight: 500;
        letter-spacing: rem(1);
        font-family: "Roboto";
        border: none;
        outline: none;
        border-radius: rem(1);
        text-transform: uppercase;
        padding: rem(18) rem(48);
        position: absolute;
        bottom: rem(128);
        right: rem(110);

        @include respond(ipad) {
          font-size: rem(10);
          letter-spacing: rem(0.5);
          padding: rem(12) rem(30);
          bottom: rem(60);
          right: rem(40);
        }
      }
    }
  }

  .whisky_for_mobile {
    display: none;

    @include respond(mobile) {
      display: block;
    }

    .whisky_card_slider {
      display: block;

      .whisky_card {
        width: 90% !important;
        position: relative;

        &_btn {
          background-color: #a47853;
          color: #ffffff;
          font-size: rem(8);
          font-weight: 500;
          letter-spacing: rem(0.5);
          font-family: "Roboto";
          border: none;
          outline: none;
          border-radius: rem(1);
          text-transform: uppercase;
          padding: rem(7) rem(15);
          position: absolute;
          bottom: rem(35);
          right: rem(45);

          @include respond(mobile) {
            bottom: rem(100);
            right: rem(30);
          }

          @include respond(mediumMobile) {
            bottom: rem(80);
            right: rem(30);
          }
        }
      }

      .slick-slider {
        width: 100%;
        overflow: hidden;

        .slick-list {

          // width: 77vw;
          .slick-active {
            width: calc(100vw - 100px) !important;

            @include respond(smallmobile) {
              width: calc(100vw - 54px) !important;
            }
          }

          .slick-track {
            display: flex;
          }
        }

        .slick-dots {
          bottom: 0px;
          display: flex !important;
          align-items: center;
          justify-content: center;
          gap: 4px;
          margin: 36px 16px 0px 0px;

          li {
            line-height: 0px;
            display: flex;
            justify-content: center;

            button {
              font-size: 0px;
              width: 8px;
              height: 8px;
              background: #d6b997;
              border-radius: 100%;
              padding: 0px;
              box-shadow: none;
              border: 0px;

              &::before {
                display: none;
              }
            }

            &.slick-active {
              button {
                width: 29px;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.nikfaldo-details-main-section {
  display: block;
  padding: 55px 5vw 0px;
  background: #0a0a0a;

  @include respond(mediumMobile) {
    padding: 25px 16px;
  }

  .inner_logo_div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;

    .main_ogo_div {
      display: block;
      text-align: center;
      max-width: 287px;

      @include respond(mediumMobile) {
        max-width: 150px;
      }

      img {
        margin: 0px auto;
        width: 100% !important;
        height: auto !important;

        // &.line_img {
        //   margin: -120px auto 0px;
        //   width: 3px !important;
        //   height: 70px !important;

        //   @include respond(ipadProMobile) {
        //     margin: -80px auto 0px;
        //     width: 3px !important;
        //     height: 50px !important;
        //   }

        //   @include respond(mobile) {
        //     margin: -50px auto 0px;
        //     height: 45px !important;
        //   }

        //   @include respond(mediumMobile) {
        //     margin: -60px auto 0px;
        //     height: 40px !important;
        //   }
        // }
      }
    }
  }

  .nikfaldo_detail_inner_div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;

    .main_detail_div {
      display: block;

      h4 {
        font-family: "EB Garamond";
        font-size: rem(58);
        font-weight: 700;
        line-height: rem(60);
        color: #000000;
        margin: 0px 0px rem(60);
        color: #ffffff;
        text-transform: uppercase;

        @include respond(ipadProMobile) {
          font-size: rem(50);
          line-height: rem(50);
          margin: 0px 0px rem(50);
        }

        @include respond(ipadMobile) {
          font-size: rem(38);
          line-height: rem(40);
          margin: 0px 0px rem(20);
        }

        @include respond(mobile) {
          font-size: rem(32);
          line-height: rem(32);
          margin: 0px 0px rem(24);
        }
      }

      p {
        font-family: "Raleway";
        font-size: rem(20);
        font-weight: 400;
        line-height: rem(29);
        letter-spacing: 0.04em;
        color: #ffffff;
        margin: 0px;
        // display: ruby;

        &.less_text {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }

        &.more_text {
          overflow: auto;
        }

        @include respond(ipadMobile) {
          font-size: rem(18);
          line-height: rem(25);
        }

        @include respond(mobile) {
          font-size: rem(12);
          line-height: rem(18);
        }

      }

      .read_more_nic_btn {
        width: auto;
        height: auto;
        margin: rem(10) 0 0 rem(5);
        background: transparent;
        box-shadow: none;
        color: #FFFFFF;
        border: 0;
        padding: 0;
        text-decoration: underline;
        cursor: pointer;
        font-family: "Raleway";
        font-size: rem(20);
        font-weight: 400;
        line-height: rem(29);
        letter-spacing: 0.04em;
      }

      .black_bull_logos_section {
        display: flex;
        align-items: center;
        gap: rem(32);
        margin-top: rem(64);

        @include respond(ipadMobile) {
          margin-top: rem(24);
        }

        @include respond(mobile) {
          margin-bottom: rem(6);
        }

        img {
          &.black_bull_img {
            width: 20vw !important;

            @include respond(mobile) {
              width: 35vw !important;
            }
          }

          &.nick_sign_img {
            width: 16vw !important;

            @include respond(mobile) {
              width: 26vw !important;
            }
          }

          &.close_icon {
            width: rem(24) !important;
          }
        }
      }
    }
  }
}

.social_media_present_section {
  display: block;
  padding: 68px 0px 135px 0px;

  @include respond(mobile) {
    padding: 40px 0px;
  }

  h2 {
    font-family: "EB Garamond";
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
    color: #8e6a4a;
    margin-bottom: 68px;
    text-align: center;

    @include respond(mobile) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }

  .slick-slider {
    width: 100%;
    overflow: hidden;

    .slick-list {
      .slick-track {
        display: flex;
      }
    }

    .slick-dots {
      bottom: -0px;
      display: none !important;

      li {

        &:hover,
        &.slick-active {
          button {
            &::before {
              font-size: 20px;
              color: #d7c397;
              opacity: 1;
            }
          }
        }

        button {
          &::before {
            font-size: 14px;
            color: #696969;
            opacity: 0.7;
          }
        }
      }
    }
  }

  .social_media_present_collection_card {
    display: block;
    width: 100%;
    height: 26vw;
    max-width: 21vw;
    padding: 0px 2px;

    @include respond(ipadMobile) {
      height: 39vw;
      max-width: 31vw;
    }

    @include respond(mobile) {
      height: 31.4vw;
      max-width: 31.4vw;
    }

    .social_media_present_collection_details {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid transparent;

      &:hover {
        border: 3px solid #c99f53;

        .social_media_icon_div {
          background: rgba(0, 0, 0, 0.67);
          display: flex;
        }
      }

      .social_media_icon_div {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding: 0px 16px;
        display: none;

        .inner_div {
          text-align: center;

          h3 {
            text-align: center;
            font-family: "Raleway";
            font-size: 1vw;
            font-weight: 400;
            line-height: 15.26px;
            letter-spacing: 0.08em;
            margin: 0px 0px 1.5vw;
            color: #faf9f7;
          }

          a {
            border: 0px;
            outline: none;
            box-shadow: none;
          }

          img {
            width: 5vw !important;
            height: 5vw !important;
            margin: 0px auto;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.range_details_section {
  display: block;
  position: relative;
  position: relative;

  .top_bar_text {
    display: block;
    text-align: center;
    background: #8e6a4a;
    font-family: "EB Garamond";
    font-size: rem(40);
    font-weight: 700;
    line-height: rem(60);
    letter-spacing: 0.01em;
    margin: 0;
    padding: rem(19);
    color: #ffffff;
    text-transform: uppercase;

    @include respond(mobile) {
      font-size: rem(32);
      line-height: rem(32);
      padding: rem(24);
    }
  }

  .whisky_card_btn {
    background-color: #a47853;
    color: #ffffff;
    font-size: rem(13);
    font-weight: 500;
    letter-spacing: rem(1);
    font-family: "Roboto";
    border: none;
    outline: none;
    border-radius: rem(1);
    text-transform: uppercase;
    position: absolute;
    padding: rem(18) rem(48);
    bottom: rem(60);
    left: 37.4%;

    @include respond(laptopdesktop) {
      bottom: rem(40);
      padding: rem(10) rem(25);
    }

    @include respond(ipad) {
      font-size: rem(10);
      letter-spacing: rem(0.5);
      padding: rem(12) rem(30);
      bottom: rem(25);
      left: 37.4%;
    }

    @include respond(mobile) {
      padding: rem(7) rem(15);
      font-size: rem(8);
      letter-spacing: rem(0.5);
      bottom: rem(30);
      left: 13%;
    }
  }

  .black_bull_label {
    display: block;
    padding: 61px 10px 40px;
    text-align: center;

    @include respond(mobile) {
      padding: 40px 16px;
    }

    .black_bull_main_logo {
      width: 100% !important;
      max-width: 468px !important;
      height: auto !important;

      @include respond(mobile) {
        max-width: 233px !important;
        margin-bottom: 24px;
      }
    }

    h2 {
      font-family: "EB Garamond";
      font-size: 40px;
      font-weight: 500;
      line-height: 56px;
      text-align: center;
      color: #8e6a4a;
      text-transform: uppercase;
      margin: 4px 0px 0px;

      @include respond(mobile) {
        font-size: 24px;
        line-height: 32px;
        margin: 0px 0px 20px 0px;
      }
    }

    p {
      font-family: "Raleway";
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.04em;
      text-align: center;
      color: #4d4d4d;
      max-width: 828px;
      margin: 0px auto;

      @include respond(mobile) {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .kyloe_whisky_type_section {
    display: block;
    // border-bottom: 10px solid #a57941;
    background-repeat: no-repeat;
    background-image: url("/new-blackbull-collection/kyloe-range-bg.jpg");
    background-position: center;
    background-size: cover;

    @include respond(mobile) {
      background-image: url("/new-blackbull-collection/kyloe-range-bg-mobile.jpg");
      background-position: top center;
    }

    .for_black_shadow {
      height: 100%;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0) 50%, #000000 77.77%);
      width: 100%;

      @include respond(mobile) {
        background: transparent;
      }

      .kyloe_details {
        display: block;
        padding: 80px 5vw 80px;

        @include respond(mobile) {
          padding: rem(24) rem(24) rem(108);
        }

        .mobile-kyloe-img {
          display: none;

          @include respond(mobile) {
            display: block;
          }
        }

        .kyloe_img_with_text_div {
          display: flex;
          align-items: center;
          gap: rem(80);

          @include respond(mobile) {
            display: block;
          }

          .kylo_main_logo {
            max-width: rem(266) !important;
            margin-bottom: 16px;

            @include respond(mobile) {
              display: block;
              text-align: center;
              max-width: rem(255) !important;
              height: auto !important;
              margin: 0 auto rem(32);
            }

            @include respond(smallmobile) {
              max-width: rem(200) !important;
            }
          }

          p {
            font-family: "Raleway";
            font-size: rem(16);
            font-weight: 600;
            line-height: rem(24);
            text-align: left;
            color: #ffffff;
            max-width: rem(700);
            margin: 0px;

            @include respond(mobile) {
              font-size: rem(24);
              line-height: rem(24);
              padding: 0 0 rem(33);
              max-width: rem(790);
            }

            @include respond(smallmobile) {
              font-size: rem(18);
              line-height: rem(24);
            }
          }
        }

        .kyloe_card_section_slider {
          display: block;

          .slick-slider {
            margin: 0 -12px;

            @include respond(mobile) {
              margin: 0 -24px 0 0;
            }

            @include respond(ipadProMobile) {
              padding: 0 rem(30);
            }

            @include respond(mobile) {
              padding: 0;
            }

            .slick-list {
              .slick-track {
                .slick-slide {
                  padding: 0 12px;

                  @include respond(mobile) {
                    padding: 0;
                  }

                  &.slick-active {
                    @include respond(mobile) {
                      width: calc(100vw - 150px) !important;
                    }

                    @include respond(mediumMobile) {
                      width: calc(100vw - 80px) !important;
                    }
                  }
                }
              }
            }

            .slick-arrow {
              width: rem(40);
              height: rem(40);

              @include respond(mediumMobile) {
                width: rem(30);
                height: rem(30);
              }
            }

            .slick-prev {
              left: -15px;

              @include respond(mobile) {
                left: 0;
                z-index: 1;
              }

              &::before {
                color: transparent;
                font-size: rem(40);
                background-image: url("/new-blackbull-collection/round-arrow-aposit.png");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;

                @include respond(mediumMobile) {
                  font-size: rem(30);
                }
              }
            }

            .slick-next {
              right: -15px;

              @include respond(mobile) {
                right: 20px;
              }

              &::before {
                color: transparent;
                font-size: rem(40);
                background-image: url("/new-blackbull-collection/round-arrow.png");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;

                @include respond(mediumMobile) {
                  font-size: rem(30);
                }
              }
            }

            .kyloe_card {
              position: relative;

              @include respond(mobile) {
                height: 100vw;
              }

              @include respond(mediumMobile) {
                height: 100vw;
              }

              .kylo_card_img {
                @include respond(mobile) {
                  padding-right: rem(24);
                }

                &.for_desktop {
                  display: block;

                  @include respond(mobile) {
                    display: none;
                  }
                }

                &.for_mobile {
                  display: none;

                  @include respond(mobile) {
                    display: block;
                  }
                }
              }

              .kylo_btn_logo_section {
                position: absolute;
                top: 2vw;
                left: rem(40);
                width: 13vw;
                height: calc(100% - 2vw);
                display: flex;
                flex-wrap: wrap;
                align-content: space-between;
                padding-bottom: 2.5vw;

                @include respond(ipadProMobile) {
                  width: 17vw;
                }

                @include respond(mobile) {
                  width: 76vw;
                  top: 8vw;
                  height: calc(100% - 15vw);
                  left: 0;
                  padding-right: 24px;
                }

                @include respond(mediumMobile) {
                  height: calc(100% - 12vw);
                }

                .top_section {
                  display: block;

                  @include respond(mobile) {
                    max-width: 39vw;
                    padding-left: rem(24);
                  }

                  .kylo_logo_in_card {
                    width: 100% !important;
                    height: auto !important;
                  }

                  h2 {
                    font-family: "EB Garamond";
                    font-size: rem(28);
                    font-weight: 800;
                    line-height: rem(30);
                    text-align: left;
                    margin: 0;
                    color: #000000;
                    text-transform: uppercase;
                    margin-top: rem(6);

                    @include respond(mediumMobile) {
                      font-size: rem(16);
                      line-height: rem(19);
                    }
                  }
                }

                .bottom_section {
                  display: block;
                  width: 100%;

                  .whisky_card_btn_new {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: rem(40);
                    border-radius: rem(4);
                    font-family: "Raleway";
                    font-size: rem(18);
                    font-weight: 500;
                    line-height: normal;
                    text-align: center;
                    background: #ffff;
                    color: #0b0a0a;
                    padding: rem(2);
                    border: 0;
                    outline: none;
                    box-shadow: none;

                    @include respond(mobile) {
                      width: rem(247);
                      height: rem(40);
                      // font-size: rem(14);
                      margin: 0 auto;
                    }

                    @include respond(mediumMobile) {
                      width: rem(147);
                      height: rem(39);
                      font-size: rem(14);
                      margin: 0 auto;
                    }
                  }
                }
              }
            }
          }
        }

        .kyloe_card_section {
          display: flex;
          gap: 17px;
          margin-top: 36px;

          &.for_desktop {
            @include respond(mobile) {
              display: none;
            }
          }

          &.for_mobile {
            display: none;

            @include respond(mobile) {
              display: flex;
              margin-top: 0px;
              padding: 0px 0px 0px 16px;
            }

            .slick-slider {
              width: 100%;
              overflow: hidden;

              .slick-list {
                // width: 71vw;

                .slick-active {
                  width: calc(100vw - 100px) !important;
                }

                .slick-track {
                  display: flex;
                }
              }

              .slick-dots {
                bottom: 0px;
                display: flex !important;
                align-items: center;
                justify-content: center;
                gap: 4px;
                margin: 40px 16px 0px 0px;

                li {
                  line-height: 0px;
                  display: flex;
                  justify-content: center;

                  button {
                    font-size: 0px;
                    width: 8px;
                    height: 8px;
                    background: #d6b997;
                    border-radius: 100%;
                    padding: 0px;
                    box-shadow: none;
                    border: 0px;

                    &::before {
                      display: none;
                    }
                  }

                  &.slick-active {
                    button {
                      width: 29px;
                      border-radius: 5px;
                    }
                  }
                }
              }
            }
          }

          // .kyloe_card {
          //   display: block;
          //   cursor: pointer;

          //   .kylo_card_img {
          //     height: auto !important;
          //     max-width: 15vw;

          //     @include respond(mobile) {
          //       height: 100vw !important;
          //       max-width: calc(100% - 20px);
          //     }

          //     @include respond(mediumMobile) {
          //       height: 80vw !important;
          //       max-width: calc(100% - 20px);
          //     }
          //   }

          //   h2 {
          //     font-family: "Alfa Slab One";
          //     font-size: 17.3px;
          //     font-weight: 400;
          //     line-height: 23.69px;
          //     text-transform: uppercase;
          //     background: radial-gradient(100% 250.08% at 0% 50%,
          //         #ffd59f 0%,
          //         #ca974a 36.99%,
          //         #ffcfa4 67.58%,
          //         #d59255 100%,
          //         #e19e61 100%);
          //     -webkit-background-clip: text;
          //     -webkit-text-fill-color: transparent;
          //     margin: 0px;
          //     padding-top: 21px;
          //     max-width: 16vw;

          //     @include respond(mobile) {
          //       padding-top: 15px;
          //       max-width: 93%;
          //       margin-bottom: 30px;
          //     }

          //     @include respond(smallmobile) {
          //       font-size: 14px;
          //     }
          //   }

          //   &:nth-child(even) {
          //     h2 {
          //       font-family: "Alfa Slab One";
          //       font-size: 17.3px;
          //       font-weight: 400;
          //       line-height: 23.69px;
          //       text-transform: uppercase;
          //       background: radial-gradient(73.45% 53.65% at 54.15% 46.35%,
          //           #ffe3a7 0%,
          //           #fbf3dd 26.56%,
          //           #f6d59a 73.44%,
          //           #f4eed2 93.23%);
          //       -webkit-background-clip: text;
          //       -webkit-text-fill-color: transparent;
          //       margin: 0px;
          //       padding-top: 21px;
          //     }
          //   }
          // }
        }
      }
    }
  }
}

.black_bull_racing_section {
  display: block;

  h2 {
    font-family: "EB Garamond";
    font-size: 48px;
    font-weight: 600;
    line-height: 62.64px;
    text-align: center;
    text-transform: uppercase;
    padding: rem(76) rem(10) rem(40);

    @include respond(mobile) {
      font-size: 24px;
      line-height: 31px;
      padding: 30px 16px;
    }
  }

  .racing_car_section {
    display: block;
    padding: rem(64) 5vw rem(80);

    @include respond(ipadMobile) {
      padding: rem(64) 0 0;
    }

    @include respond(mobile) {
      padding: rem(40) 0 0;
    }

    .racing_career_details {
      display: flex;
      gap: rem(80);
      justify-content: center;
      margin-bottom: rem(80);

      @include respond(ipadMobile) {
        display: block;
        gap: rem(40);
        margin-bottom: rem(64);
        padding: 0 rem(24);
      }

      @include respond(mobile) {
        margin-bottom: rem(40);
      }

      h2 {
        font-family: "Raleway";
        font-size: rem(32);
        font-weight: 600;
        line-height: rem(37);
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #8e6a4a;
        text-align: left;
        min-width: rem(462);
        margin: 0;
        padding: 0;

        @include respond(ipadMobile) {
          min-width: rem(342);
          margin-bottom: rem(24);
        }

        @include respond(mobile) {
          min-width: 100%;
          font-size: rem(24);
          line-height: rem(28);
        }
      }

      p {
        font-family: "Raleway";
        font-size: rem(20);
        font-weight: 400;
        line-height: rem(31);
        text-align: left;
        margin: 0;

        @include respond(mobile) {
          font-size: rem(12);
          line-height: rem(18);
        }
      }
    }

    .career_details {
      margin-top: rem(80);
      margin-right: rem(80);
      max-width: rem(400);

      @include respond(ipadProMobile) {
        margin-top: rem(40);
        margin-right: rem(40);
        max-width: rem(320);
      }

      @include respond(ipadMobile) {
        max-width: 100%;
        margin-right: 0;
        margin-top: 0;
        background: #0a0a0a;
        padding: rem(12) rem(24) rem(42);
      }

      &.for_desktop {
        display: block;

        @include respond(ipadMobile) {
          display: none;
        }
      }

      &.for_mobile {
        display: none;

        @include respond(ipadMobile) {
          display: block;
        }
      }

      h5 {
        font-family: "EB Garamond";
        font-size: rem(32);
        line-height: rem(41);
        font-weight: 700;
        color: #e1cb9e;
        text-align: left;
        text-transform: uppercase;
        margin: 0 0 rem(20);

        @include respond(ipadProMobile) {
          font-size: rem(28);
          line-height: rem(38);
          margin: 0 0 rem(16);
        }

        @include respond(mobile) {
          font-size: rem(24);
          line-height: rem(28);
          margin: 0 0 rem(24);
        }
      }

      h6 {
        font-family: "Raleway";
        font-size: rem(20);
        font-weight: 300;
        line-height: rem(32);
        text-align: left;
        color: #ffffff;

        @include respond(ipadProMobile) {
          font-size: rem(18);
          line-height: rem(28);
        }

        @include respond(mobile) {
          font-size: rem(12);
          line-height: rem(18);
          margin: 0;
        }
      }
    }

    .racing_career_banner {
      position: relative;

      .banner_img_car {
        width: 100% !important;
        height: auto !important;
      }

      .racing_content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding: rem(32) 0;

        @include respond(ipadMobile) {
          padding: rem(24) 0;
          height: auto;
        }

        .career_data_section {
          width: rem(199);

          @include respond(ipadMobile) {
            width: rem(177);
          }

          .prix_name {
            width: rem(199);
            height: rem(58);
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(359.93deg,
                #735839 0.03%,
                #b3936d 50.04%,
                #6e5435 98.02%);
            font-family: "Raleway";
            font-size: rem(18);
            font-weight: 900;
            line-height: rem(21);
            color: #ffffff;

            @include respond(ipadMobile) {
              width: rem(177);
              height: rem(45);
              font-size: rem(16);
              line-height: rem(18);
            }

            @include respond(mobile) {
              margin-bottom: rem(10);
            }
          }

          .content_data {
            display: block;
            text-align: center;
            margin: rem(24) 0;
            padding: rem(16);

            @include respond(laptopdesktop) {
              padding: rem(8);
            }

            @include respond(ipadProMobile) {
              margin: rem(14) 0;
            }

            @include respond(ipadMobile) {
              margin: rem(11) 0 0;
              padding: rem(13);
            }

            @include respond(mobile) {
              margin: rem(6);
              max-width: rem(78);
              padding: 0;
            }

            h3 {
              font-family: "EB Garamond";
              font-size: rem(42);
              font-weight: 700;
              line-height: rem(54);
              color: #e1cb9e;
              margin: 0 0 rem(13);

              @include respond(ipadProMobile) {
                font-size: rem(38);
                line-height: rem(45);
                margin: 0 0 rem(8);
              }

              @include respond(ipadMobile) {
                font-size: rem(32);
                line-height: rem(41);
                margin: 0;
              }
            }

            h4 {
              font-family: "Raleway";
              font-size: rem(18);
              font-weight: 400;
              line-height: rem(21);
              margin: 0;
              color: #ffffff;

              @include respond(ipadMobile) {
                font-size: rem(12);
                line-height: rem(14);
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  .racing_details_section {
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond(mobile) {
      display: block;
    }

    &.background_bg {
      height: 100%;
      min-height: 580px;
      background-repeat: no-repeat;
      background-image: url("/new-blackbull-collection/race-bg.png");
      background-position: center;
      background-size: cover;

      @include respond(ipadMobile) {
        min-height: 389px !important;
      }

      @include respond(mobile) {
        background-image: url("/new-blackbull-collection/race-bg-mobile.png");
        background-position: top center;
        min-height: 100% !important;
      }
    }

    &.top_fall {
      margin-top: -8px;
    }

    .details_section {
      width: 50%;
      background-repeat: no-repeat;
      background-image: url("/new-blackbull-collection/black-car-bg.png");
      background-position: left center;
      background-size: cover;

      @include respond(mobile) {
        width: 100%;
        background-image: url("/new-blackbull-collection/black-car-bg-mobile.png");
        background-position: top center;
      }

      .sponsors_details {
        height: 594px;
        padding: 75px 50px;
        display: flex;
        align-items: center;

        @include respond(ipadMobile) {
          height: 389px;
          padding: 30px;
        }

        @include respond(mobile) {
          height: auto;
          display: block;
          padding: 40px 24px;
        }

        .details_manage_div {
          h2 {
            text-align: left;
            font-family: "EB Garamond";
            font-size: 32px;
            font-weight: 600;
            line-height: 45.6px;
            color: #b6995e;
            padding: 0px;
            margin: 0px 0px 8px;
            text-transform: uppercase;

            @include respond(ipadMobile) {
              font-size: 2.5vw;
              line-height: normal;
            }

            @include respond(mobile) {
              font-size: 24px;
              line-height: 34px;
              margin: 0px 0px 20px;
            }
          }

          h3 {
            font-family: "EB Garamond";
            font-size: 16px;
            font-weight: 600;
            line-height: 22.8px;
            text-align: left;
            color: #ffffff;
            margin: 0px 0px 20px;
            text-transform: uppercase;

            @include respond(ipadMobile) {
              font-size: 1.25vw;
              line-height: normal;
              margin: 0px 0px 10px;
            }

            @include respond(mobile) {
              font-size: 16px;
              line-height: 22px;
              margin: 0px 0px 20px;
            }
          }

          p {
            font-family: "Raleway";
            font-size: 16px;
            font-weight: 400;
            line-height: 21.34px;
            text-align: left;
            color: #ffffff;
            margin: 0px 0px 50px;

            @include respond(ipadMobile) {
              font-size: 1.25vw;
              line-height: 1.4;
              margin: 0px 0px 20px;
            }

            @include respond(mobile) {
              font-size: 14px;
              line-height: 18px;
              margin: 0px 0px 50px;
            }
          }

          h4 {
            font-family: "Baskerville BT";
            font-size: 21px;
            font-style: italic;
            font-weight: 400;
            line-height: 25.79px;
            text-align: left;
            margin: 0px;
            color: #ffffff;

            @include respond(ipadMobile) {
              font-size: 2vw;
              line-height: 1.4;
            }

            @include respond(mobile) {
              font-size: 18px;
              line-height: 22px;
            }
          }

          h5 {
            font-family: "EB Garamond";
            font-size: 21px;
            font-weight: 600;
            line-height: 27.4px;
            text-align: left;
            text-transform: uppercase;
            color: #ffffff;
            margin: 0px 0px 20px;

            @include respond(ipadMobile) {
              font-size: 1.5vw;
              line-height: normal;
              margin: 0px 0px 10px;
            }

            @include respond(mobile) {
              font-size: 16px;
              line-height: 22px;
              margin: 0px 0px 20px;
            }
          }

          h6 {
            font-family: "Raleway";
            font-size: 16px;
            font-weight: 400;
            line-height: 21.34px;
            text-align: left;
            color: #ffffff;
            margin: 0px;

            @include respond(ipadMobile) {
              font-size: 1.25vw;
              line-height: 1.4;
            }

            @include respond(mobile) {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
    }

    .car_slider_section {
      width: 100%;
      height: 100%;
      position: relative;

      @include respond(mobile) {
        width: 100%;
        background: #0a0a0a;
        padding-bottom: rem(32);
      }

      .car_slider_content {
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        @include respond(mobile) {
          position: relative;
          left: inherit;
          top: inherit;
        }

        .car_slider_details {
          width: rem(420);
          margin-left: rem(90);

          @include respond(ipadMobile) {
            width: rem(320);
            margin-left: rem(40);
          }

          @include respond(mobile) {
            width: 100%;
            margin-left: 0;
            padding: rem(60) rem(16) rem(24);
          }

          h2 {
            font-family: "EB Garamond";
            font-size: rem(60);
            font-weight: 700;
            line-height: rem(56);
            letter-spacing: 0.01em;
            color: #fdfbf6;
            margin: 0;
            padding: 0;
            text-transform: uppercase;
            text-align: left;

            @include respond(ipadMobile) {
              font-size: rem(40);
              line-height: rem(45);
            }

            @include respond(mobile) {
              font-size: rem(32);
              line-height: rem(32);
            }
          }

          h4 {
            font-family: "Raleway";
            font-size: rem(24);
            font-weight: 600;
            line-height: rem(28);
            color: #e1cb9e;
            margin: rem(40) 0 rem(32);
            text-transform: uppercase;

            @include respond(ipadMobile) {
              font-size: rem(18);
              line-height: rem(22);
              margin: rem(24) 0;
            }

            @include respond(mobile) {
              font-size: rem(24);
              line-height: rem(28);
            }
          }

          p {
            font-family: "Raleway";
            font-size: rem(20);
            font-weight: 400;
            line-height: rem(29);
            margin: 0;
            color: #ffffff;

            @include respond(ipadMobile) {
              font-size: rem(18);
              line-height: rem(22);
            }

            @include respond(mobile) {
              font-size: rem(12);
              line-height: rem(18);
              margin-bottom: rem(24);
            }
          }
        }
      }

      .slick-slider {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 740px;

        @include respond(ipadMobile) {
          height: rem(600) !important;
        }

        @include respond(mobile) {
          height: auto !important;
        }

        .car_slider_inner_div {
          position: relative;

          img {
            width: 100% !important;
            height: 740px !important;

            @include respond(ipadMobile) {
              height: rem(600) !important;
            }

            @include respond(mobile) {
              height: auto !important;
            }
          }
        }

        .slick-list {
          .slick-track {
            display: flex;
          }
        }

        .slick-arrow {
          position: absolute;
          top: inherit;
          bottom: rem(32);
          // top: 50%;
          // transform: translateX(-50%);
          z-index: 1;
          font-size: 0px;
          width: 40px;
          height: 40px;
          padding: 0px;
          border: 0px;
          background: transparent;

          @include respond(mobile) {
            width: 32px;
            height: 32px;
            top: 50%;
            transform: translateX(-50%);
          }

          &.slick-next {
            right: 46%;
            transform: translateX(-50%);
            z-index: 1;

            @include respond(ipadMobile) {
              right: 44%;
            }

            @include respond(mobile) {
              right: 0px;
            }

            &::before {
              content: "";
              background-repeat: no-repeat;
              background-image: url("/new-blackbull-collection/nex.png");
              display: inline-block;
              width: 40px;
              height: 40px;
              border: 0.25px solid #323232;
              font-size: 15px;
              background-color: #ffffff;
              background-position: center;
              background-size: 22px;

              @include respond(mobile) {
                width: 32px;
                height: 32px;
              }
            }
          }

          &.slick-prev {
            left: 46%;
            transform: translateX(-50%);
            z-index: 1;

            @include respond(ipadMobile) {
              left: 44%;
            }

            @include respond(mobile) {
              left: 32px;
            }

            &::before {
              content: "";
              background-repeat: no-repeat;
              background-image: url("/new-blackbull-collection/pre.png");
              display: inline-block;
              width: 40px;
              height: 40px;
              border: 2px solid #323232;
              background-color: #ffffff;
              background-position: center;
              background-size: 22px;

              @include respond(mobile) {
                width: 32px;
                height: 32px;
              }
            }
          }
        }

        .slick-dots {
          bottom: -0px;
          display: none !important;

          li {

            &:hover,
            &.slick-active {
              button {
                &::before {
                  font-size: 20px;
                  color: #d7c397;
                  opacity: 1;
                }
              }
            }

            button {
              &::before {
                font-size: 14px;
                color: #696969;
                opacity: 0.7;
              }
            }
          }
        }
      }
    }

    .race_driver_image {
      width: 50%;

      @include respond(mobile) {
        width: 100%;
      }

      img {
        width: 90% !important;
        height: 580px !important;

        &.race_desktop {
          display: block;
        }

        &.race_mobile {
          display: none;
        }

        @include respond(ipadMobile) {
          height: 389px !important;
        }

        @include respond(mobile) {
          width: 100% !important;
          height: 100% !important;

          &.race_desktop {
            display: none;
          }

          &.race_mobile {
            display: block;
          }
        }
      }
    }

    .racing_data_section {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;

      @include respond(mobile) {
        width: 100%;
        display: block;
        padding: 40px 16px;
      }

      .career_data {
        display: block;
        padding-right: 5vw;

        @include respond(ipadMobile) {
          padding-right: 30px;
        }

        @include respond(mobile) {
          padding-right: 0px;
        }

        h2 {
          font-family: "EB Garamond";
          font-size: 2.2vw;
          font-weight: 600;
          line-height: 41.76px;
          color: #d7c397;
          padding: 0px;
          margin: 0px 0px 20px;
          text-transform: uppercase;
          text-align: left;

          @include respond(ipadMobile) {
            line-height: normal;
            margin: 0px 0px 10px;
          }

          @include respond(mobile) {
            font-size: 20px;
            line-height: 26px;
            margin: 0px 0px 20px;
          }
        }

        p {
          font-family: "Raleway";
          font-size: 16px;
          font-weight: 300;
          line-height: 21.34px;
          color: #ffffff;
          text-align: left;
          margin: 0px 0px 35px;

          @include respond(ipadMobile) {
            font-size: 1.45vw;
            line-height: 1.4;
            margin: 0px 0px 20px;
          }

          @include respond(mobile) {
            font-size: 14px;
            line-height: 18px;
            margin: 0px 0px 36px;
          }
        }

        .total_data_section {
          display: flex;
          justify-content: space-around;
          padding-left: 38px;
          height: 184px;
          align-items: center;
          gap: 5px;
          position: relative;

          @include respond(ipadMobile) {
            padding-left: 25px;
            height: 120px;
          }

          @include respond(mobile) {
            padding-left: 38px;
            height: 198px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
          }

          .title_of_data_section {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Raleway";
            font-size: 18px;
            font-weight: 400;
            line-height: 21.13px;
            background: linear-gradient(359.93deg,
                #735839 0.03%,
                #b3936d 50.04%,
                #6e5435 98.02%);
            color: #ffffff;
            width: 184px;
            height: 38px;
            transform: rotate(-90deg);
            position: absolute;
            left: -73px;
            top: 73px;

            @include respond(ipadMobile) {
              width: 120px;
              height: 25px;
              font-size: 12px;
              line-height: 1;
              left: -48px;
              top: 48px;
            }

            @include respond(mobile) {
              width: 198px;
              height: 38px;
              font-size: 14px;
              left: -80px;
              top: 80px;
            }
          }

          .card_of_data {
            display: block;
            height: auto;

            h3 {
              font-family: "DM Serif Text";
              font-size: 42px;
              font-weight: 400;
              line-height: 57.58px;
              text-align: center;
              background: linear-gradient(90deg,
                  #735839 0%,
                  #b3936d 51.04%,
                  #6e5435 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              margin: 0px;

              @include respond(ipadMobile) {
                font-size: 25px;
                line-height: normal;
              }

              @include respond(mobile) {
                font-size: 40px;
                line-height: 54px;
              }
            }

            h4 {
              font-family: "Raleway";
              font-size: 18px;
              font-weight: 400;
              line-height: 21.13px;
              text-align: center;
              color: #775c3d;
              margin: 12px 0px 0px;

              @include respond(ipadMobile) {
                margin: 5px 0px 0px;
                font-size: 10px;
                line-height: normal;
              }

              @include respond(mobile) {
                font-size: 16px;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
  }
}

.black_bull_new_details_section {
  padding: rem(59) 5vw rem(65);
  background-image: url("/new-blackbull-collection/black-bull-banner-bg-new.jpg");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-image: url("/new-blackbull-collection/doted-line.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 80%;
    height: rem(4);
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    @include respond(mobile) {
      display: none;
    }
  }

  @include respond(mobile) {
    background-image: url("/new-blackbull-collection/black-bull-banner-bg-new-mobile.jpg");
  }

  .inner_div {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: rem(30);
    position: relative;

    @include respond(ipadMobile) {
      flex-wrap: wrap;
    }

    .black_bull_content {
      display: block;
      width: 40%;
      margin-bottom: rem(120);

      @include respond(ipadMobile) {
        width: 100%;
        margin-bottom: 0;
        order: 1;
      }

      .black_bull_details {
        display: block;

        h3 {
          font-family: "EB Garamond";
          font-size: rem(60);
          font-weight: 700;
          line-height: rem(56);
          letter-spacing: 0.01em;
          color: #ffffff;
          text-transform: uppercase;
          margin: 0;

          @include respond(mediumMobile) {
            font-size: rem(32);
            line-height: rem(32);
          }
        }

        h4 {
          font-family: "EB Garamond";
          font-size: rem(50);
          font-weight: 700;
          line-height: rem(56);
          letter-spacing: 0.01em;
          color: #ffffff;
          margin: 0 0 rem(40);
          text-transform: uppercase;

          @include respond(mediumMobile) {
            font-size: rem(32);
            line-height: rem(32);
            margin: 0 0 rem(24);
          }
        }

        p {
          font-family: "Raleway";
          font-size: rem(16);
          font-weight: 400;
          line-height: rem(32);
          margin: 0;
          color: #ffffff;

          @include respond(mediumMobile) {
            font-size: rem(12);
            line-height: rem(18);
          }
        }

        .read_more_btn_text_new {
          display: none;

          @include respond(mobile) {
            display: block;
            font-family: "Raleway";
            font-size: rem(12);
            font-weight: 900;
            line-height: rem(18);
            color: #ffffff;
            position: relative;
            cursor: pointer;
            width: fit-content;

            &::after {
              content: "";
              position: absolute;
              width: 100%;
              height: rem(1);
              background: #fff;
              left: 0;
              bottom: 0;
            }
          }
        }

        .black_bull_learn_more_btn {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #0b0a0a;
          background: #ffffff;
          font-family: "Raleway";
          font-size: rem(18);
          font-weight: 500;
          line-height: normal;
          width: rem(183);
          height: rem(40);
          margin-top: rem(40);
          padding: 0;

          @include respond(mobile) {
            position: absolute;
            bottom: -35px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    .black_bull_bottle_img_box {
      display: block;
      width: rem(256);

      @include respond(ipadMobile) {
        width: 100%;
        order: 3;
      }

      img {
        width: rem(256) !important;
        height: auto !important;

        @include respond(ipadMobile) {
          display: flex;
          margin: 0 auto rem(60);
        }

        @include respond(mediumMobile) {
          width: rem(110) !important;
        }
      }
    }

    .black_bull_banner_img {
      width: 40%;
      margin-bottom: rem(100);

      img {
        @include respond(desktop) {
          padding-bottom: rem(60);
        }

      }

      @include respond(ipadMobile) {
        width: 100%;
        margin-bottom: 0;
        order: 2;
      }
    }
  }
}

.pt-0 {
  margin-top: 0 !important;
}

@media screen and (max-width: 575px) {
  .nikfaldo-details-main-section {
    padding: 40px 16px 0px;

    .first_mobile_first {
      order: 1;
    }

    .second_mobile_order {
      order: 2;
    }

    .third_mobile_third {
      order: 3;
    }

    .inner_logo_div {
      padding-bottom: 26px;

      .main_ogo_div {
        max-width: 177px;
      }
    }

    .nikfaldo_detail_inner_div {
      padding-bottom: 0px;

      // .main_detail_div {
      //   h4 {
      //     font-size: 20px;
      //     line-height: 26px;
      //     margin: 0px 16px 8px;
      //   }

      //   p {
      //     text-align: justify;
      //     font-size: 14px;
      //     line-height: 20px;
      //     padding: 0px 16px;
      //   }
      // }
    }
  }
}