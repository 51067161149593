.privacy_policy_section {
    background-color: #FFFFFF;
    max-width: 100%;
    padding-top: rem(150);
    padding-bottom: rem(24);

    @include respond(desktop) {
        padding-top: rem(150);
    }

    @include respond(ipadMobile) {
        padding-top: rem(200);
    }

    @include respond(mediumMobile) {
        padding-top: rem(150);
    }

    .sub_header {
        font-family: 'Raleway';
        font-size: rem(26);
        font-weight: 500;
        line-height: rem(31);
        margin: 0 0 rem(25);
        color: #000000;
        text-align: center;

        @include respond(smallmobile) {
            font-size: rem(18);
            line-height: rem(25);
        }
    }

    .main_heading {
        font-family: "EB Garamond";
        font-size: rem(34);
        font-weight: 400;
        line-height: rem(39);
        color: #775c3d;
        text-transform: uppercase;
        margin: 0 0 rem(10);
        text-align: center;

        @include respond(mediumMobile) {
            font-size: rem(30);
        }

        @include respond(smallmobile) {
            font-size: rem(28);
        }
    }

    .lead {
        font-family: "EB Garamond";
        font-size: rem(32);
        font-weight: 400;
        line-height: rem(39);
        text-align: left;
        color: #000000;
        // text-transform: uppercase;
        margin: 0 0 rem(24);

        @include respond(mediumMobile) {
            font-size: rem(26);
            line-height: rem(30);
        }

        @include respond(smallmobile) {
            font-size: rem(20);
            line-height: rem(26);
        }
    }

    p {
        font-family: 'Raleway';
        font-size: rem(20);
        font-weight: 400;
        line-height: rem(31);
        margin: 0 0 rem(20);
        color: #000000;

        @include respond(mediumMobile) {
            font-size: rem(18);
            line-height: rem(25);
        }

        @include respond(smallmobile) {
            font-size: rem(14);
            line-height: rem(21);
        }

        a {
            color: #000000;
            text-decoration: underline;
            padding: 0 rem(5);
        }
    }

    ul {
        list-style-type: disc;
        margin-left: rem(35);

        &.round_mark {
            list-style-type: circle;

            li {
                margin: 0 0 rem(5);
            }
        }

        li {
            font-family: 'Raleway';
            font-size: rem(20);
            font-weight: 400;
            line-height: rem(31);
            margin: 0 0 rem(14);
            color: #000000;

            @include respond(smallmobile) {
                font-size: rem(14);
                line-height: rem(21);
            }

            strong {
                padding-right: rem(5);
            }
        }
    }

    .table_upper_div {
        @include respond(mediumMobile) {
            width: 95vw;
            margin: 0 auto;
            overflow-y: auto;
        }
    }

    table {
        width: 100%;

        tr {
            td {
                border: rem(2) solid #000000;
                padding: rem(20);

                @include respond(mediumMobile) {
                    padding: rem(10);
                }

                @include respond(smallmobile) {
                    padding: rem(10);
                }
            }
        }
    }
}