.scroll_img {
  width: rem(56);
  height: rem(88);
  margin-bottom: -40px;
  cursor: pointer;

  @include respond(mobile) {
    width: rem(40);
    height: rem(62);
    margin-bottom: -40px;
  }

  @include respond(smallmobile) {
    width: rem(30);
    height: rem(47);
    margin-bottom: -50px;
  }
}

.main_title_section {
  display: block;
  background: #000000;
  padding: rem(80) 0 rem(55);
  text-align: center;

  @include respond(mobile) {
    padding: rem(24) 0;
  }

  h2 {
    font-family: "EB Garamond";
    font-size: rem(32);
    font-weight: 400;
    line-height: rem(41);
    text-align: center;
    margin: 0;
    color: #e9ce9d;
    text-transform: uppercase;

    &.nick_title {
      padding-bottom: rem(40);

      @include respond(mobile) {
        padding-bottom: 0;
      }
    }

    @include respond(mobile) {
      font-size: rem(24);
      line-height: rem(32);
      max-width: rem(314);
      margin: 0 auto;
    }
  }
}

.end_of_brand_box {
  background: #000000;
  padding: rem(0) rem(0) rem(80);

  @include respond(mobile) {
    padding: 0 0 rem(84);
  }
}

.brand_details_section {
  display: block;
  background: #000000;

  .brand_details_inner_div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include respond(mobile) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }

    &.left_side_box {
      .brand_details {
        order: 2;
        padding: rem(15) rem(0) rem(0) rem(61);

        @include respond(ipadMobile) {
          padding: rem(16);
        }

        @include respond(mobile) {
          padding: rem(24) 0;
          order: inherit;
        }
      }

      .brand_img {
        order: 1;

        @include respond(mobile) {
          order: inherit;
        }
      }
    }

    .brand_details {
      display: flex;
      align-items: center;
      height: 100%;
      padding: rem(15) rem(61) rem(0) rem(0);

      @include respond(ipadMobile) {
        padding: rem(16);
      }

      @include respond(mobile) {
        padding: rem(24) 0 rem(40);
      }

      .content {
        display: block;

        h2 {
          font-family: "EB Garamond";
          font-size: rem(32);
          font-weight: 600;
          line-height: rem(39);
          text-align: left;
          margin: 0;
          color: #e9ce9d;
          text-transform: uppercase;

          @include respond(ipadMobile) {
            font-size: rem(24);
            line-height: rem(35);
          }

          @include respond(mobile) {
            font-size: rem(20);
            line-height: rem(24);
          }
        }

        p {
          font-family: "Raleway";
          font-size: rem(18);
          font-weight: 400;
          line-height: rem(22);
          text-align: left;
          color: #adadad;
          margin: 0;
          padding-top: rem(22);

          @include respond(mobile) {
            font-size: rem(14);
            line-height: rem(22);
            padding-top: rem(10);
          }
        }
      }
    }
  }

  .brand_img {
    display: block;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.dt_hospitality_section {
  display: block;
  background: #000000;

  @include respond(smallmobile) {
    padding-bottom: rem(24);
  }

  .dt_hospitality_title {
    display: block;
    text-align: center;
    padding: rem(50);

    @include respond(mobile) {
      padding: rem(45) 0;
    }

    h2 {
      margin: 0;
      font-family: "EB Garamond";
      font-size: rem(32);
      font-weight: 700;
      line-height: rem(41);
      text-align: center;
      color: #e9ce9d;
      text-transform: uppercase;

      @include respond(mobile) {
        font-size: rem(24);
        line-height: rem(32);
      }
    }
  }

  .dt_hospitality_img_boxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(12);

    @include respond(mobile) {
      gap: rem(4);
    }

    @include respond(smallmobile) {
      gap: rem(12);
      grid-template-columns: repeat(1, 1fr);
    }

    .dt_card {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 37.5vw;
      position: relative;

      @include respond(ipadMobile) {
        height: 50vw;
      }

      @include respond(smallmobile) {
        height: 60vw;
      }

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(
          179.89deg,
          rgba(0, 0, 0, 0) 42.88%,
          rgba(0, 0, 0, 0.8) 99.9%
        );

        @include respond(mobile) {
          background: linear-gradient(
              179.89deg,
              rgba(0, 0, 0, 0) 42.88%,
              rgba(0, 0, 0, 0.8) 99.9%
            ),
            linear-gradient(
              179.89deg,
              rgba(0, 0, 0, 0) 42.88%,
              rgba(0, 0, 0, 0.8) 99.9%
            );
        }
      }

      .dt_card_content {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: rem(30);
        max-width: rem(835);
        z-index: 1;

        @include respond(mobile) {
          padding: rem(16);
        }

        h3 {
          margin: 0;
          font-family: "EB Garamond";
          font-size: rem(32);
          font-weight: 700;
          line-height: rem(41);
          color: #e9ce9d;
          text-transform: uppercase;

          @include respond(ipadMobile) {
            font-size: rem(24);
            line-height: rem(35);
          }

          @include respond(mobile) {
            font-size: rem(14);
            line-height: rem(18);
          }
        }

        p {
          margin: 0;
          font-family: "Raleway";
          font-size: rem(18);
          font-weight: 400;
          line-height: rem(21);
          color: #adadad;
          padding-top: rem(12);

          @include respond(mobile) {
            font-size: rem(10);
            line-height: rem(11);
            padding-top: rem(3);
          }
        }
      }
    }
  }
}

.experience_section {
  display: block;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180.36deg,
      rgba(0, 0, 0, 0) 45.85%,
      rgba(0, 0, 0, 0.8) 76.86%
    );
  }

  .exp_bg {
    width: 100%;
    height: auto;

    @include respond(mobile) {
      min-height: 55vw;
    }
  }

  .experience_content {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: rem(30);
    z-index: 1;
    max-width: rem(1291);

    @include respond(mobile) {
      padding: rem(16);
    }

    h2 {
      margin: 0;
      font-family: "EB Garamond";
      font-size: rem(32);
      font-weight: 700;
      line-height: rem(41);
      color: #e9ce9d;
      text-transform: uppercase;

      @include respond(mobile) {
        font-size: rem(14);
        line-height: rem(18);
      }
    }

    p {
      margin: 0;
      font-family: "Raleway";
      font-size: rem(18);
      font-weight: 400;
      line-height: rem(21);
      padding-top: rem(12);
      color: #adadad;

      @include respond(mobile) {
        font-size: rem(10);
        padding-top: rem(3);
        line-height: rem(11);
      }
    }
  }
}

.details_section {
  display: block;
  background: #000000;
  position: relative;

  .details_bg {
    width: 100%;
    height: auto;

    @include respond(mobile) {
      display: none;
    }
  }

  .details_content_section {
    position: absolute;
    left: 56vw;
    top: 50%;
    transform: translateY(-50%);
    max-width: 33vw;

    @include respond(ipadProMobile) {
      max-width: 40vw;
    }

    @include respond(mobile) {
      position: relative;
      left: initial;
      top: initial;
      transform: none;
      max-width: 100%;
      padding: rem(24) 0;
    }

    &.visit_section {
      left: 17vw;
      max-width: 25vw;
      text-align: left;

      @include respond(laptopdesktop) {
        left: 5vw;
        max-width: 35vw;
      }

      @include respond(smalldesktop) {
        left: 5vw;
        max-width: 40vw;
      }

      @include respond(ipadMobile) {
        left: 5vw;
        max-width: 40vw;
      }

      @include respond(mobile) {
        position: relative;
        left: initial;
        top: initial;
        transform: none;
        max-width: 100%;
        padding: rem(24) 0;
      }
    }

    &.associate_section {
      .nick_logo {
        width: rem(173);
        height: rem(110);
        margin-top: rem(16);

        @include respond(ipadMobile) {
          width: rem(117);
          height: rem(74);
        }

        @include respond(mobile) {
          text-align: left;
          margin-top: rem(32);
        }
      }

      .logos_img {
        width: 100%;
        height: auto;
        max-width: rem(446);
        margin-top: rem(103);

        @include respond(ipadProMobile) {
          margin-top: rem(40);
        }

        @include respond(ipadMobile) {
          margin-top: rem(32);
        }

        @include respond(mobile) {
          margin-top: rem(80);
        }
      }

      .content {
        text-align: left;
        padding: 0 rem(16);

        h3 {
          text-align: left;
          margin: rem(16) 0 0;
          padding-bottom: 0;
        }

        p {
          text-align: left;
          padding: rem(10) 0 0;
        }
      }
    }

    .content {
      display: block;

      @include respond(mobile) {
        text-align: center;
      }

      h4 {
        font-family: "EB Garamond";
        font-size: rem(18);
        font-weight: 600;
        line-height: rem(23);
        margin: 0;
        color: #ffffff;
        padding-bottom: rem(6);
        text-transform: uppercase;

        @include respond(ipadMobile) {
          font-size: rem(16);
          line-height: rem(20);
        }
      }

      h3 {
        margin: 0;
        font-family: "EB Garamond";
        font-size: rem(32);
        font-weight: 700;
        line-height: rem(41);
        text-transform: uppercase;
        color: #e9ce9d;

        @include respond(ipadMobile) {
          font-size: rem(24);
          line-height: rem(30);
        }

        @include respond(mobile) {
          max-width: rem(348);
          margin: 0 auto;
          padding-bottom: rem(24);
        }
      }

      p {
        margin: 0;
        font-family: "Raleway";
        font-size: rem(18);
        font-weight: 400;
        line-height: rem(22);
        color: #adadad;
        padding-top: rem(16);

        @include respond(ipadMobile) {
          font-size: rem(14);
          line-height: rem(22);
        }

        @include respond(mobile) {
          padding: rem(24) rem(16) 0;
        }
      }

      .content_middle_img {
        display: none;

        @include respond(mobile) {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .brand_logo {
        width: rem(129);
        height: auto;

        @include respond(ipadMobile) {
          width: rem(101);
          height: auto;
        }
      }

      .btn_visit {
        padding: 0;
        margin: rem(24) 0 0;
        background: #b6995e;
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(130);
        height: rem(51);
        border-radius: rem(1);
        outline: none;
        box-shadow: none;
        font-family: "Roboto";
        font-size: rem(13);
        font-weight: 500;
        line-height: rem(15);
        letter-spacing: 0.13em;
        color: #ffffff;
        text-transform: uppercase;

        @include respond(mobile) {
          margin: rem(38) auto;
        }
      }
    }
  }
}

.whisky_richness_section {
  display: block;
  background: #000000;
  position: relative;

  .details_bg {
    width: 100%;
    height: auto;

    &.for_whisky_richness {
      min-height: rem(440);
    }

    @include respond(mobile) {
      display: none;
    }
  }

  .whisky_richness_content_section {
    position: absolute;
    left: 50vw;
    top: 50%;
    transform: translateY(-50%);
    max-width: 40vw;

    @include respond(ipadProMobile) {
      max-width: 40vw;
    }

    @include respond(mobile) {
      position: relative;
      left: initial;
      top: initial;
      transform: none;
      max-width: 100%;
      padding: 0 0 rem(80);
    }

    .whisky_richness_content {
      display: block;

      @include respond(mobile) {
        text-align: center;
      }

      h4 {
        font-family: "EB Garamond";
        font-size: rem(18);
        font-weight: 600;
        line-height: rem(23);
        margin: 0;
        color: #ffffff;
        padding-bottom: rem(6);
        text-transform: uppercase;

        @include respond(ipadMobile) {
          font-size: rem(16);
          line-height: rem(20);
        }
      }

      h3 {
        margin: 0;
        font-family: "EB Garamond";
        font-size: rem(32);
        font-weight: 700;
        line-height: rem(41);
        text-transform: uppercase;
        color: #e9ce9d;

        @include respond(ipadMobile) {
          font-size: rem(24);
          line-height: rem(30);
        }

        @include respond(mobile) {
          max-width: rem(348);
          margin: rem(-40) auto rem(0);
        }
      }

      p {
        margin: 0;
        font-family: "Raleway";
        font-size: rem(18);
        font-weight: 400;
        line-height: rem(22);
        color: #adadad;
        padding-top: rem(16);

        @include respond(ipadMobile) {
          font-size: rem(14);
          line-height: rem(22);
        }

        @include respond(mobile) {
          padding: rem(24) rem(16) 0;
        }
      }

      .content_middle_img {
        display: none;

        @include respond(mobile) {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .whisky_details {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: rem(5);
        padding-top: rem(35);

        @include respond(mobile) {
          padding-top: rem(25);
          margin: 0 rem(16);
        }

        .whisky_card {
          display: block;
          text-align: center;

          @include respond(mobile) {
            max-width: rem(90);
            margin: 0 auto;
          }

          .whisky_img {
            display: block;
            text-align: center;
            margin: 0 auto;

            img {
              width: rem(90);
              height: rem(90);

              @include respond(mobile) {
                width: rem(80);
                height: rem(80);
              }
            }
          }

          h2 {
            margin: 0;
            font-family: "EB Garamond";
            font-size: rem(14);
            font-weight: 400;
            line-height: rem(22);
            text-transform: uppercase;
            padding-top: rem(16);
            color: #e9ce9d;

            @include respond(mobile) {
              font-size: rem(12);
              line-height: rem(15);
            }
          }
        }
      }
    }
  }
}

.coming_soon_section {
  display: block;
  background: #000000;
  position: relative;

  &::before {
    content: "";
    background: linear-gradient(
        95.84deg,
        rgba(0, 0, 0, 0) 64.38%,
        #000000 90.5%
      ),
      linear-gradient(180.41deg, rgba(0, 0, 0, 0) 76.45%, #000000 106.67%),
      linear-gradient(0.48deg, rgba(0, 0, 0, 0) 67.9%, #000000 105.64%),
      radial-gradient(
        64.33% 95.94% at 51.84% 56.02%,
        rgba(0, 0, 0, 0) 95.72%,
        #000000 100%
      );
    position: absolute;
    left: 0;
    top: 0;
    width: 59vw;
    height: 100%;

    @include respond(mobile) {
      display: none;
    }
  }

  video {
    width: 59vw;
    height: auto;

    &.video_for_desktop {
      display: block;

      @include respond(mobile) {
        display: none;
      }
    }
  }

  .coming_soon_content_box {
    position: absolute;
    left: 56vw;
    top: 50%;
    transform: translateY(-50%);
    max-width: 23vw;

    @include respond(ipadProMobile) {
      max-width: 40vw;
    }

    @include respond(mobile) {
      position: relative;
      left: initial;
      top: initial;
      transform: none;
      max-width: 100%;
      padding: rem(24) 0 rem(54);
    }

    .coming_soon_inner_text {
      display: block;
      z-index: 1;

      h4 {
        margin: 0;
        font-family: "EB Garamond";
        font-size: rem(18);
        font-weight: 700;
        line-height: rem(23);
        color: #ffffff;
        text-transform: uppercase;
        padding-bottom: rem(3);

        @include respond(ipadMobile) {
          font-size: rem(16);
          line-height: rem(20);
        }

        @include respond(mobile) {
          text-align: center;
          padding-bottom: rem(9);
        }
      }

      h3 {
        margin: 0;
        font-family: "EB Garamond";
        font-size: rem(32);
        font-weight: 700;
        line-height: rem(41);
        text-transform: uppercase;
        color: #e9ce9d;

        @include respond(ipadMobile) {
          font-size: rem(24);
          line-height: rem(30);
        }

        @include respond(mobile) {
          max-width: rem(348);
          margin: 0 auto;
          text-align: center;
          padding-bottom: rem(24);
        }
      }

      p {
        font-family: "Raleway";
        font-size: rem(18);
        font-weight: 400;
        line-height: rem(22);
        color: #adadad;
        margin: 0;
        padding-top: rem(16);

        @include respond(ipadMobile) {
          font-size: rem(14);
          line-height: rem(22);
        }

        @include respond(mobile) {
          padding: rem(24) rem(16) 0;
          text-align: center;
        }
      }

      .video_shadow {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: radial-gradient(
              54.33% 76.94% at 51.84% 63.02%,
              rgba(0, 0, 0, 0) 53.72%,
              #000000 100%
            )
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        }
      }

      video {
        &.video_for_mobile {
          display: none;

          @include respond(mobile) {
            display: block;
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
.dt_sunflower_section {
  background-color: #010101;
  padding: rem(40) 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include respond(ipadMobile) {
    padding: rem(40) rem(30);
  }
  .content_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: rem(25) 0;
    h2 {
      font-family: "EB Garamond";
      font-weight: 700;
      color: #e9ce9d;
      text-align: center;
      line-height: 1.3;
      text-transform: uppercase;

      @include respond(desktop) {
        letter-spacing: rem(1);
        font-size: rem(32);
        max-width: rem(700);
        margin-inline: auto;
      }
      @include respond(ipadMobile) {
        font-size: rem(24);
      }
    }

    .sunflower_content1 {
      font-family: "Raleway";
      font-size: rem(18);
      text-align: center;
      letter-spacing: 0.03125rem;
      position: relative;
      padding: rem(10) rem(10);
      border: rem(1) solid transparent;
      margin: rem(10) 0;
      background-color: #353026;
      padding: rem(10) rem(10);
      font-weight: 500;
      line-height: 1.2;
      color: white;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      @include respond(ipadMobile) {
        padding: rem(10) rem(15);
      }
      &::before {
        content: "";
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        z-index: -1;
        border-radius: rem(4);
        padding: rem(1);
        background: linear-gradient(
          96.7deg,
          #ffad17 -19.78%,
          #ffecca 30.17%,
          #ffc65e 70.91%,
          #61543c 107.54%
        );
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
      }
    }
    .sunflower_content2 {
      font-family: "Raleway";
      font-size: rem(18);
      font-weight: 400;
      color: #adadad;
      text-align: center;
      letter-spacing: rem(0.5);
      margin: rem(10) 0;
      @include respond(ipadMobile) {
        font-size: rem(14);
        letter-spacing: rem(0.5);
      }
    }
    .btn_sunflower {
      background: #b6995e;
      padding: rem(16) rem(30);
      border-radius: rem(2);
      font-size: rem(16);
      color: white;
      font-family: "Roboto";
      font-weight: 500;
      font-size: rem(13);
      text-transform: uppercase;
      letter-spacing: rem(1);
      margin-top: rem(20);
      @include respond(ipadMobile){
        margin-top: rem(15);
      padding: rem(15) rem(30);

      }
    }
  }
  .video_shadow {
    @include respond(desktop) {
      margin-top: rem(1);
    }
    video {
      width: 59vw;
      height: auto;
      border-radius: rem(4);
      @include respond(ipadMobile){
        width: 86vw;
      }
      &.video_for_desktop {
        display: block;
      }
    }
  }
}
