.modal {
    &.buy_now_cart_modal {
        background: rgba(3, 3, 3, 0.8);
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: left;
        opacity: 1 !important;
        padding-right: 0px !important;

        @include respond(mobile) {
            height: 100dvh;
        }

        .modal-dialog {
            width: rem(498);
            margin: 0px 0px 0px auto;
            transform: translate(0, 0);

            .modal-content {
                border-radius: 0px;
                width: rem(498);
                height: 100vh;
                background: #FFF;
                padding: rem(30) rem(26);

                @include respond(mediumMobile) {
                    width: 100vw;
                }

                @include respond(smallmobile) {
                    padding: rem(16);
                }


                @include respond(mobile) {
                    height: 100dvh;
                }

                .modal-header {
                    padding: 0px 0px 9px 0px;
                    border-bottom: 1px solid rgba(130, 110, 80, 1);
                    justify-content: space-between;

                    h2 {
                        font-family: "EB Garamond";
                        font-size: rem(28);
                        font-weight: 600;
                        line-height: rem(36.54);
                        margin: 0px;
                        text-transform: uppercase;
                        color: #323232;

                        @include respond(smallmobile) {
                            font-size: rem(20);
                            line-height: rem(26);
                        }
                    }

                    img {
                        cursor: pointer;

                        @include respond(smallmobile) {
                            width: 14px !important;
                            height: 14px !important;
                        }
                    }
                }

                .modal-body {
                    display: block;
                    padding: rem(0);
                    height: calc(100vh - 225px);
                    overflow-y: scroll;

                    @include respond(smallmobile) {
                        height: calc(100vh - 177px);
                    }

                    @include respond(mobile) {
                        height: calc(100dvh - 280px);
                    }


                    .main_bottle_details {
                        display: block;

                        @include respond(mobile) {
                            height: calc(100dvh - 280px);
                        }

                        .cart_product_card {
                            display: flex;
                            align-items: center;
                            gap: rem(16);
                            padding: rem(30) rem(0);
                            border-bottom: rem(1) solid #D9C8AE;

                            &:last-child {
                                border-bottom: 0px;
                            }

                            @include respond(smallmobile) {
                                padding: rem(20) rem(0);
                                gap: rem(10);
                            }

                            .cart_product_image {
                                width: rem(120);
                                height: rem(120);
                                min-width: 120px;
                                min-height: 120px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background: #F4F2EE;
                                padding: rem(5);

                                @include respond(smallmobile) {
                                    width: rem(90);
                                    height: rem(90);
                                    min-width: 90px;
                                    min-height: 90px;
                                }



                                img {
                                    width: auto !important;
                                    height: rem(99) !important;

                                    @include respond(smallmobile) {
                                        height: rem(75) !important;
                                    }
                                }
                            }

                            .cart_product_details {
                                display: block;

                                @include respond(mediumMobile) {
                                    width: calc(100vh - 390px);
                                }

                                @include respond(smallmobile) {
                                    width: calc(100vh - 380px);
                                }


                                h2 {
                                    font-family: "Raleway";
                                    font-size: rem(16);
                                    font-weight: 600;
                                    line-height: rem(18.78);
                                    letter-spacing: 0.01em;
                                    color: #292A28;
                                    margin: rem(0);

                                    @include respond(smallmobile) {
                                        font-size: rem(14);
                                        line-height: rem(16.78);
                                    }
                                }

                                h3 {
                                    padding: rem(12) rem(0);
                                    font-family: "Helvetica";
                                    font-size: rem(14);
                                    font-weight: 400;
                                    line-height: rem(16);
                                    letter-spacing: 0.01em;
                                    margin: rem(0);
                                    color: #6F5E4B;

                                    @include respond(smallmobile) {
                                        padding: rem(8) rem(0);
                                    }
                                }

                                .total_item_box {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    width: 77px;
                                    height: 32px;
                                    border-radius: rem(1);
                                    padding: rem(5) rem(10);
                                    border: rem(1) solid #826E50;
                                    gap: rem(2);

                                    @include respond(smallmobile) {
                                        width: 77px;
                                        height: 25px;
                                    }

                                    .plus_item {
                                        font-family: "Raleway";
                                        font-size: rem(18);
                                        font-weight: 600;
                                        line-height: rem(18);
                                        letter-spacing: 0.13em;
                                        display: block;
                                        cursor: pointer;
                                        color: #828281;
                                    }

                                    .minus_item {
                                        font-family: "Raleway";
                                        font-size: rem(18);
                                        font-weight: 600;
                                        line-height: rem(18);
                                        letter-spacing: 0.13em;
                                        display: block;
                                        cursor: pointer;
                                        color: #828281;
                                    }

                                    .total_item {
                                        font-family: "EB Garamond";
                                        text-transform: uppercase;
                                        font-size: rem(16);
                                        font-weight: 400;
                                        line-height: rem(21);
                                        letter-spacing: 0.13em;
                                        color: #14130F;
                                        text-transform: uppercase;

                                    }
                                }

                                .remove_item {
                                    padding-top: rem(15);
                                    font-family: "Raleway";
                                    font-size: 10px;
                                    font-weight: 500;
                                    line-height: 11.74px;
                                    letter-spacing: 0.01em;
                                    text-transform: uppercase;
                                    color: #6F5E4B;
                                    cursor: pointer;
                                    width: max-content;
                                    border-bottom: 1px solid #6F5E4B;

                                    @include respond(smallmobile) {
                                        padding-top: rem(8);
                                    }
                                }
                            }
                        }
                    }
                }

                .modal-footer {
                    padding: rem(0);
                    border-top: 1px solid rgba(130, 110, 80, 1);

                    .cart_price_section {
                        width: 100%;
                        margin: 0px;
                        padding-top: rem(17);

                        p {
                            font-family: "Raleway";
                            font-size: rem(14);
                            font-weight: 500;
                            line-height: rem(16.44);
                            letter-spacing: 0.01em;
                            margin: rem(0);
                            color: #6F5E4B;

                            @include respond(smallmobile) {
                                font-size: rem(12);
                                line-height: rem(14.44);
                            }
                        }

                        .total_price_section {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            gap: 5px;
                            padding-bottom: rem(7);

                            h3 {
                                margin: rem(0);
                                font-family: "Raleway";
                                font-size: rem(16);
                                font-weight: 600;
                                line-height: rem(18.78);
                                letter-spacing: 0.03em;
                                color: #292A28;

                                @include respond(smallmobile) {
                                    font-size: rem(14);
                                    line-height: rem(16.78);
                                }
                            }

                            h4 {
                                font-family: "Raleway";
                                font-size: rem(28);
                                font-weight: 600;
                                line-height: rem(32.87);
                                letter-spacing: 0.01em;
                                color: #3C3937;
                                margin: rem(0);

                                @include respond(smallmobile) {
                                    font-size: rem(20);
                                    line-height: rem(28);
                                }
                            }
                        }

                        .btn_group_for_cart {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: rem(8);
                            margin: rem(28) rem(0) rem(10);

                            @include respond(smallmobile) {
                                margin: rem(18) rem(0) rem(10);
                            }

                            .btn_view_cart {
                                box-shadow: none;
                                outline: none;
                                border: rem(1) solid #775C3D;
                                background: transparent;
                                border-radius: rem(2);
                                width: 100%;
                                height: rem(35);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-family: "Raleway";
                                font-size: rem(12);
                                font-weight: 500;
                                line-height: rem(14);
                                letter-spacing: -0.02em;
                                color: #775C3D;
                                text-transform: uppercase;
                            }

                            .btn_checkout {
                                box-shadow: none;
                                outline: none;
                                border: rem(1) solid #775C3D;
                                background: transparent;
                                width: 100%;
                                height: rem(35);
                                background-color: #775C3D;
                                border-radius: rem(2);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-family: "Raleway";
                                font-size: rem(12);
                                font-weight: 500;
                                line-height: rem(14);
                                letter-spacing: -0.02em;
                                color: #FFFFFF;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }
}